/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';
import type {
  Absent,
  ResponsePaginatedAbsentDto,
  AbsentsGetAbsentsByTeacherParams,
  AbsentsGetAbsentsHistoryParams,
  AbsentsGetAbsentsByGroupIdParams,
  AbsentsGetAbsentsForCSVParams,
  AbsentsDownloadAbsentsParams,
  ResponseListAttendeeItemDto,
  AbsentsGetAttendeeInfoParams,
  AbsentsDownloadAttendeeParams,
  RequestCreateAbsentDto,
  RequestUpdateAbsentDto,
  AbsentsRequestDeleteBody,
  AbsentsDenyBody,
  AbsentsTeacherCommentApprovalBody,
  AbsentsApproveByParentBody,
  AbsentsApproveByParentAppBody,
  AbsentsApproveBody,
  AbsentsSubmittedBody,
  AbsentsNiceSubmittedBody,
  ResponseActivityWithSubmittedDto,
  ActivityFindByTeacherParams,
  Activity,
  ResponseActivityWithGroupDto,
  RequestCreateActivityDto,
  ResponseActivitySubmitUserDto,
  ActivityFindActivitySubmitUsersParams,
  ActivityDownloadSubmittersParams,
  ActivityV3WithCountDto,
  ActivityV3FindByTeacherParams,
  ActivityV3,
  ActivityV3FindByStudentParams,
  StudentGroup,
  ActivityV3FindByGroupIdsParams,
  GetGPTReportDto,
  ActivityV3FindStudentCardParams,
  ActivityV3FindTitleByIdsParams,
  ActivityV3FindOneParams,
  RequestUpdateActivityV3Dto,
  RequestCreateActivityV3Dto,
  ActivityV3UpdateStudentTextBody,
  ActivityCardGenerateGPTAnalysis200,
  ActivitySession,
  ActivitySessionFindByActivityV3IdParams,
  RequestCreateActivitySessionDto,
  ActivitySessionWithCountDto,
  ActivitySessionFindByTeacherParams,
  ActivitySessionFindSubmittersParams,
  RequestUpdateActivitySessionDto,
  ActivitySessionDownloadSubmittersParams,
  ActivitySessionOrder,
  RequestUpdateActivitySessionOrderDto,
  AchievementChapter,
  AchievementCriteria,
  AchievementCriteriaGetByIdsParams,
  Attendance,
  AttendanceFindAttendanceAbsentParams,
  RequestCreateAttendanceAbsentDto,
  AttendanceDownloadAbsentsParams,
  LectureTeacherAttendance,
  RequestUpsertAttendanceCheckDto,
  AttendanceGetAttendanceCheckParams,
  ResponsePaginatedBoardDto,
  BoardFindAllParams,
  Board,
  RequestCreateBoardDto,
  BoardFindTitleByIdsParams,
  RequestUpdateBoardDto,
  Canteen,
  CanteenFindByYearMonthParams,
  RequestUpsertCanteenDto,
  Code,
  Category,
  ResponseCoulselingDetailDto,
  Coulseling,
  RequestCreateCoulselingDto,
  ResponseCoulselingDetailStudentDto,
  UpdateStudentDto,
  RequestSendParentSignUpDto,
  Fieldtrip,
  FieldtripsDownloadFieldtripsParams,
  ResponsePaginatedFieldtripDto,
  FieldtripsGetFieldtripsByTeacherParams,
  RequestCreateFieldtripDto,
  FieldtripsGetFieldtripsHistoryParams,
  FieldtripsGetResultsByTeacherParams,
  RequestUpdateFieldtripDto,
  FieldtripsDenyBody,
  FieldtripsApproveByParentBody,
  FieldtripsApproveByParentAppBody,
  FieldtripsApproveBody,
  RequestUpdateFieldtripResultDto,
  RequestUpdateFieldtripResultByTeacherDto,
  FieldtripResultDenyResultBody,
  FieldtripResultApproveResultByParentBody,
  FieldtripResultApproveResultByParentAppBody,
  FieldtripResultApproveResultBody,
  Group,
  GroupsFindAllParams,
  ResponseErrorDto,
  RequestCreateGroupDto,
  GroupsFindComparisonParams,
  ResponseGroupByIdsDto,
  GroupsFindAllByIdsParams,
  ResponseGroupDto,
  GroupsFindAllKlassBySchoolParams,
  ResponseSubjectGroupDto,
  GroupsFindSubjectByUserParams,
  GroupsUpdateSeatPositionBody,
  ImagesUploadImageBody,
  UploadFileTypeEnum,
  LifeRecordGetLifeActivityByStudentParams,
  ResponsePaginatedNewsletterDto,
  NewsLettersFindAllParams,
  Newsletter,
  RequestCreateNewsletterDto,
  NewsLettersFindTitleByIdsParams,
  ResponseNewsletterDetailDto,
  RequestUpdateNewsletterDto,
  NewsLettersUpdateEndAtBody,
  ResponsePaginatedNoticeDto,
  NoticesFindAllParams,
  Notice,
  RequestCreateNoticeDto,
  NoticesFindTitleByIdsParams,
  NotificationSetting,
  ResponseNotificationCheckDto,
  RequestNotificationCheckDto,
  RequestUpdateNotificationSettingDto,
  NotificationLog,
  ResponsePaginatedOutingDto,
  OutingsFindAllByTeacherParams,
  OutingsFindHistoryParams,
  OutingsDownloadOutingsParams,
  Outing,
  ResponseCreateOutingDto,
  RequestCreateOutingDto,
  RequestUpdateOutingDto,
  OutingsRequestDeleteBody,
  OutingsDenyBody,
  OutingsApproveBody,
  StudentGroupsFindByGroupIdsParams,
  ResponseStudentGroupWithKlassDto,
  StudentGroupsFindAllByKlassesParams,
  Schedule,
  SchedulesFindAllParams,
  RequestCreateScheduleDto,
  SchedulesFindRejectScheduleParams,
  School,
  Summary,
  SummariesFindByStudentGroupIdParams,
  RequestCreateSummaryDto,
  RequestUpdateSummaryDto,
  RequestUpsertStudentRoleDto,
  StudentActivity,
  StudentActivityFindByUserIdParams,
  RequestUpdateStudentActivityDto,
  StudentActivityUpdateFeedbackBody,
  StudentActivitySession,
  StudentActivitySessionFindByTeacherParams,
  StudentActivitySessionFindOneByTeacherParams,
  StudentActivitySessionFindOneByStudentParams,
  RequestUpdateStudentActivitySessionDto,
  StudentActivitySessionSaveOneParams,
  Comment,
  RequestCreateActivityCommentDto,
  StudentActivityCommentUpdateBody,
  StudentNewsletter,
  ResponseChatAttendeeDto,
  RequestApproveStudentNewsletterDto,
  RequestUpsertStudentNewsletterDto,
  StudentActivityV3,
  StudentActivityV3FindStudentCardParams,
  StudentActivityV3FindByTeacherParams,
  RequestUpdateStudentActivityV3Dto,
  StudentActivityV3SaveByTeacherParams,
  StudentActivityV3FindByStudentParams,
  RequestUpdateStudentTextDto,
  StudentActivityV3SaveStudentTextParams,
  TeacherGroupsGetTeacherGroupsParams,
  TeacherGroup,
  TeacherGroupsFindBySubjectParams,
  Timetable,
  RequestCreateTimetableDto,
  TimetableV2,
  ResponseTimetableV2TeacherDto,
  TimetablesV2GetAllTeacherParams,
  TimetableV2Student,
  TimetablesV2FindOneByStudentParams,
  TimetablesV2FindOneByStudentIdParams,
  TimetablesV2UpdateSeatPositionBody,
  UserFindIdParams,
  RequestFindPasswordDto,
  RequestResetPasswordDto,
  AuthResponse,
  RequestLoginDto,
  ParentDto,
  ResponseUserDto,
  UpdateUserDto,
  RequestUpdateMyInfoAtFirstLoginDto,
  RequestParentSignUpDto,
  RequestRemoveChildDto,
  ResponseTeachersDto,
  ResponseGetParentsByStudentGroupDto,
  UserGetParentsByGroupIdParams,
  UserGetUserAttendanceParams,
  UserGetAttendanceBookParams,
  RequestCreateChatMessageDto,
  ResponseChatroomInfoDto,
  RequestCreateChatroomDto,
  RequestAddUsersChatroomDto,
  ResponseChatroomInfoDetailDto,
  ResponsePaginatedChatMessageDto,
  ChatroomGetMessageListParams,
  ChatroomReadInfo,
  RequestModifyChatroomStatusDto,
  RequestUpdateChatroomInfoDto,
  ChatroomSearchUsersParams,
  ResponseDashboardDto,
  ResponseStudentNotificationDashboardDto,
  ResponseTeacherNotificationDashboardDto,
  ResponseApprovalLineDto,
  ApprovalLineGetApprovalLineByType2Params,
  ApprovalLine,
  ApprovalLineDeleteTeacherParams,
  RequestUpdateSchoolInfoDto,
  SchoolManagementLoadHolidayDataParams,
  SchoolManagementLoadYearHolidayDataParams,
  SchoolManagementLoadTimetableFromNeisParams,
  ResponsePaginationTeacherInfoDto,
  TeacherManagementGetTeachersParams,
  ResponseTeacherDto,
  RequestCreateTeacherDto,
  ResponseTeacherDetailInfoDto,
  TeacherManagementGetTeacherInfoParams,
  RequestModifyTeacherDto,
  ResponsePaginationStudentInfoDto,
  StudentManagementGetStudentsParams,
  ResponseStudentInfoDto,
  RequestCreateStudentDto,
  ResponseStudentDetailInfoDto,
  StudentManagementGetStudentInfoParams,
  RequestModifyStudentDto,
  RequestAdvancedStudentDto,
  ResponsePaginationParentInfoDto,
  ParentManagementGetParentsParams,
  ResponseParentDetailInfoDto,
  ParentManagementGetParentInfoParams,
  RequestModifyParentDto,
  ResponseKlassInfoDto,
  RequestChangeHomeroomTeacherDto,
  RequestCreateKlassDto,
  GroupManagementGetGroupListParams,
  RequestCreateGroupOnlyDto,
  ResponseGroupDetailInfoDto,
  RequestModifyGroupOnlyDto,
  RequestGroupTeachersDto,
  RequestGroupStudentsDto,
  AdminCommonSearchTeachersParams,
  AdminCommonSearchStudentsParams,
  AdminCommonFindAllKlassBySchoolParams,
  AdminCommonFindGroupByteacherBySchoolParams,
  ResponseTimetableV3Dto,
  Timetablev3GetTimetableByGroupIdParams,
  Timetablev3GetTimetableByStudentIdParams,
  Timetablev3GetTimetableByTeacherIdParams,
  RequestCreateSmsMessageDto,
  ResponsePaginatedSmsHistoryDto,
  SmsGetFieldtripsByTeacherParams,
  ResponseCreateSmsMessageDto,
  SmsCreditRemain,
  TimetableManagementGetTimeTableParams,
  Lecture,
  TimetableManagementGetMoveLectureListParams,
  RequestTradeLectureDto,
  RequestTradeLectureDayDto,
  ResponseLectureInfoDto,
  RequestCreateLectureDto,
  RequestModifyLectureInfoDto,
  RequestModifyMoveLectureDto,
  TimetableManagementDeleteLectureParams,
  ResponseSearchUserDto,
  CommonUserSearchParams,
  OtpSendPostBody,
  OtpSendPostAuthBody,
  OtpUserSearchPostBody,
  OtpUserSearchPostAuthBody,
  SmsCreditHistory,
  SmsManagementGetStatisticsParams,
  ResponseSmsHistoryDto,
  SmsManagementGetSmsHistoryParams,
  SmsCreditCharge,
  SmsManagementGetCreditChargeParams,
  StudentProperty,
  RequestStudentPropertyDto,
  ResponseAnnouncementDto,
  RequestCreateAnnouncementDto,
  ResponsePaginatedAnnouncementDto,
  AnnouncementGetAllAnnouncementsParams,
  AnnouncementGetAllAnnouncementsForAdminParams,
  RequestUpdateAnnouncementDto,
  GroupActivityV3,
  GroupActivityV3FindByTeacherParams,
  NiceComparison,
  NiceComparisonsFindOneParams,
  RequestCreateNiceComparisonDto,
  RequestUpdateNiceComparisonDto,
  StudentRecord,
  StudentRecordontrollerFindByStudentIdParams,
  RequestCreateStudentRecordDto,
  StudentRecordontrollerCreateParams,
  UpdateResult,
  StudentRecordontrollerUpdateBody,
  StudentExamScoreGetMyScores200,
  StudentExamScoreGetStudentExamScores200,
  StudentExamScoreGetStudentExamScoresChart200,
  StudentExamScorePatchStudentExamScores200,
  StudentExamScorePatchStudentExamScoresParams,
  StudentExamScoreInsetClassScoresBody,
  StudentExamScoreInsetClassScoresParams,
  StudentExamScoreInsetClassScoresBulkBody,
  StudentExamScoreInsetClassScoresBulkParams,
  StudentExamScoreCheckScoreFileParams,
  StudentExamScoreInsetTestScoresBody,
  StudentExamScoreInsetTestScoresParams,
  ResponseStudentCardStudentDto,
  Record,
  RecordCreateBody,
  RecordCreateParams,
  RecordUpdateBody,
  SessionComment,
  SessionCommentCreateBody,
  SessionCommentCreateParams,
  SessionCommentUpdateBody,
  ResponseBlockChainStatusDto,
  BlockChainRequestCheckParams,
  BlockChainGetDocumentStatusParams,
} from './model';
import { mutator } from '../lib/axios';
import type { ErrorType } from '../lib/axios';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary 출결 신고서 목록(학생용)
 */
export const absentsFindAllByStudent = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Absent[]>({ url: `/api/absents/list-by-student`, method: 'get', signal }, options);
};

export const getAbsentsFindAllByStudentQueryKey = () => [`/api/absents/list-by-student`];

export type AbsentsFindAllByStudentQueryResult = NonNullable<Awaited<ReturnType<typeof absentsFindAllByStudent>>>;
export type AbsentsFindAllByStudentQueryError = ErrorType<unknown>;

export const useAbsentsFindAllByStudent = <
  TData = Awaited<ReturnType<typeof absentsFindAllByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof absentsFindAllByStudent>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsFindAllByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsFindAllByStudent>>> = ({ signal }) =>
    absentsFindAllByStudent(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsFindAllByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 목록(선생님용), 레거시 거의 그대로 유지
 */
export const absentsGetAbsentsByTeacher = (
  params: AbsentsGetAbsentsByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedAbsentDto>({ url: `/api/absents/teacher`, method: 'get', params, signal }, options);
};

export const getAbsentsGetAbsentsByTeacherQueryKey = (params: AbsentsGetAbsentsByTeacherParams) => [
  `/api/absents/teacher`,
  ...(params ? [params] : []),
];

export type AbsentsGetAbsentsByTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof absentsGetAbsentsByTeacher>>>;
export type AbsentsGetAbsentsByTeacherQueryError = ErrorType<unknown>;

export const useAbsentsGetAbsentsByTeacher = <
  TData = Awaited<ReturnType<typeof absentsGetAbsentsByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsGetAbsentsByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsGetAbsentsByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsGetAbsentsByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsGetAbsentsByTeacher>>> = ({ signal }) =>
    absentsGetAbsentsByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsGetAbsentsByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 서류관리 목록(선생님용)
 */
export const absentsGetAbsentsHistory = (
  params: AbsentsGetAbsentsHistoryParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedAbsentDto>({ url: `/api/absents/history`, method: 'get', params, signal }, options);
};

export const getAbsentsGetAbsentsHistoryQueryKey = (params: AbsentsGetAbsentsHistoryParams) => [
  `/api/absents/history`,
  ...(params ? [params] : []),
];

export type AbsentsGetAbsentsHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof absentsGetAbsentsHistory>>>;
export type AbsentsGetAbsentsHistoryQueryError = ErrorType<unknown>;

export const useAbsentsGetAbsentsHistory = <
  TData = Awaited<ReturnType<typeof absentsGetAbsentsHistory>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsGetAbsentsHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsGetAbsentsHistory>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsGetAbsentsHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsGetAbsentsHistory>>> = ({ signal }) =>
    absentsGetAbsentsHistory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsGetAbsentsHistory>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 목록 그룹아이디로 조회(선생님용), 레거시 거의 그대로 유지
 */
export const absentsGetAbsentsByGroupId = (
  params: AbsentsGetAbsentsByGroupIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedAbsentDto>({ url: `/api/absents/groupid`, method: 'get', params, signal }, options);
};

export const getAbsentsGetAbsentsByGroupIdQueryKey = (params: AbsentsGetAbsentsByGroupIdParams) => [
  `/api/absents/groupid`,
  ...(params ? [params] : []),
];

export type AbsentsGetAbsentsByGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof absentsGetAbsentsByGroupId>>>;
export type AbsentsGetAbsentsByGroupIdQueryError = ErrorType<unknown>;

export const useAbsentsGetAbsentsByGroupId = <
  TData = Awaited<ReturnType<typeof absentsGetAbsentsByGroupId>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsGetAbsentsByGroupIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsGetAbsentsByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsGetAbsentsByGroupIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsGetAbsentsByGroupId>>> = ({ signal }) =>
    absentsGetAbsentsByGroupId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsGetAbsentsByGroupId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 출결 신고서 csv 목록(선생님용), 레거시 거의 그대로 유지
 */
export const absentsGetAbsentsForCSV = (
  params: AbsentsGetAbsentsForCSVParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedAbsentDto>(
    { url: `/api/absents/teacher/csv`, method: 'get', params, signal },
    options,
  );
};

export const getAbsentsGetAbsentsForCSVQueryKey = (params: AbsentsGetAbsentsForCSVParams) => [
  `/api/absents/teacher/csv`,
  ...(params ? [params] : []),
];

export type AbsentsGetAbsentsForCSVQueryResult = NonNullable<Awaited<ReturnType<typeof absentsGetAbsentsForCSV>>>;
export type AbsentsGetAbsentsForCSVQueryError = ErrorType<unknown>;

export const useAbsentsGetAbsentsForCSV = <
  TData = Awaited<ReturnType<typeof absentsGetAbsentsForCSV>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsGetAbsentsForCSVParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsGetAbsentsForCSV>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsGetAbsentsForCSVQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsGetAbsentsForCSV>>> = ({ signal }) =>
    absentsGetAbsentsForCSV(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsGetAbsentsForCSV>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 엑셀 다운로드(선생님용)
 */
export const absentsDownloadAbsents = (
  params: AbsentsDownloadAbsentsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>({ url: `/api/absents/download`, method: 'get', params, responseType: 'blob', signal }, options);
};

export const getAbsentsDownloadAbsentsQueryKey = (params: AbsentsDownloadAbsentsParams) => [
  `/api/absents/download`,
  ...(params ? [params] : []),
];

export type AbsentsDownloadAbsentsQueryResult = NonNullable<Awaited<ReturnType<typeof absentsDownloadAbsents>>>;
export type AbsentsDownloadAbsentsQueryError = ErrorType<unknown>;

export const useAbsentsDownloadAbsents = <
  TData = Awaited<ReturnType<typeof absentsDownloadAbsents>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsDownloadAbsentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsDownloadAbsents>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsDownloadAbsentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsDownloadAbsents>>> = ({ signal }) =>
    absentsDownloadAbsents(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsDownloadAbsents>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * TODO /teacher/:id endpoint 제거 필요
 * @summary 나이스 비교 출결신고서 정보 조회
 */
export const absentsGetAttendeeInfo = (
  params: AbsentsGetAttendeeInfoParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseListAttendeeItemDto[]>(
    { url: `/api/absents/list-attendee`, method: 'get', params, signal },
    options,
  );
};

export const getAbsentsGetAttendeeInfoQueryKey = (params: AbsentsGetAttendeeInfoParams) => [
  `/api/absents/list-attendee`,
  ...(params ? [params] : []),
];

export type AbsentsGetAttendeeInfoQueryResult = NonNullable<Awaited<ReturnType<typeof absentsGetAttendeeInfo>>>;
export type AbsentsGetAttendeeInfoQueryError = ErrorType<unknown>;

export const useAbsentsGetAttendeeInfo = <
  TData = Awaited<ReturnType<typeof absentsGetAttendeeInfo>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsGetAttendeeInfoParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsGetAttendeeInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsGetAttendeeInfoQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsGetAttendeeInfo>>> = ({ signal }) =>
    absentsGetAttendeeInfo(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsGetAttendeeInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급별 출결 현황 다운로드(선생님용)
 */
export const absentsDownloadAttendee = (
  params: AbsentsDownloadAttendeeParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/absents/teacher/download/attendee`, method: 'get', params, responseType: 'blob', signal },
    options,
  );
};

export const getAbsentsDownloadAttendeeQueryKey = (params: AbsentsDownloadAttendeeParams) => [
  `/api/absents/teacher/download/attendee`,
  ...(params ? [params] : []),
];

export type AbsentsDownloadAttendeeQueryResult = NonNullable<Awaited<ReturnType<typeof absentsDownloadAttendee>>>;
export type AbsentsDownloadAttendeeQueryError = ErrorType<unknown>;

export const useAbsentsDownloadAttendee = <
  TData = Awaited<ReturnType<typeof absentsDownloadAttendee>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsDownloadAttendeeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsDownloadAttendee>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsDownloadAttendeeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsDownloadAttendee>>> = ({ signal }) =>
    absentsDownloadAttendee(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsDownloadAttendee>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * TODO /teacher/:id endpoint 제거 필요
 * @summary 출결 신고서 단건 조회
 */
export const absentsFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Absent>({ url: `/api/absents/${id}`, method: 'get', signal }, options);
};

export const getAbsentsFindOneQueryKey = (id: number) => [`/api/absents/${id}`];

export type AbsentsFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof absentsFindOne>>>;
export type AbsentsFindOneQueryError = ErrorType<void>;

export const useAbsentsFindOne = <TData = Awaited<ReturnType<typeof absentsFindOne>>, TError = ErrorType<void>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsFindOne>>> = ({ signal }) =>
    absentsFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 수정
 */
export const absentsUpdate = (
  id: number,
  requestCreateAbsentDto: RequestCreateAbsentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Absent>(
    {
      url: `/api/absents/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateAbsentDto,
    },
    options,
  );
};

export type AbsentsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof absentsUpdate>>>;
export type AbsentsUpdateMutationBody = RequestCreateAbsentDto;
export type AbsentsUpdateMutationError = ErrorType<void>;

export const useAbsentsUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsUpdate>>,
    TError,
    { id: number; data: RequestCreateAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsUpdate>>,
    { id: number; data: RequestCreateAbsentDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsUpdate>>,
    TError,
    { id: number; data: RequestCreateAbsentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 삭제
 */
export const absentsDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/absents/${id}`, method: 'delete' }, options);
};

export type AbsentsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof absentsDelete>>>;

export type AbsentsDeleteMutationError = ErrorType<unknown>;

export const useAbsentsDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof absentsDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof absentsDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return absentsDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof absentsDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 출결 신고서 단건 조회(by UUID)
 */
export const absentsFindOneByUUID = (uuid: string, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Absent>({ url: `/api/absents/by-uuid/${uuid}`, method: 'get', signal }, options);
};

export const getAbsentsFindOneByUUIDQueryKey = (uuid: string) => [`/api/absents/by-uuid/${uuid}`];

export type AbsentsFindOneByUUIDQueryResult = NonNullable<Awaited<ReturnType<typeof absentsFindOneByUUID>>>;
export type AbsentsFindOneByUUIDQueryError = ErrorType<unknown>;

export const useAbsentsFindOneByUUID = <
  TData = Awaited<ReturnType<typeof absentsFindOneByUUID>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsFindOneByUUID>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsFindOneByUUIDQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsFindOneByUUID>>> = ({ signal }) =>
    absentsFindOneByUUID(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsFindOneByUUID>>, TError, TData>(queryKey, queryFn, {
    enabled: !!uuid,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 추가
 */
export const absentsCreate = (
  requestCreateAbsentDto: RequestCreateAbsentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Absent>(
    {
      url: `/api/absents`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateAbsentDto,
    },
    options,
  );
};

export type AbsentsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof absentsCreate>>>;
export type AbsentsCreateMutationBody = RequestCreateAbsentDto;
export type AbsentsCreateMutationError = ErrorType<void>;

export const useAbsentsCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsCreate>>,
    TError,
    { data: RequestCreateAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof absentsCreate>>, { data: RequestCreateAbsentDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return absentsCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof absentsCreate>>, TError, { data: RequestCreateAbsentDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 출결 신고서 수정(선생님용)
 */
export const absentsUpdateByTeacher = (
  id: number,
  requestUpdateAbsentDto: RequestUpdateAbsentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Absent>(
    {
      url: `/api/absents/update-by-teacher/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateAbsentDto,
    },
    options,
  );
};

export type AbsentsUpdateByTeacherMutationResult = NonNullable<Awaited<ReturnType<typeof absentsUpdateByTeacher>>>;
export type AbsentsUpdateByTeacherMutationBody = RequestUpdateAbsentDto;
export type AbsentsUpdateByTeacherMutationError = ErrorType<void>;

export const useAbsentsUpdateByTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsUpdateByTeacher>>,
    { id: number; data: RequestUpdateAbsentDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsUpdateByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateAbsentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 삭제요청
 */
export const absentsRequestDelete = (
  id: number,
  absentsRequestDeleteBody: AbsentsRequestDeleteBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Absent>(
    {
      url: `/api/absents/request-delete/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsRequestDeleteBody,
    },
    options,
  );
};

export type AbsentsRequestDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof absentsRequestDelete>>>;
export type AbsentsRequestDeleteMutationBody = AbsentsRequestDeleteBody;
export type AbsentsRequestDeleteMutationError = ErrorType<void>;

export const useAbsentsRequestDelete = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsRequestDelete>>,
    TError,
    { id: number; data: AbsentsRequestDeleteBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsRequestDelete>>,
    { id: number; data: AbsentsRequestDeleteBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsRequestDelete(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsRequestDelete>>,
    TError,
    { id: number; data: AbsentsRequestDeleteBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 반려
 */
export const absentsDeny = (
  id: number,
  absentsDenyBody: AbsentsDenyBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/absents/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsDenyBody,
    },
    options,
  );
};

export type AbsentsDenyMutationResult = NonNullable<Awaited<ReturnType<typeof absentsDeny>>>;
export type AbsentsDenyMutationBody = AbsentsDenyBody;
export type AbsentsDenyMutationError = ErrorType<unknown>;

export const useAbsentsDeny = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsDeny>>,
    TError,
    { id: number; data: AbsentsDenyBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof absentsDeny>>, { id: number; data: AbsentsDenyBody }> = (
    props,
  ) => {
    const { id, data } = props ?? {};

    return absentsDeny(id, data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof absentsDeny>>, TError, { id: number; data: AbsentsDenyBody }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 결석 신고서 승인(담임) 코멘트 저장
 */
export const absentsTeacherCommentApproval = (
  id: number,
  absentsTeacherCommentApprovalBody: AbsentsTeacherCommentApprovalBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/absents/teacherCommentApproval/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsTeacherCommentApprovalBody,
    },
    options,
  );
};

export type AbsentsTeacherCommentApprovalMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsTeacherCommentApproval>>
>;
export type AbsentsTeacherCommentApprovalMutationBody = AbsentsTeacherCommentApprovalBody;
export type AbsentsTeacherCommentApprovalMutationError = ErrorType<unknown>;

export const useAbsentsTeacherCommentApproval = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsTeacherCommentApproval>>,
    TError,
    { id: number; data: AbsentsTeacherCommentApprovalBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsTeacherCommentApproval>>,
    { id: number; data: AbsentsTeacherCommentApprovalBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsTeacherCommentApproval(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsTeacherCommentApproval>>,
    TError,
    { id: number; data: AbsentsTeacherCommentApprovalBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 승인(부모님) from 알림톡
 */
export const absentsApproveByParent = (
  uuid: string,
  absentsApproveByParentBody: AbsentsApproveByParentBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/absents/approve-by-parent/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsApproveByParentBody,
    },
    options,
  );
};

export type AbsentsApproveByParentMutationResult = NonNullable<Awaited<ReturnType<typeof absentsApproveByParent>>>;
export type AbsentsApproveByParentMutationBody = AbsentsApproveByParentBody;
export type AbsentsApproveByParentMutationError = ErrorType<unknown>;

export const useAbsentsApproveByParent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsApproveByParent>>,
    TError,
    { uuid: string; data: AbsentsApproveByParentBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsApproveByParent>>,
    { uuid: string; data: AbsentsApproveByParentBody }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return absentsApproveByParent(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsApproveByParent>>,
    TError,
    { uuid: string; data: AbsentsApproveByParentBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 승인(부모님) from 슈퍼스쿨앱
 */
export const absentsApproveByParentApp = (
  id: number,
  absentsApproveByParentAppBody: AbsentsApproveByParentAppBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/absents/approve-by-parentapp/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsApproveByParentAppBody,
    },
    options,
  );
};

export type AbsentsApproveByParentAppMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsApproveByParentApp>>
>;
export type AbsentsApproveByParentAppMutationBody = AbsentsApproveByParentAppBody;
export type AbsentsApproveByParentAppMutationError = ErrorType<unknown>;

export const useAbsentsApproveByParentApp = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsApproveByParentApp>>,
    TError,
    { id: number; data: AbsentsApproveByParentAppBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsApproveByParentApp>>,
    { id: number; data: AbsentsApproveByParentAppBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsApproveByParentApp(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsApproveByParentApp>>,
    TError,
    { id: number; data: AbsentsApproveByParentAppBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 승인
 */
export const absentsApprove = (
  id: number,
  absentsApproveBody: AbsentsApproveBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/absents/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsApproveBody,
    },
    options,
  );
};

export type AbsentsApproveMutationResult = NonNullable<Awaited<ReturnType<typeof absentsApprove>>>;
export type AbsentsApproveMutationBody = AbsentsApproveBody;
export type AbsentsApproveMutationError = ErrorType<unknown>;

export const useAbsentsApprove = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsApprove>>,
    TError,
    { id: number; data: AbsentsApproveBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsApprove>>,
    { id: number; data: AbsentsApproveBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsApprove(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsApprove>>,
    TError,
    { id: number; data: AbsentsApproveBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 증빙서류 확인 체크
 */
export const absentsSubmitted = (
  id: number,
  absentsSubmittedBody: AbsentsSubmittedBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/absents/submitted/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsSubmittedBody,
    },
    options,
  );
};

export type AbsentsSubmittedMutationResult = NonNullable<Awaited<ReturnType<typeof absentsSubmitted>>>;
export type AbsentsSubmittedMutationBody = AbsentsSubmittedBody;
export type AbsentsSubmittedMutationError = ErrorType<unknown>;

export const useAbsentsSubmitted = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsSubmitted>>,
    TError,
    { id: number; data: AbsentsSubmittedBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsSubmitted>>,
    { id: number; data: AbsentsSubmittedBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsSubmitted(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsSubmitted>>,
    TError,
    { id: number; data: AbsentsSubmittedBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 나이스 확인 체크
 */
export const absentsNiceSubmitted = (
  id: number,
  absentsNiceSubmittedBody: AbsentsNiceSubmittedBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/absents/nice-submitted/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsNiceSubmittedBody,
    },
    options,
  );
};

export type AbsentsNiceSubmittedMutationResult = NonNullable<Awaited<ReturnType<typeof absentsNiceSubmitted>>>;
export type AbsentsNiceSubmittedMutationBody = AbsentsNiceSubmittedBody;
export type AbsentsNiceSubmittedMutationError = ErrorType<unknown>;

export const useAbsentsNiceSubmitted = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsNiceSubmitted>>,
    TError,
    { id: number; data: AbsentsNiceSubmittedBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsNiceSubmitted>>,
    { id: number; data: AbsentsNiceSubmittedBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsNiceSubmitted(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsNiceSubmitted>>,
    TError,
    { id: number; data: AbsentsNiceSubmittedBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결신고서 부모님 사인 재요청
 */
export const absentsResend = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<void>({ url: `/api/absents/resend/${id}`, method: 'get', signal }, options);
};

export const getAbsentsResendQueryKey = (id: number) => [`/api/absents/resend/${id}`];

export type AbsentsResendQueryResult = NonNullable<Awaited<ReturnType<typeof absentsResend>>>;
export type AbsentsResendQueryError = ErrorType<unknown>;

export const useAbsentsResend = <TData = Awaited<ReturnType<typeof absentsResend>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsResend>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsResendQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsResend>>> = ({ signal }) =>
    absentsResend(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsResend>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(학생용)
 */
export const activityFindByStudent = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseActivityWithSubmittedDto[]>(
    { url: `/api/activities/by-student`, method: 'get', signal },
    options,
  );
};

export const getActivityFindByStudentQueryKey = () => [`/api/activities/by-student`];

export type ActivityFindByStudentQueryResult = NonNullable<Awaited<ReturnType<typeof activityFindByStudent>>>;
export type ActivityFindByStudentQueryError = ErrorType<void>;

export const useActivityFindByStudent = <
  TData = Awaited<ReturnType<typeof activityFindByStudent>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof activityFindByStudent>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityFindByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityFindByStudent>>> = ({ signal }) =>
    activityFindByStudent(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityFindByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(선생님용)
 */
export const activityFindByTeacher = (
  params: ActivityFindByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseActivityWithSubmittedDto[]>(
    { url: `/api/activities/by-teacher`, method: 'get', params, signal },
    options,
  );
};

export const getActivityFindByTeacherQueryKey = (params: ActivityFindByTeacherParams) => [
  `/api/activities/by-teacher`,
  ...(params ? [params] : []),
];

export type ActivityFindByTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof activityFindByTeacher>>>;
export type ActivityFindByTeacherQueryError = ErrorType<void>;

export const useActivityFindByTeacher = <
  TData = Awaited<ReturnType<typeof activityFindByTeacher>>,
  TError = ErrorType<void>,
>(
  params: ActivityFindByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityFindByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityFindByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityFindByTeacher>>> = ({ signal }) =>
    activityFindByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityFindByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(그룹 아이디로)
 */
export const activityFindByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Activity[]>({ url: `/api/activities/by-group-id/${groupId}`, method: 'get', signal }, options);
};

export const getActivityFindByGroupIdQueryKey = (groupId: number) => [`/api/activities/by-group-id/${groupId}`];

export type ActivityFindByGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof activityFindByGroupId>>>;
export type ActivityFindByGroupIdQueryError = ErrorType<void>;

export const useActivityFindByGroupId = <
  TData = Awaited<ReturnType<typeof activityFindByGroupId>>,
  TError = ErrorType<void>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityFindByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityFindByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityFindByGroupId>>> = ({ signal }) =>
    activityFindByGroupId(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityFindByGroupId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!groupId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(과목명으로)
 */
export const activityFindBySubject = (
  subject: string,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseActivityWithGroupDto[]>(
    { url: `/api/activities/by-Subject/${subject}`, method: 'get', signal },
    options,
  );
};

export const getActivityFindBySubjectQueryKey = (subject: string) => [`/api/activities/by-Subject/${subject}`];

export type ActivityFindBySubjectQueryResult = NonNullable<Awaited<ReturnType<typeof activityFindBySubject>>>;
export type ActivityFindBySubjectQueryError = ErrorType<void>;

export const useActivityFindBySubject = <
  TData = Awaited<ReturnType<typeof activityFindBySubject>>,
  TError = ErrorType<void>,
>(
  subject: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityFindBySubject>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityFindBySubjectQueryKey(subject);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityFindBySubject>>> = ({ signal }) =>
    activityFindBySubject(subject, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityFindBySubject>>, TError, TData>(queryKey, queryFn, {
    enabled: !!subject,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 단건 조회
 */
export const activityFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Activity>({ url: `/api/activities/${id}`, method: 'get', signal }, options);
};

export const getActivityFindOneQueryKey = (id: number) => [`/api/activities/${id}`];

export type ActivityFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof activityFindOne>>>;
export type ActivityFindOneQueryError = ErrorType<void>;

export const useActivityFindOne = <TData = Awaited<ReturnType<typeof activityFindOne>>, TError = ErrorType<void>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityFindOne>>> = ({ signal }) =>
    activityFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 수정
 */
export const activityUpdate = (
  id: number,
  requestCreateActivityDto: RequestCreateActivityDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Activity>(
    {
      url: `/api/activities/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivityDto,
    },
    options,
  );
};

export type ActivityUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof activityUpdate>>>;
export type ActivityUpdateMutationBody = RequestCreateActivityDto;
export type ActivityUpdateMutationError = ErrorType<void>;

export const useActivityUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityUpdate>>,
    TError,
    { id: number; data: RequestCreateActivityDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityUpdate>>,
    { id: number; data: RequestCreateActivityDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return activityUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityUpdate>>,
    TError,
    { id: number; data: RequestCreateActivityDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 삭제
 */
export const activityDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/activities/${id}`, method: 'delete' }, options);
};

export type ActivityDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof activityDelete>>>;

export type ActivityDeleteMutationError = ErrorType<unknown>;

export const useActivityDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof activityDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return activityDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof activityDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 활동 제출자 목록 조회
 */
export const activityFindActivitySubmitUsers = (
  id: number,
  params?: ActivityFindActivitySubmitUsersParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseActivitySubmitUserDto[]>(
    { url: `/api/activities/get-submit-users/${id}`, method: 'get', params, signal },
    options,
  );
};

export const getActivityFindActivitySubmitUsersQueryKey = (
  id: number,
  params?: ActivityFindActivitySubmitUsersParams,
) => [`/api/activities/get-submit-users/${id}`, ...(params ? [params] : [])];

export type ActivityFindActivitySubmitUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityFindActivitySubmitUsers>>
>;
export type ActivityFindActivitySubmitUsersQueryError = ErrorType<unknown>;

export const useActivityFindActivitySubmitUsers = <
  TData = Awaited<ReturnType<typeof activityFindActivitySubmitUsers>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: ActivityFindActivitySubmitUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityFindActivitySubmitUsers>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityFindActivitySubmitUsersQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityFindActivitySubmitUsers>>> = ({ signal }) =>
    activityFindActivitySubmitUsers(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityFindActivitySubmitUsers>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 추가
 */
export const activityCreate = (
  requestCreateActivityDto: RequestCreateActivityDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Activity>(
    {
      url: `/api/activities`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivityDto,
    },
    options,
  );
};

export type ActivityCreateMutationResult = NonNullable<Awaited<ReturnType<typeof activityCreate>>>;
export type ActivityCreateMutationBody = RequestCreateActivityDto;
export type ActivityCreateMutationError = ErrorType<void>;

export const useActivityCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityCreate>>,
    TError,
    { data: RequestCreateActivityDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityCreate>>, { data: RequestCreateActivityDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return activityCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof activityCreate>>, TError, { data: RequestCreateActivityDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 활동 다운로드
 */
export const activityDownloadSubmitters = (
  id: number,
  params?: ActivityDownloadSubmittersParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/activities/download/${id}`, method: 'get', params, responseType: 'blob', signal },
    options,
  );
};

export const getActivityDownloadSubmittersQueryKey = (id: number, params?: ActivityDownloadSubmittersParams) => [
  `/api/activities/download/${id}`,
  ...(params ? [params] : []),
];

export type ActivityDownloadSubmittersQueryResult = NonNullable<Awaited<ReturnType<typeof activityDownloadSubmitters>>>;
export type ActivityDownloadSubmittersQueryError = ErrorType<unknown>;

export const useActivityDownloadSubmitters = <
  TData = Awaited<ReturnType<typeof activityDownloadSubmitters>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: ActivityDownloadSubmittersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityDownloadSubmitters>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityDownloadSubmittersQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityDownloadSubmitters>>> = ({ signal }) =>
    activityDownloadSubmitters(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityDownloadSubmitters>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(선생님용)
 */
export const activityV3FindByTeacher = (
  params?: ActivityV3FindByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ActivityV3WithCountDto[]>({ url: `/api/activityv3/teacher`, method: 'get', params, signal }, options);
};

export const getActivityV3FindByTeacherQueryKey = (params?: ActivityV3FindByTeacherParams) => [
  `/api/activityv3/teacher`,
  ...(params ? [params] : []),
];

export type ActivityV3FindByTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof activityV3FindByTeacher>>>;
export type ActivityV3FindByTeacherQueryError = ErrorType<void>;

export const useActivityV3FindByTeacher = <
  TData = Awaited<ReturnType<typeof activityV3FindByTeacher>>,
  TError = ErrorType<void>,
>(
  params?: ActivityV3FindByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityV3FindByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityV3FindByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityV3FindByTeacher>>> = ({ signal }) =>
    activityV3FindByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityV3FindByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(학생용)
 */
export const activityV3FindByStudent = (
  params?: ActivityV3FindByStudentParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ActivityV3[]>({ url: `/api/activityv3/student`, method: 'get', params, signal }, options);
};

export const getActivityV3FindByStudentQueryKey = (params?: ActivityV3FindByStudentParams) => [
  `/api/activityv3/student`,
  ...(params ? [params] : []),
];

export type ActivityV3FindByStudentQueryResult = NonNullable<Awaited<ReturnType<typeof activityV3FindByStudent>>>;
export type ActivityV3FindByStudentQueryError = ErrorType<void>;

export const useActivityV3FindByStudent = <
  TData = Awaited<ReturnType<typeof activityV3FindByStudent>>,
  TError = ErrorType<void>,
>(
  params?: ActivityV3FindByStudentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityV3FindByStudent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityV3FindByStudentQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityV3FindByStudent>>> = ({ signal }) =>
    activityV3FindByStudent(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityV3FindByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 여러 그룹ID로 활동 제출자 목록 조회(선생님용)
 */
export const activityV3FindByGroupIds = (
  activityv3Id: number,
  params: ActivityV3FindByGroupIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentGroup[]>(
    { url: `/api/activityv3/by-groups/${activityv3Id}`, method: 'get', params, signal },
    options,
  );
};

export const getActivityV3FindByGroupIdsQueryKey = (activityv3Id: number, params: ActivityV3FindByGroupIdsParams) => [
  `/api/activityv3/by-groups/${activityv3Id}`,
  ...(params ? [params] : []),
];

export type ActivityV3FindByGroupIdsQueryResult = NonNullable<Awaited<ReturnType<typeof activityV3FindByGroupIds>>>;
export type ActivityV3FindByGroupIdsQueryError = ErrorType<unknown>;

export const useActivityV3FindByGroupIds = <
  TData = Awaited<ReturnType<typeof activityV3FindByGroupIds>>,
  TError = ErrorType<unknown>,
>(
  activityv3Id: number,
  params: ActivityV3FindByGroupIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityV3FindByGroupIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityV3FindByGroupIdsQueryKey(activityv3Id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityV3FindByGroupIds>>> = ({ signal }) =>
    activityV3FindByGroupIds(activityv3Id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityV3FindByGroupIds>>, TError, TData>(queryKey, queryFn, {
    enabled: !!activityv3Id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary GPT 활동기록 가져오기
 */
export const activityV3GetGPTReport = (getGPTReportDto: GetGPTReportDto, options?: SecondParameter<typeof mutator>) => {
  return mutator<ActivityV3>(
    {
      url: `/api/activityv3/gpt`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: getGPTReportDto,
    },
    options,
  );
};

export type ActivityV3GetGPTReportMutationResult = NonNullable<Awaited<ReturnType<typeof activityV3GetGPTReport>>>;
export type ActivityV3GetGPTReportMutationBody = GetGPTReportDto;
export type ActivityV3GetGPTReportMutationError = ErrorType<void>;

export const useActivityV3GetGPTReport = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityV3GetGPTReport>>,
    TError,
    { data: GetGPTReportDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityV3GetGPTReport>>, { data: GetGPTReportDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return activityV3GetGPTReport(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof activityV3GetGPTReport>>, TError, { data: GetGPTReportDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학생카드 활동 정보 조회
 */
export const activityV3FindStudentCard = (
  params: ActivityV3FindStudentCardParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ActivityV3[]>({ url: `/api/activityv3/student-card`, method: 'get', params, signal }, options);
};

export const getActivityV3FindStudentCardQueryKey = (params: ActivityV3FindStudentCardParams) => [
  `/api/activityv3/student-card`,
  ...(params ? [params] : []),
];

export type ActivityV3FindStudentCardQueryResult = NonNullable<Awaited<ReturnType<typeof activityV3FindStudentCard>>>;
export type ActivityV3FindStudentCardQueryError = ErrorType<void>;

export const useActivityV3FindStudentCard = <
  TData = Awaited<ReturnType<typeof activityV3FindStudentCard>>,
  TError = ErrorType<void>,
>(
  params: ActivityV3FindStudentCardParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityV3FindStudentCard>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityV3FindStudentCardQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityV3FindStudentCard>>> = ({ signal }) =>
    activityV3FindStudentCard(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityV3FindStudentCard>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 제목 조회 (메시지용)
 */
export const activityV3FindTitleByIds = (
  params?: ActivityV3FindTitleByIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ActivityV3[]>({ url: `/api/activityv3/title`, method: 'get', params, signal }, options);
};

export const getActivityV3FindTitleByIdsQueryKey = (params?: ActivityV3FindTitleByIdsParams) => [
  `/api/activityv3/title`,
  ...(params ? [params] : []),
];

export type ActivityV3FindTitleByIdsQueryResult = NonNullable<Awaited<ReturnType<typeof activityV3FindTitleByIds>>>;
export type ActivityV3FindTitleByIdsQueryError = ErrorType<void>;

export const useActivityV3FindTitleByIds = <
  TData = Awaited<ReturnType<typeof activityV3FindTitleByIds>>,
  TError = ErrorType<void>,
>(
  params?: ActivityV3FindTitleByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityV3FindTitleByIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityV3FindTitleByIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityV3FindTitleByIds>>> = ({ signal }) =>
    activityV3FindTitleByIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityV3FindTitleByIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 단건 조회
 */
export const activityV3FindOne = (
  id: number,
  params?: ActivityV3FindOneParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ActivityV3>({ url: `/api/activityv3/${id}`, method: 'get', params, signal }, options);
};

export const getActivityV3FindOneQueryKey = (id: number, params?: ActivityV3FindOneParams) => [
  `/api/activityv3/${id}`,
  ...(params ? [params] : []),
];

export type ActivityV3FindOneQueryResult = NonNullable<Awaited<ReturnType<typeof activityV3FindOne>>>;
export type ActivityV3FindOneQueryError = ErrorType<void>;

export const useActivityV3FindOne = <TData = Awaited<ReturnType<typeof activityV3FindOne>>, TError = ErrorType<void>>(
  id: number,
  params?: ActivityV3FindOneParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityV3FindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityV3FindOneQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityV3FindOne>>> = ({ signal }) =>
    activityV3FindOne(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityV3FindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 수정
 */
export const activityV3Update = (
  id: number,
  requestUpdateActivityV3Dto: RequestUpdateActivityV3Dto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ActivityV3>(
    {
      url: `/api/activityv3/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateActivityV3Dto,
    },
    options,
  );
};

export type ActivityV3UpdateMutationResult = NonNullable<Awaited<ReturnType<typeof activityV3Update>>>;
export type ActivityV3UpdateMutationBody = RequestUpdateActivityV3Dto;
export type ActivityV3UpdateMutationError = ErrorType<void>;

export const useActivityV3Update = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityV3Update>>,
    TError,
    { id: number; data: RequestUpdateActivityV3Dto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityV3Update>>,
    { id: number; data: RequestUpdateActivityV3Dto }
  > = (props) => {
    const { id, data } = props ?? {};

    return activityV3Update(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityV3Update>>,
    TError,
    { id: number; data: RequestUpdateActivityV3Dto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 삭제
 */
export const activityV3Delete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/activityv3/${id}`, method: 'delete' }, options);
};

export type ActivityV3DeleteMutationResult = NonNullable<Awaited<ReturnType<typeof activityV3Delete>>>;

export type ActivityV3DeleteMutationError = ErrorType<unknown>;

export const useActivityV3Delete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof activityV3Delete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityV3Delete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return activityV3Delete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof activityV3Delete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 활동 추가
 */
export const activityV3Create = (
  requestCreateActivityV3Dto: RequestCreateActivityV3Dto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ActivityV3>(
    {
      url: `/api/activityv3`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivityV3Dto,
    },
    options,
  );
};

export type ActivityV3CreateMutationResult = NonNullable<Awaited<ReturnType<typeof activityV3Create>>>;
export type ActivityV3CreateMutationBody = RequestCreateActivityV3Dto;
export type ActivityV3CreateMutationError = ErrorType<void>;

export const useActivityV3Create = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityV3Create>>,
    TError,
    { data: RequestCreateActivityV3Dto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityV3Create>>,
    { data: RequestCreateActivityV3Dto }
  > = (props) => {
    const { data } = props ?? {};

    return activityV3Create(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityV3Create>>,
    TError,
    { data: RequestCreateActivityV3Dto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 활동 보고서 수정
 */
export const activityV3UpdateStudentText = (
  id: number,
  activityV3UpdateStudentTextBody: ActivityV3UpdateStudentTextBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ActivityV3>(
    {
      url: `/api/activityv3/${id}/student-text`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: activityV3UpdateStudentTextBody,
    },
    options,
  );
};

export type ActivityV3UpdateStudentTextMutationResult = NonNullable<
  Awaited<ReturnType<typeof activityV3UpdateStudentText>>
>;
export type ActivityV3UpdateStudentTextMutationBody = ActivityV3UpdateStudentTextBody;
export type ActivityV3UpdateStudentTextMutationError = ErrorType<void>;

export const useActivityV3UpdateStudentText = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityV3UpdateStudentText>>,
    TError,
    { id: number; data: ActivityV3UpdateStudentTextBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityV3UpdateStudentText>>,
    { id: number; data: ActivityV3UpdateStudentTextBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return activityV3UpdateStudentText(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityV3UpdateStudentText>>,
    TError,
    { id: number; data: ActivityV3UpdateStudentTextBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 활동요약 GPT 시안 불러오기
 */
export const activityCardGetActivityGPTAnalysis = (
  studentId: number,
  activityId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<void>(
    { url: `/api/activity-card/${studentId}/activity/${activityId}`, method: 'get', signal },
    options,
  );
};

export const getActivityCardGetActivityGPTAnalysisQueryKey = (studentId: number, activityId: number) => [
  `/api/activity-card/${studentId}/activity/${activityId}`,
];

export type ActivityCardGetActivityGPTAnalysisQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityCardGetActivityGPTAnalysis>>
>;
export type ActivityCardGetActivityGPTAnalysisQueryError = ErrorType<unknown>;

export const useActivityCardGetActivityGPTAnalysis = <
  TData = Awaited<ReturnType<typeof activityCardGetActivityGPTAnalysis>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  activityId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityCardGetActivityGPTAnalysis>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityCardGetActivityGPTAnalysisQueryKey(studentId, activityId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityCardGetActivityGPTAnalysis>>> = ({ signal }) =>
    activityCardGetActivityGPTAnalysis(studentId, activityId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityCardGetActivityGPTAnalysis>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(studentId && activityId), ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 활동요약 GPT 시안 생성
 */
export const activityCardGenerateGPTAnalysis = (
  studentId: number,
  activityId: number,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ActivityCardGenerateGPTAnalysis200>(
    { url: `/api/activity-card/${studentId}/activity/${activityId}`, method: 'post' },
    options,
  );
};

export type ActivityCardGenerateGPTAnalysisMutationResult = NonNullable<
  Awaited<ReturnType<typeof activityCardGenerateGPTAnalysis>>
>;

export type ActivityCardGenerateGPTAnalysisMutationError = ErrorType<unknown>;

export const useActivityCardGenerateGPTAnalysis = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityCardGenerateGPTAnalysis>>,
    TError,
    { studentId: number; activityId: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityCardGenerateGPTAnalysis>>,
    { studentId: number; activityId: number }
  > = (props) => {
    const { studentId, activityId } = props ?? {};

    return activityCardGenerateGPTAnalysis(studentId, activityId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityCardGenerateGPTAnalysis>>,
    TError,
    { studentId: number; activityId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 차시 활동ID별 조회
 */
export const activitySessionFindByActivityV3Id = (
  params: ActivitySessionFindByActivityV3IdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ActivitySession[]>({ url: `/api/activity-session`, method: 'get', params, signal }, options);
};

export const getActivitySessionFindByActivityV3IdQueryKey = (params: ActivitySessionFindByActivityV3IdParams) => [
  `/api/activity-session`,
  ...(params ? [params] : []),
];

export type ActivitySessionFindByActivityV3IdQueryResult = NonNullable<
  Awaited<ReturnType<typeof activitySessionFindByActivityV3Id>>
>;
export type ActivitySessionFindByActivityV3IdQueryError = ErrorType<void>;

export const useActivitySessionFindByActivityV3Id = <
  TData = Awaited<ReturnType<typeof activitySessionFindByActivityV3Id>>,
  TError = ErrorType<void>,
>(
  params: ActivitySessionFindByActivityV3IdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activitySessionFindByActivityV3Id>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivitySessionFindByActivityV3IdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activitySessionFindByActivityV3Id>>> = ({ signal }) =>
    activitySessionFindByActivityV3Id(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activitySessionFindByActivityV3Id>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 차시 추가
 */
export const activitySessionCreate = (
  requestCreateActivitySessionDto: RequestCreateActivitySessionDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ActivitySession>(
    {
      url: `/api/activity-session`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivitySessionDto,
    },
    options,
  );
};

export type ActivitySessionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof activitySessionCreate>>>;
export type ActivitySessionCreateMutationBody = RequestCreateActivitySessionDto;
export type ActivitySessionCreateMutationError = ErrorType<void>;

export const useActivitySessionCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activitySessionCreate>>,
    TError,
    { data: RequestCreateActivitySessionDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activitySessionCreate>>,
    { data: RequestCreateActivitySessionDto }
  > = (props) => {
    const { data } = props ?? {};

    return activitySessionCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activitySessionCreate>>,
    TError,
    { data: RequestCreateActivitySessionDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 차시 리스트 조회(선생님용)
 */
export const activitySessionFindByTeacher = (
  params?: ActivitySessionFindByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ActivitySessionWithCountDto[]>(
    { url: `/api/activity-session/teacher`, method: 'get', params, signal },
    options,
  );
};

export const getActivitySessionFindByTeacherQueryKey = (params?: ActivitySessionFindByTeacherParams) => [
  `/api/activity-session/teacher`,
  ...(params ? [params] : []),
];

export type ActivitySessionFindByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof activitySessionFindByTeacher>>
>;
export type ActivitySessionFindByTeacherQueryError = ErrorType<void>;

export const useActivitySessionFindByTeacher = <
  TData = Awaited<ReturnType<typeof activitySessionFindByTeacher>>,
  TError = ErrorType<void>,
>(
  params?: ActivitySessionFindByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activitySessionFindByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivitySessionFindByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activitySessionFindByTeacher>>> = ({ signal }) =>
    activitySessionFindByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activitySessionFindByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 차시 제출자 및 제출내역 조회(선생님용)
 */
export const activitySessionFindSubmitters = (
  params: ActivitySessionFindSubmittersParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentGroup[]>({ url: `/api/activity-session/submitters`, method: 'get', params, signal }, options);
};

export const getActivitySessionFindSubmittersQueryKey = (params: ActivitySessionFindSubmittersParams) => [
  `/api/activity-session/submitters`,
  ...(params ? [params] : []),
];

export type ActivitySessionFindSubmittersQueryResult = NonNullable<
  Awaited<ReturnType<typeof activitySessionFindSubmitters>>
>;
export type ActivitySessionFindSubmittersQueryError = ErrorType<unknown>;

export const useActivitySessionFindSubmitters = <
  TData = Awaited<ReturnType<typeof activitySessionFindSubmitters>>,
  TError = ErrorType<unknown>,
>(
  params: ActivitySessionFindSubmittersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activitySessionFindSubmitters>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivitySessionFindSubmittersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activitySessionFindSubmitters>>> = ({ signal }) =>
    activitySessionFindSubmitters(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activitySessionFindSubmitters>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 차시 단건 조회(선생님용)
 */
export const activitySessionFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ActivitySession>({ url: `/api/activity-session/${id}`, method: 'get', signal }, options);
};

export const getActivitySessionFindOneQueryKey = (id: number) => [`/api/activity-session/${id}`];

export type ActivitySessionFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof activitySessionFindOne>>>;
export type ActivitySessionFindOneQueryError = ErrorType<void>;

export const useActivitySessionFindOne = <
  TData = Awaited<ReturnType<typeof activitySessionFindOne>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activitySessionFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivitySessionFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activitySessionFindOne>>> = ({ signal }) =>
    activitySessionFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activitySessionFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 차시 수정
 */
export const activitySessionUpdate = (
  id: number,
  requestUpdateActivitySessionDto: RequestUpdateActivitySessionDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ActivitySession>(
    {
      url: `/api/activity-session/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateActivitySessionDto,
    },
    options,
  );
};

export type ActivitySessionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof activitySessionUpdate>>>;
export type ActivitySessionUpdateMutationBody = RequestUpdateActivitySessionDto;
export type ActivitySessionUpdateMutationError = ErrorType<void>;

export const useActivitySessionUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activitySessionUpdate>>,
    TError,
    { id: number; data: RequestUpdateActivitySessionDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activitySessionUpdate>>,
    { id: number; data: RequestUpdateActivitySessionDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return activitySessionUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activitySessionUpdate>>,
    TError,
    { id: number; data: RequestUpdateActivitySessionDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 차시 삭제
 */
export const activitySessionDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/activity-session/${id}`, method: 'delete' }, options);
};

export type ActivitySessionDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof activitySessionDelete>>>;

export type ActivitySessionDeleteMutationError = ErrorType<unknown>;

export const useActivitySessionDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof activitySessionDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof activitySessionDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return activitySessionDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof activitySessionDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 차시 다운로드
 */
export const activitySessionDownloadSubmitters = (
  id: number,
  params: ActivitySessionDownloadSubmittersParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/activity-session/download/${id}`, method: 'get', params, responseType: 'blob', signal },
    options,
  );
};

export const getActivitySessionDownloadSubmittersQueryKey = (
  id: number,
  params: ActivitySessionDownloadSubmittersParams,
) => [`/api/activity-session/download/${id}`, ...(params ? [params] : [])];

export type ActivitySessionDownloadSubmittersQueryResult = NonNullable<
  Awaited<ReturnType<typeof activitySessionDownloadSubmitters>>
>;
export type ActivitySessionDownloadSubmittersQueryError = ErrorType<unknown>;

export const useActivitySessionDownloadSubmitters = <
  TData = Awaited<ReturnType<typeof activitySessionDownloadSubmitters>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: ActivitySessionDownloadSubmittersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activitySessionDownloadSubmitters>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivitySessionDownloadSubmittersQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activitySessionDownloadSubmitters>>> = ({ signal }) =>
    activitySessionDownloadSubmitters(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activitySessionDownloadSubmitters>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 차시 순서 수정
 */
export const activitySessionOrderUpdate = (
  requestUpdateActivitySessionOrderDto: RequestUpdateActivitySessionOrderDto[],
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ActivitySessionOrder[]>(
    {
      url: `/api/activity-session-order`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateActivitySessionOrderDto,
    },
    options,
  );
};

export type ActivitySessionOrderUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof activitySessionOrderUpdate>>
>;
export type ActivitySessionOrderUpdateMutationBody = RequestUpdateActivitySessionOrderDto[];
export type ActivitySessionOrderUpdateMutationError = ErrorType<void>;

export const useActivitySessionOrderUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activitySessionOrderUpdate>>,
    TError,
    { data: RequestUpdateActivitySessionOrderDto[] },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activitySessionOrderUpdate>>,
    { data: RequestUpdateActivitySessionOrderDto[] }
  > = (props) => {
    const { data } = props ?? {};

    return activitySessionOrderUpdate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activitySessionOrderUpdate>>,
    TError,
    { data: RequestUpdateActivitySessionOrderDto[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 성취기준 조회
 */
export const achievementCriteriaGetAll = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<AchievementChapter[]>({ url: `/api/achievement-criteria`, method: 'get', signal }, options);
};

export const getAchievementCriteriaGetAllQueryKey = () => [`/api/achievement-criteria`];

export type AchievementCriteriaGetAllQueryResult = NonNullable<Awaited<ReturnType<typeof achievementCriteriaGetAll>>>;
export type AchievementCriteriaGetAllQueryError = ErrorType<unknown>;

export const useAchievementCriteriaGetAll = <
  TData = Awaited<ReturnType<typeof achievementCriteriaGetAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof achievementCriteriaGetAll>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAchievementCriteriaGetAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof achievementCriteriaGetAll>>> = ({ signal }) =>
    achievementCriteriaGetAll(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof achievementCriteriaGetAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary ids 기반으로 성취기준 조회
 */
export const achievementCriteriaGetByIds = (
  params?: AchievementCriteriaGetByIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<AchievementCriteria[]>(
    { url: `/api/achievement-criteria/get-by-ids`, method: 'get', params, signal },
    options,
  );
};

export const getAchievementCriteriaGetByIdsQueryKey = (params?: AchievementCriteriaGetByIdsParams) => [
  `/api/achievement-criteria/get-by-ids`,
  ...(params ? [params] : []),
];

export type AchievementCriteriaGetByIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof achievementCriteriaGetByIds>>
>;
export type AchievementCriteriaGetByIdsQueryError = ErrorType<unknown>;

export const useAchievementCriteriaGetByIds = <
  TData = Awaited<ReturnType<typeof achievementCriteriaGetByIds>>,
  TError = ErrorType<unknown>,
>(
  params?: AchievementCriteriaGetByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof achievementCriteriaGetByIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAchievementCriteriaGetByIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof achievementCriteriaGetByIds>>> = ({ signal }) =>
    achievementCriteriaGetByIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof achievementCriteriaGetByIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출석부 출결 조회
 */
export const attendanceFindAttendanceAbsent = (
  params: AttendanceFindAttendanceAbsentParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Attendance[]>({ url: `/api/attendance/absent`, method: 'get', params, signal }, options);
};

export const getAttendanceFindAttendanceAbsentQueryKey = (params: AttendanceFindAttendanceAbsentParams) => [
  `/api/attendance/absent`,
  ...(params ? [params] : []),
];

export type AttendanceFindAttendanceAbsentQueryResult = NonNullable<
  Awaited<ReturnType<typeof attendanceFindAttendanceAbsent>>
>;
export type AttendanceFindAttendanceAbsentQueryError = ErrorType<unknown>;

export const useAttendanceFindAttendanceAbsent = <
  TData = Awaited<ReturnType<typeof attendanceFindAttendanceAbsent>>,
  TError = ErrorType<unknown>,
>(
  params: AttendanceFindAttendanceAbsentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof attendanceFindAttendanceAbsent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAttendanceFindAttendanceAbsentQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof attendanceFindAttendanceAbsent>>> = ({ signal }) =>
    attendanceFindAttendanceAbsent(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof attendanceFindAttendanceAbsent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출석부 출결 등록(마지막 등록된게 유효)
 */
export const attendanceCreateAttendanceAbsent = (
  requestCreateAttendanceAbsentDto: RequestCreateAttendanceAbsentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/attendance/absent`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateAttendanceAbsentDto,
    },
    options,
  );
};

export type AttendanceCreateAttendanceAbsentMutationResult = NonNullable<
  Awaited<ReturnType<typeof attendanceCreateAttendanceAbsent>>
>;
export type AttendanceCreateAttendanceAbsentMutationBody = RequestCreateAttendanceAbsentDto;
export type AttendanceCreateAttendanceAbsentMutationError = ErrorType<unknown>;

export const useAttendanceCreateAttendanceAbsent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof attendanceCreateAttendanceAbsent>>,
    TError,
    { data: RequestCreateAttendanceAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof attendanceCreateAttendanceAbsent>>,
    { data: RequestCreateAttendanceAbsentDto }
  > = (props) => {
    const { data } = props ?? {};

    return attendanceCreateAttendanceAbsent(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof attendanceCreateAttendanceAbsent>>,
    TError,
    { data: RequestCreateAttendanceAbsentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출석부 엑셀 다운로드(선생님용)
 */
export const attendanceDownloadAbsents = (
  params: AttendanceDownloadAbsentsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/attendance/download`, method: 'get', params, responseType: 'blob', signal },
    options,
  );
};

export const getAttendanceDownloadAbsentsQueryKey = (params: AttendanceDownloadAbsentsParams) => [
  `/api/attendance/download`,
  ...(params ? [params] : []),
];

export type AttendanceDownloadAbsentsQueryResult = NonNullable<Awaited<ReturnType<typeof attendanceDownloadAbsents>>>;
export type AttendanceDownloadAbsentsQueryError = ErrorType<unknown>;

export const useAttendanceDownloadAbsents = <
  TData = Awaited<ReturnType<typeof attendanceDownloadAbsents>>,
  TError = ErrorType<unknown>,
>(
  params: AttendanceDownloadAbsentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof attendanceDownloadAbsents>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAttendanceDownloadAbsentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof attendanceDownloadAbsents>>> = ({ signal }) =>
    attendanceDownloadAbsents(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof attendanceDownloadAbsents>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출석체크 완료
 */
export const attendanceAttendanceCheck = (
  requestUpsertAttendanceCheckDto: RequestUpsertAttendanceCheckDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<LectureTeacherAttendance>(
    {
      url: `/api/attendance/attendance-check`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpsertAttendanceCheckDto,
    },
    options,
  );
};

export type AttendanceAttendanceCheckMutationResult = NonNullable<
  Awaited<ReturnType<typeof attendanceAttendanceCheck>>
>;
export type AttendanceAttendanceCheckMutationBody = RequestUpsertAttendanceCheckDto;
export type AttendanceAttendanceCheckMutationError = ErrorType<void>;

export const useAttendanceAttendanceCheck = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof attendanceAttendanceCheck>>,
    TError,
    { data: RequestUpsertAttendanceCheckDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof attendanceAttendanceCheck>>,
    { data: RequestUpsertAttendanceCheckDto }
  > = (props) => {
    const { data } = props ?? {};

    return attendanceAttendanceCheck(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof attendanceAttendanceCheck>>,
    TError,
    { data: RequestUpsertAttendanceCheckDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출석체크 확인
 */
export const attendanceGetAttendanceCheck = (
  params: AttendanceGetAttendanceCheckParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<LectureTeacherAttendance>(
    { url: `/api/attendance/attendance-check`, method: 'get', params, signal },
    options,
  );
};

export const getAttendanceGetAttendanceCheckQueryKey = (params: AttendanceGetAttendanceCheckParams) => [
  `/api/attendance/attendance-check`,
  ...(params ? [params] : []),
];

export type AttendanceGetAttendanceCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof attendanceGetAttendanceCheck>>
>;
export type AttendanceGetAttendanceCheckQueryError = ErrorType<void>;

export const useAttendanceGetAttendanceCheck = <
  TData = Awaited<ReturnType<typeof attendanceGetAttendanceCheck>>,
  TError = ErrorType<void>,
>(
  params: AttendanceGetAttendanceCheckParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof attendanceGetAttendanceCheck>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAttendanceGetAttendanceCheckQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof attendanceGetAttendanceCheck>>> = ({ signal }) =>
    attendanceGetAttendanceCheck(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof attendanceGetAttendanceCheck>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 게시판 목록 조회
 */
export const boardFindAll = (
  params: BoardFindAllParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedBoardDto>({ url: `/api/boards`, method: 'get', params, signal }, options);
};

export const getBoardFindAllQueryKey = (params: BoardFindAllParams) => [`/api/boards`, ...(params ? [params] : [])];

export type BoardFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof boardFindAll>>>;
export type BoardFindAllQueryError = ErrorType<unknown>;

export const useBoardFindAll = <TData = Awaited<ReturnType<typeof boardFindAll>>, TError = ErrorType<unknown>>(
  params: BoardFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof boardFindAll>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBoardFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof boardFindAll>>> = ({ signal }) =>
    boardFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof boardFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 게시판 글 생성
 */
export const boardCreate = (
  requestCreateBoardDto: RequestCreateBoardDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Board>(
    {
      url: `/api/boards`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateBoardDto,
    },
    options,
  );
};

export type BoardCreateMutationResult = NonNullable<Awaited<ReturnType<typeof boardCreate>>>;
export type BoardCreateMutationBody = RequestCreateBoardDto;
export type BoardCreateMutationError = ErrorType<unknown>;

export const useBoardCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boardCreate>>,
    TError,
    { data: RequestCreateBoardDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof boardCreate>>, { data: RequestCreateBoardDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return boardCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof boardCreate>>, TError, { data: RequestCreateBoardDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학급 게시판 제목 조회 (메시지용)
 */
export const boardFindTitleByIds = (
  params?: BoardFindTitleByIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Board[]>({ url: `/api/boards/title`, method: 'get', params, signal }, options);
};

export const getBoardFindTitleByIdsQueryKey = (params?: BoardFindTitleByIdsParams) => [
  `/api/boards/title`,
  ...(params ? [params] : []),
];

export type BoardFindTitleByIdsQueryResult = NonNullable<Awaited<ReturnType<typeof boardFindTitleByIds>>>;
export type BoardFindTitleByIdsQueryError = ErrorType<void>;

export const useBoardFindTitleByIds = <
  TData = Awaited<ReturnType<typeof boardFindTitleByIds>>,
  TError = ErrorType<void>,
>(
  params?: BoardFindTitleByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof boardFindTitleByIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBoardFindTitleByIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof boardFindTitleByIds>>> = ({ signal }) =>
    boardFindTitleByIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof boardFindTitleByIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 게시판 단건 조회
 */
export const boardFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Board>({ url: `/api/boards/${id}`, method: 'get', signal }, options);
};

export const getBoardFindOneQueryKey = (id: number) => [`/api/boards/${id}`];

export type BoardFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof boardFindOne>>>;
export type BoardFindOneQueryError = ErrorType<unknown>;

export const useBoardFindOne = <TData = Awaited<ReturnType<typeof boardFindOne>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof boardFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBoardFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof boardFindOne>>> = ({ signal }) =>
    boardFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof boardFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 게시판 글 수정
 */
export const boardUpdate = (
  id: number,
  requestUpdateBoardDto: RequestUpdateBoardDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/boards/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateBoardDto,
    },
    options,
  );
};

export type BoardUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof boardUpdate>>>;
export type BoardUpdateMutationBody = RequestUpdateBoardDto;
export type BoardUpdateMutationError = ErrorType<void>;

export const useBoardUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boardUpdate>>,
    TError,
    { id: number; data: RequestUpdateBoardDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boardUpdate>>,
    { id: number; data: RequestUpdateBoardDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return boardUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof boardUpdate>>,
    TError,
    { id: number; data: RequestUpdateBoardDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학급 게시판 글 삭제
 */
export const boardDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/boards/${id}`, method: 'delete' }, options);
};

export type BoardDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof boardDelete>>>;

export type BoardDeleteMutationError = ErrorType<unknown>;

export const useBoardDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof boardDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof boardDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return boardDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof boardDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 급식표 목록
 */
export const canteenFindByYearMonth = (
  params: CanteenFindByYearMonthParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Canteen[]>({ url: `/api/canteens`, method: 'get', params, signal }, options);
};

export const getCanteenFindByYearMonthQueryKey = (params: CanteenFindByYearMonthParams) => [
  `/api/canteens`,
  ...(params ? [params] : []),
];

export type CanteenFindByYearMonthQueryResult = NonNullable<Awaited<ReturnType<typeof canteenFindByYearMonth>>>;
export type CanteenFindByYearMonthQueryError = ErrorType<unknown>;

export const useCanteenFindByYearMonth = <
  TData = Awaited<ReturnType<typeof canteenFindByYearMonth>>,
  TError = ErrorType<unknown>,
>(
  params: CanteenFindByYearMonthParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof canteenFindByYearMonth>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCanteenFindByYearMonthQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof canteenFindByYearMonth>>> = ({ signal }) =>
    canteenFindByYearMonth(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof canteenFindByYearMonth>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 급식표 추가/수정
 */
export const canteenCreateOrUpdate = (
  requestUpsertCanteenDto: RequestUpsertCanteenDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Canteen>(
    {
      url: `/api/canteens`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpsertCanteenDto,
    },
    options,
  );
};

export type CanteenCreateOrUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof canteenCreateOrUpdate>>>;
export type CanteenCreateOrUpdateMutationBody = RequestUpsertCanteenDto;
export type CanteenCreateOrUpdateMutationError = ErrorType<void>;

export const useCanteenCreateOrUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof canteenCreateOrUpdate>>,
    TError,
    { data: RequestUpsertCanteenDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof canteenCreateOrUpdate>>,
    { data: RequestUpsertCanteenDto }
  > = (props) => {
    const { data } = props ?? {};

    return canteenCreateOrUpdate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof canteenCreateOrUpdate>>,
    TError,
    { data: RequestUpsertCanteenDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 카테고리별 코드 목록 조회
 */
export const codeGetCodesByCategory = (
  category: Category,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Code[]>({ url: `/api/code/codes-by-category/${category}`, method: 'get', signal }, options);
};

export const getCodeGetCodesByCategoryQueryKey = (category: Category) => [`/api/code/codes-by-category/${category}`];

export type CodeGetCodesByCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof codeGetCodesByCategory>>>;
export type CodeGetCodesByCategoryQueryError = ErrorType<unknown>;

export const useCodeGetCodesByCategory = <
  TData = Awaited<ReturnType<typeof codeGetCodesByCategory>>,
  TError = ErrorType<unknown>,
>(
  category: Category,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof codeGetCodesByCategory>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCodeGetCodesByCategoryQueryKey(category);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof codeGetCodesByCategory>>> = ({ signal }) =>
    codeGetCodesByCategory(category, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof codeGetCodesByCategory>>, TError, TData>(queryKey, queryFn, {
    enabled: !!category,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생아이디로 학생상담글 조회 (학생카드)
 */
export const coulselingFindByStudentId = (
  studentId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseCoulselingDetailDto[]>(
    { url: `/api/coulseling/coulseling-by-student-id/${studentId}`, method: 'get', signal },
    options,
  );
};

export const getCoulselingFindByStudentIdQueryKey = (studentId: number) => [
  `/api/coulseling/coulseling-by-student-id/${studentId}`,
];

export type CoulselingFindByStudentIdQueryResult = NonNullable<Awaited<ReturnType<typeof coulselingFindByStudentId>>>;
export type CoulselingFindByStudentIdQueryError = ErrorType<unknown>;

export const useCoulselingFindByStudentId = <
  TData = Awaited<ReturnType<typeof coulselingFindByStudentId>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof coulselingFindByStudentId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCoulselingFindByStudentIdQueryKey(studentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof coulselingFindByStudentId>>> = ({ signal }) =>
    coulselingFindByStudentId(studentId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof coulselingFindByStudentId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!studentId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생상담글 생성 (학생카드)
 */
export const coulselingCreate = (
  requestCreateCoulselingDto: RequestCreateCoulselingDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Coulseling>(
    {
      url: `/api/coulseling`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateCoulselingDto,
    },
    options,
  );
};

export type CoulselingCreateMutationResult = NonNullable<Awaited<ReturnType<typeof coulselingCreate>>>;
export type CoulselingCreateMutationBody = RequestCreateCoulselingDto;
export type CoulselingCreateMutationError = ErrorType<unknown>;

export const useCoulselingCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coulselingCreate>>,
    TError,
    { data: RequestCreateCoulselingDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coulselingCreate>>,
    { data: RequestCreateCoulselingDto }
  > = (props) => {
    const { data } = props ?? {};

    return coulselingCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof coulselingCreate>>,
    TError,
    { data: RequestCreateCoulselingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생상담글 수정 (학생카드)
 */
export const coulselingUpdate = (
  id: number,
  requestCreateCoulselingDto: RequestCreateCoulselingDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/coulseling/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateCoulselingDto,
    },
    options,
  );
};

export type CoulselingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof coulselingUpdate>>>;
export type CoulselingUpdateMutationBody = RequestCreateCoulselingDto;
export type CoulselingUpdateMutationError = ErrorType<void>;

export const useCoulselingUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coulselingUpdate>>,
    TError,
    { id: number; data: RequestCreateCoulselingDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coulselingUpdate>>,
    { id: number; data: RequestCreateCoulselingDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return coulselingUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof coulselingUpdate>>,
    TError,
    { id: number; data: RequestCreateCoulselingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생상담 글 삭제
 */
export const coulselingDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/coulseling/${id}`, method: 'delete' }, options);
};

export type CoulselingDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof coulselingDelete>>>;

export type CoulselingDeleteMutationError = ErrorType<unknown>;

export const useCoulselingDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof coulselingDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof coulselingDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return coulselingDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof coulselingDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 상담 학생의 상세정보 조회 (학생, 보호자, 소속그룹)
 */
export const coulselingFindCoulselingDetailStudentByStudentId = (
  studentId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseCoulselingDetailStudentDto>(
    { url: `/api/coulseling/detail/student/${studentId}`, method: 'get', signal },
    options,
  );
};

export const getCoulselingFindCoulselingDetailStudentByStudentIdQueryKey = (studentId: number) => [
  `/api/coulseling/detail/student/${studentId}`,
];

export type CoulselingFindCoulselingDetailStudentByStudentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof coulselingFindCoulselingDetailStudentByStudentId>>
>;
export type CoulselingFindCoulselingDetailStudentByStudentIdQueryError = ErrorType<unknown>;

export const useCoulselingFindCoulselingDetailStudentByStudentId = <
  TData = Awaited<ReturnType<typeof coulselingFindCoulselingDetailStudentByStudentId>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coulselingFindCoulselingDetailStudentByStudentId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCoulselingFindCoulselingDetailStudentByStudentIdQueryKey(studentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof coulselingFindCoulselingDetailStudentByStudentId>>> = ({
    signal,
  }) => coulselingFindCoulselingDetailStudentByStudentId(studentId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof coulselingFindCoulselingDetailStudentByStudentId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!studentId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생정보 수정
 */
export const coulselingUpdateStudent = (
  id: number,
  updateStudentDto: UpdateStudentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/coulseling/update/student/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateStudentDto,
    },
    options,
  );
};

export type CoulselingUpdateStudentMutationResult = NonNullable<Awaited<ReturnType<typeof coulselingUpdateStudent>>>;
export type CoulselingUpdateStudentMutationBody = UpdateStudentDto;
export type CoulselingUpdateStudentMutationError = ErrorType<unknown>;

export const useCoulselingUpdateStudent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coulselingUpdateStudent>>,
    TError,
    { id: number; data: UpdateStudentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coulselingUpdateStudent>>,
    { id: number; data: UpdateStudentDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return coulselingUpdateStudent(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof coulselingUpdateStudent>>,
    TError,
    { id: number; data: UpdateStudentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 선생님이 학생을 지정하여 부모 회원가입 요청(알림톡 발송)
 */
export const coulselingSendParentSignUp = (
  studentId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<boolean>({ url: `/api/coulseling/send-parent-sign-up/${studentId}`, method: 'get', signal }, options);
};

export const getCoulselingSendParentSignUpQueryKey = (studentId: number) => [
  `/api/coulseling/send-parent-sign-up/${studentId}`,
];

export type CoulselingSendParentSignUpQueryResult = NonNullable<Awaited<ReturnType<typeof coulselingSendParentSignUp>>>;
export type CoulselingSendParentSignUpQueryError = ErrorType<unknown>;

export const useCoulselingSendParentSignUp = <
  TData = Awaited<ReturnType<typeof coulselingSendParentSignUp>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof coulselingSendParentSignUp>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCoulselingSendParentSignUpQueryKey(studentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof coulselingSendParentSignUp>>> = ({ signal }) =>
    coulselingSendParentSignUp(studentId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof coulselingSendParentSignUp>>, TError, TData>(queryKey, queryFn, {
    enabled: !!studentId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님이 학생을 지정하여 부모 회원가입 요청v2(알림톡 발송)
 */
export const coulselingSendParentSignUpV2 = (
  studentId: number,
  requestSendParentSignUpDto: RequestSendParentSignUpDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/coulseling/send-parent-sign-up-v2/${studentId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestSendParentSignUpDto,
    },
    options,
  );
};

export type CoulselingSendParentSignUpV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof coulselingSendParentSignUpV2>>
>;
export type CoulselingSendParentSignUpV2MutationBody = RequestSendParentSignUpDto;
export type CoulselingSendParentSignUpV2MutationError = ErrorType<unknown>;

export const useCoulselingSendParentSignUpV2 = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coulselingSendParentSignUpV2>>,
    TError,
    { studentId: number; data: RequestSendParentSignUpDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coulselingSendParentSignUpV2>>,
    { studentId: number; data: RequestSendParentSignUpDto }
  > = (props) => {
    const { studentId, data } = props ?? {};

    return coulselingSendParentSignUpV2(studentId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof coulselingSendParentSignUpV2>>,
    TError,
    { studentId: number; data: RequestSendParentSignUpDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 목록(학생용)
 */
export const fieldtripsFindAllByStudent = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Fieldtrip[]>({ url: `/api/fieldtrips/list-by-student`, method: 'get', signal }, options);
};

export const getFieldtripsFindAllByStudentQueryKey = () => [`/api/fieldtrips/list-by-student`];

export type FieldtripsFindAllByStudentQueryResult = NonNullable<Awaited<ReturnType<typeof fieldtripsFindAllByStudent>>>;
export type FieldtripsFindAllByStudentQueryError = ErrorType<unknown>;

export const useFieldtripsFindAllByStudent = <
  TData = Awaited<ReturnType<typeof fieldtripsFindAllByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsFindAllByStudent>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsFindAllByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsFindAllByStudent>>> = ({ signal }) =>
    fieldtripsFindAllByStudent(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsFindAllByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 날짜 다시 계산
 */
export const fieldtripsReCalculateFieldtripDays = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<number>({ url: `/api/fieldtrips/recalculate-days`, method: 'get', signal }, options);
};

export const getFieldtripsReCalculateFieldtripDaysQueryKey = () => [`/api/fieldtrips/recalculate-days`];

export type FieldtripsReCalculateFieldtripDaysQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDays>>
>;
export type FieldtripsReCalculateFieldtripDaysQueryError = ErrorType<unknown>;

export const useFieldtripsReCalculateFieldtripDays = <
  TData = Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDays>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDays>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsReCalculateFieldtripDaysQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDays>>> = ({ signal }) =>
    fieldtripsReCalculateFieldtripDays(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDays>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 날짜 다시 계산
 */
export const fieldtripsReCalculateFieldtripDaysWithUser = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<number>({ url: `/api/fieldtrips/recalculate-days/${id}`, method: 'get', signal }, options);
};

export const getFieldtripsReCalculateFieldtripDaysWithUserQueryKey = (id: number) => [
  `/api/fieldtrips/recalculate-days/${id}`,
];

export type FieldtripsReCalculateFieldtripDaysWithUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUser>>
>;
export type FieldtripsReCalculateFieldtripDaysWithUserQueryError = ErrorType<unknown>;

export const useFieldtripsReCalculateFieldtripDaysWithUser = <
  TData = Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUser>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUser>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsReCalculateFieldtripDaysWithUserQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUser>>> = ({ signal }) =>
    fieldtripsReCalculateFieldtripDaysWithUser(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUser>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 날짜 다시 계산
 */
export const fieldtripsReCalculateFieldtripDaysWithUserId = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<number>({ url: `/api/fieldtrips/recalculate-days/user/${id}`, method: 'get', signal }, options);
};

export const getFieldtripsReCalculateFieldtripDaysWithUserIdQueryKey = (id: number) => [
  `/api/fieldtrips/recalculate-days/user/${id}`,
];

export type FieldtripsReCalculateFieldtripDaysWithUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUserId>>
>;
export type FieldtripsReCalculateFieldtripDaysWithUserIdQueryError = ErrorType<unknown>;

export const useFieldtripsReCalculateFieldtripDaysWithUserId = <
  TData = Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUserId>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUserId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsReCalculateFieldtripDaysWithUserIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUserId>>> = ({
    signal,
  }) => fieldtripsReCalculateFieldtripDaysWithUserId(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsReCalculateFieldtripDaysWithUserId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습현황 엑셀 다운로드(선생님용)
 */
export const fieldtripsDownloadFieldtrips = (
  params: FieldtripsDownloadFieldtripsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/fieldtrips/download`, method: 'get', params, responseType: 'blob', signal },
    options,
  );
};

export const getFieldtripsDownloadFieldtripsQueryKey = (params: FieldtripsDownloadFieldtripsParams) => [
  `/api/fieldtrips/download`,
  ...(params ? [params] : []),
];

export type FieldtripsDownloadFieldtripsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsDownloadFieldtrips>>
>;
export type FieldtripsDownloadFieldtripsQueryError = ErrorType<unknown>;

export const useFieldtripsDownloadFieldtrips = <
  TData = Awaited<ReturnType<typeof fieldtripsDownloadFieldtrips>>,
  TError = ErrorType<unknown>,
>(
  params: FieldtripsDownloadFieldtripsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsDownloadFieldtrips>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsDownloadFieldtripsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsDownloadFieldtrips>>> = ({ signal }) =>
    fieldtripsDownloadFieldtrips(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsDownloadFieldtrips>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 목록(선생님용), 레거시 거의 그대로 유지
 */
export const fieldtripsGetFieldtripsByTeacher = (
  params: FieldtripsGetFieldtripsByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedFieldtripDto>({ url: `/api/fieldtrips`, method: 'get', params, signal }, options);
};

export const getFieldtripsGetFieldtripsByTeacherQueryKey = (params: FieldtripsGetFieldtripsByTeacherParams) => [
  `/api/fieldtrips`,
  ...(params ? [params] : []),
];

export type FieldtripsGetFieldtripsByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsGetFieldtripsByTeacher>>
>;
export type FieldtripsGetFieldtripsByTeacherQueryError = ErrorType<unknown>;

export const useFieldtripsGetFieldtripsByTeacher = <
  TData = Awaited<ReturnType<typeof fieldtripsGetFieldtripsByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: FieldtripsGetFieldtripsByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsGetFieldtripsByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsGetFieldtripsByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsGetFieldtripsByTeacher>>> = ({ signal }) =>
    fieldtripsGetFieldtripsByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsGetFieldtripsByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 추가
 */
export const fieldtripsCreate = (
  requestCreateFieldtripDto: RequestCreateFieldtripDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Fieldtrip>(
    {
      url: `/api/fieldtrips`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateFieldtripDto,
    },
    options,
  );
};

export type FieldtripsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof fieldtripsCreate>>>;
export type FieldtripsCreateMutationBody = RequestCreateFieldtripDto;
export type FieldtripsCreateMutationError = ErrorType<void>;

export const useFieldtripsCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsCreate>>,
    TError,
    { data: RequestCreateFieldtripDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsCreate>>,
    { data: RequestCreateFieldtripDto }
  > = (props) => {
    const { data } = props ?? {};

    return fieldtripsCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsCreate>>,
    TError,
    { data: RequestCreateFieldtripDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 목록(선생님용), 레거시 거의 그대로 유지
 */
export const fieldtripsGetFieldtripsHistory = (
  params: FieldtripsGetFieldtripsHistoryParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedFieldtripDto>(
    { url: `/api/fieldtrips/history`, method: 'get', params, signal },
    options,
  );
};

export const getFieldtripsGetFieldtripsHistoryQueryKey = (params: FieldtripsGetFieldtripsHistoryParams) => [
  `/api/fieldtrips/history`,
  ...(params ? [params] : []),
];

export type FieldtripsGetFieldtripsHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsGetFieldtripsHistory>>
>;
export type FieldtripsGetFieldtripsHistoryQueryError = ErrorType<unknown>;

export const useFieldtripsGetFieldtripsHistory = <
  TData = Awaited<ReturnType<typeof fieldtripsGetFieldtripsHistory>>,
  TError = ErrorType<unknown>,
>(
  params: FieldtripsGetFieldtripsHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsGetFieldtripsHistory>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsGetFieldtripsHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsGetFieldtripsHistory>>> = ({ signal }) =>
    fieldtripsGetFieldtripsHistory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsGetFieldtripsHistory>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 결과보고서 목록(선생님용), 레거시 그대로 유지
 */
export const fieldtripsGetResultsByTeacher = (
  params: FieldtripsGetResultsByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedFieldtripDto>(
    { url: `/api/fieldtrips/result`, method: 'get', params, signal },
    options,
  );
};

export const getFieldtripsGetResultsByTeacherQueryKey = (params: FieldtripsGetResultsByTeacherParams) => [
  `/api/fieldtrips/result`,
  ...(params ? [params] : []),
];

export type FieldtripsGetResultsByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsGetResultsByTeacher>>
>;
export type FieldtripsGetResultsByTeacherQueryError = ErrorType<unknown>;

export const useFieldtripsGetResultsByTeacher = <
  TData = Awaited<ReturnType<typeof fieldtripsGetResultsByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: FieldtripsGetResultsByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsGetResultsByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsGetResultsByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsGetResultsByTeacher>>> = ({ signal }) =>
    fieldtripsGetResultsByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsGetResultsByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * TODO /result/:id endpoint 제거 필요
 * @summary 체험학습 신청서 단건 조회
 */
export const fieldtripsFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Fieldtrip>({ url: `/api/fieldtrips/${id}`, method: 'get', signal }, options);
};

export const getFieldtripsFindOneQueryKey = (id: number) => [`/api/fieldtrips/${id}`];

export type FieldtripsFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof fieldtripsFindOne>>>;
export type FieldtripsFindOneQueryError = ErrorType<void>;

export const useFieldtripsFindOne = <TData = Awaited<ReturnType<typeof fieldtripsFindOne>>, TError = ErrorType<void>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsFindOne>>> = ({ signal }) =>
    fieldtripsFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 수정
 */
export const fieldtripsUpdate = (
  id: number,
  requestCreateFieldtripDto: RequestCreateFieldtripDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Fieldtrip>(
    {
      url: `/api/fieldtrips/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateFieldtripDto,
    },
    options,
  );
};

export type FieldtripsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof fieldtripsUpdate>>>;
export type FieldtripsUpdateMutationBody = RequestCreateFieldtripDto;
export type FieldtripsUpdateMutationError = ErrorType<void>;

export const useFieldtripsUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsUpdate>>,
    TError,
    { id: number; data: RequestCreateFieldtripDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsUpdate>>,
    { id: number; data: RequestCreateFieldtripDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsUpdate>>,
    TError,
    { id: number; data: RequestCreateFieldtripDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 삭제
 */
export const fieldtripsDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/fieldtrips/${id}`, method: 'delete' }, options);
};

export type FieldtripsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof fieldtripsDelete>>>;

export type FieldtripsDeleteMutationError = ErrorType<unknown>;

export const useFieldtripsDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof fieldtripsDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof fieldtripsDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return fieldtripsDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof fieldtripsDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 체험학습 신청서 단건 조회(by UUID)
 */
export const fieldtripsFindOneByUUID = (
  uuid: string,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Fieldtrip>({ url: `/api/fieldtrips/by-uuid/${uuid}`, method: 'get', signal }, options);
};

export const getFieldtripsFindOneByUUIDQueryKey = (uuid: string) => [`/api/fieldtrips/by-uuid/${uuid}`];

export type FieldtripsFindOneByUUIDQueryResult = NonNullable<Awaited<ReturnType<typeof fieldtripsFindOneByUUID>>>;
export type FieldtripsFindOneByUUIDQueryError = ErrorType<unknown>;

export const useFieldtripsFindOneByUUID = <
  TData = Awaited<ReturnType<typeof fieldtripsFindOneByUUID>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsFindOneByUUID>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsFindOneByUUIDQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsFindOneByUUID>>> = ({ signal }) =>
    fieldtripsFindOneByUUID(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsFindOneByUUID>>, TError, TData>(queryKey, queryFn, {
    enabled: !!uuid,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 수정(선생님용)
 */
export const fieldtripsUpdateByTeacher = (
  id: number,
  requestUpdateFieldtripDto: RequestUpdateFieldtripDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Fieldtrip>(
    {
      url: `/api/fieldtrips/update-by-teacher/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateFieldtripDto,
    },
    options,
  );
};

export type FieldtripsUpdateByTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsUpdateByTeacher>>
>;
export type FieldtripsUpdateByTeacherMutationBody = RequestUpdateFieldtripDto;
export type FieldtripsUpdateByTeacherMutationError = ErrorType<void>;

export const useFieldtripsUpdateByTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsUpdateByTeacher>>,
    { id: number; data: RequestUpdateFieldtripDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsUpdateByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 삭제요청
 */
export const fieldtripsRequestDelete = (
  id: number,
  fieldtripsRequestDeleteBody: unknown,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Fieldtrip>(
    {
      url: `/api/fieldtrips/request-delete/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsRequestDeleteBody,
    },
    options,
  );
};

export type FieldtripsRequestDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof fieldtripsRequestDelete>>>;
export type FieldtripsRequestDeleteMutationBody = unknown;
export type FieldtripsRequestDeleteMutationError = ErrorType<void>;

export const useFieldtripsRequestDelete = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsRequestDelete>>,
    TError,
    { id: number; data: unknown },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsRequestDelete>>,
    { id: number; data: unknown }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsRequestDelete(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsRequestDelete>>,
    TError,
    { id: number; data: unknown },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 반려
 */
export const fieldtripsDeny = (
  id: number,
  fieldtripsDenyBody: FieldtripsDenyBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsDenyBody,
    },
    options,
  );
};

export type FieldtripsDenyMutationResult = NonNullable<Awaited<ReturnType<typeof fieldtripsDeny>>>;
export type FieldtripsDenyMutationBody = FieldtripsDenyBody;
export type FieldtripsDenyMutationError = ErrorType<unknown>;

export const useFieldtripsDeny = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsDeny>>,
    TError,
    { id: number; data: FieldtripsDenyBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsDeny>>,
    { id: number; data: FieldtripsDenyBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsDeny(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsDeny>>,
    TError,
    { id: number; data: FieldtripsDenyBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 승인(부모님)
 */
export const fieldtripsApproveByParent = (
  uuid: string,
  fieldtripsApproveByParentBody: FieldtripsApproveByParentBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/approve-by-parent/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsApproveByParentBody,
    },
    options,
  );
};

export type FieldtripsApproveByParentMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsApproveByParent>>
>;
export type FieldtripsApproveByParentMutationBody = FieldtripsApproveByParentBody;
export type FieldtripsApproveByParentMutationError = ErrorType<unknown>;

export const useFieldtripsApproveByParent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsApproveByParent>>,
    TError,
    { uuid: string; data: FieldtripsApproveByParentBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsApproveByParent>>,
    { uuid: string; data: FieldtripsApproveByParentBody }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return fieldtripsApproveByParent(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsApproveByParent>>,
    TError,
    { uuid: string; data: FieldtripsApproveByParentBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 승인(부모님) from 슈퍼스쿨앱
 */
export const fieldtripsApproveByParentApp = (
  id: number,
  fieldtripsApproveByParentAppBody: FieldtripsApproveByParentAppBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/approve-by-parentapp/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsApproveByParentAppBody,
    },
    options,
  );
};

export type FieldtripsApproveByParentAppMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsApproveByParentApp>>
>;
export type FieldtripsApproveByParentAppMutationBody = FieldtripsApproveByParentAppBody;
export type FieldtripsApproveByParentAppMutationError = ErrorType<unknown>;

export const useFieldtripsApproveByParentApp = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsApproveByParentApp>>,
    TError,
    { id: number; data: FieldtripsApproveByParentAppBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsApproveByParentApp>>,
    { id: number; data: FieldtripsApproveByParentAppBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsApproveByParentApp(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsApproveByParentApp>>,
    TError,
    { id: number; data: FieldtripsApproveByParentAppBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 승인
 */
export const fieldtripsApprove = (
  id: number,
  fieldtripsApproveBody: FieldtripsApproveBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsApproveBody,
    },
    options,
  );
};

export type FieldtripsApproveMutationResult = NonNullable<Awaited<ReturnType<typeof fieldtripsApprove>>>;
export type FieldtripsApproveMutationBody = FieldtripsApproveBody;
export type FieldtripsApproveMutationError = ErrorType<unknown>;

export const useFieldtripsApprove = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsApprove>>,
    TError,
    { id: number; data: FieldtripsApproveBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsApprove>>,
    { id: number; data: FieldtripsApproveBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsApprove(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsApprove>>,
    TError,
    { id: number; data: FieldtripsApproveBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 부모님 사인 재요청
 */
export const fieldtripsResend = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<void>({ url: `/api/fieldtrips/resend/${id}`, method: 'get', signal }, options);
};

export const getFieldtripsResendQueryKey = (id: number) => [`/api/fieldtrips/resend/${id}`];

export type FieldtripsResendQueryResult = NonNullable<Awaited<ReturnType<typeof fieldtripsResend>>>;
export type FieldtripsResendQueryError = ErrorType<unknown>;

export const useFieldtripsResend = <TData = Awaited<ReturnType<typeof fieldtripsResend>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsResend>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsResendQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsResend>>> = ({ signal }) =>
    fieldtripsResend(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsResend>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 결과보고서 제출
 */
export const fieldtripResultUpdateResult = (
  id: number,
  requestUpdateFieldtripResultDto: RequestUpdateFieldtripResultDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Fieldtrip>(
    {
      url: `/api/fieldtrips/result/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateFieldtripResultDto,
    },
    options,
  );
};

export type FieldtripResultUpdateResultMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultUpdateResult>>
>;
export type FieldtripResultUpdateResultMutationBody = RequestUpdateFieldtripResultDto;
export type FieldtripResultUpdateResultMutationError = ErrorType<void>;

export const useFieldtripResultUpdateResult = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultUpdateResult>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultUpdateResult>>,
    { id: number; data: RequestUpdateFieldtripResultDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultUpdateResult(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultUpdateResult>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 제출 수정(선생님용)
 */
export const fieldtripResultUpdateResultByTeacher = (
  id: number,
  requestUpdateFieldtripResultByTeacherDto: RequestUpdateFieldtripResultByTeacherDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Fieldtrip>(
    {
      url: `/api/fieldtrips/result/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateFieldtripResultByTeacherDto,
    },
    options,
  );
};

export type FieldtripResultUpdateResultByTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultUpdateResultByTeacher>>
>;
export type FieldtripResultUpdateResultByTeacherMutationBody = RequestUpdateFieldtripResultByTeacherDto;
export type FieldtripResultUpdateResultByTeacherMutationError = ErrorType<void>;

export const useFieldtripResultUpdateResultByTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultUpdateResultByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultByTeacherDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultUpdateResultByTeacher>>,
    { id: number; data: RequestUpdateFieldtripResultByTeacherDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultUpdateResultByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultUpdateResultByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultByTeacherDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 반려
 */
export const fieldtripResultDenyResult = (
  id: number,
  fieldtripResultDenyResultBody: FieldtripResultDenyResultBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/result/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripResultDenyResultBody,
    },
    options,
  );
};

export type FieldtripResultDenyResultMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultDenyResult>>
>;
export type FieldtripResultDenyResultMutationBody = FieldtripResultDenyResultBody;
export type FieldtripResultDenyResultMutationError = ErrorType<unknown>;

export const useFieldtripResultDenyResult = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultDenyResult>>,
    TError,
    { id: number; data: FieldtripResultDenyResultBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultDenyResult>>,
    { id: number; data: FieldtripResultDenyResultBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultDenyResult(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultDenyResult>>,
    TError,
    { id: number; data: FieldtripResultDenyResultBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 승인(부모님)
 */
export const fieldtripResultApproveResultByParent = (
  uuid: string,
  fieldtripResultApproveResultByParentBody: FieldtripResultApproveResultByParentBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/result/approve-by-parent/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripResultApproveResultByParentBody,
    },
    options,
  );
};

export type FieldtripResultApproveResultByParentMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultApproveResultByParent>>
>;
export type FieldtripResultApproveResultByParentMutationBody = FieldtripResultApproveResultByParentBody;
export type FieldtripResultApproveResultByParentMutationError = ErrorType<unknown>;

export const useFieldtripResultApproveResultByParent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultApproveResultByParent>>,
    TError,
    { uuid: string; data: FieldtripResultApproveResultByParentBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultApproveResultByParent>>,
    { uuid: string; data: FieldtripResultApproveResultByParentBody }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return fieldtripResultApproveResultByParent(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultApproveResultByParent>>,
    TError,
    { uuid: string; data: FieldtripResultApproveResultByParentBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 승인(부모님)
 */
export const fieldtripResultApproveResultByParentApp = (
  id: number,
  fieldtripResultApproveResultByParentAppBody: FieldtripResultApproveResultByParentAppBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/result/approve-by-parentapp/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripResultApproveResultByParentAppBody,
    },
    options,
  );
};

export type FieldtripResultApproveResultByParentAppMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultApproveResultByParentApp>>
>;
export type FieldtripResultApproveResultByParentAppMutationBody = FieldtripResultApproveResultByParentAppBody;
export type FieldtripResultApproveResultByParentAppMutationError = ErrorType<unknown>;

export const useFieldtripResultApproveResultByParentApp = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultApproveResultByParentApp>>,
    TError,
    { id: number; data: FieldtripResultApproveResultByParentAppBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultApproveResultByParentApp>>,
    { id: number; data: FieldtripResultApproveResultByParentAppBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultApproveResultByParentApp(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultApproveResultByParentApp>>,
    TError,
    { id: number; data: FieldtripResultApproveResultByParentAppBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 승인
 */
export const fieldtripResultApproveResult = (
  id: number,
  fieldtripResultApproveResultBody: FieldtripResultApproveResultBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/fieldtrips/result/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripResultApproveResultBody,
    },
    options,
  );
};

export type FieldtripResultApproveResultMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultApproveResult>>
>;
export type FieldtripResultApproveResultMutationBody = FieldtripResultApproveResultBody;
export type FieldtripResultApproveResultMutationError = ErrorType<unknown>;

export const useFieldtripResultApproveResult = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultApproveResult>>,
    TError,
    { id: number; data: FieldtripResultApproveResultBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultApproveResult>>,
    { id: number; data: FieldtripResultApproveResultBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultApproveResult(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultApproveResult>>,
    TError,
    { id: number; data: FieldtripResultApproveResultBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 부모님 사인 재요청
 */
export const fieldtripResultResend = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/fieldtrips/result/resend/${id}`, method: 'post' }, options);
};

export type FieldtripResultResendMutationResult = NonNullable<Awaited<ReturnType<typeof fieldtripResultResend>>>;

export type FieldtripResultResendMutationError = ErrorType<unknown>;

export const useFieldtripResultResend = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof fieldtripResultResend>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof fieldtripResultResend>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return fieldtripResultResend(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof fieldtripResultResend>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * 선생님인 경우 선생님 그룹, 학생인 경우 학생 그룹, 부모님인 경우 학생들의 그룹
 * @summary 그룹 조회(선생님, 부모, 학생 공통)
 */
export const groupsFindAll = (
  params: GroupsFindAllParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Group[]>({ url: `/api/groups`, method: 'get', params, signal }, options);
};

export const getGroupsFindAllQueryKey = (params: GroupsFindAllParams) => [`/api/groups`, ...(params ? [params] : [])];

export type GroupsFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindAll>>>;
export type GroupsFindAllQueryError = ErrorType<unknown>;

export const useGroupsFindAll = <TData = Awaited<ReturnType<typeof groupsFindAll>>, TError = ErrorType<unknown>>(
  params: GroupsFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindAll>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindAll>>> = ({ signal }) =>
    groupsFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 추가
 */
export const groupsCreate = (
  requestCreateGroupDto: RequestCreateGroupDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Group>(
    {
      url: `/api/groups`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateGroupDto,
    },
    options,
  );
};

export type GroupsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof groupsCreate>>>;
export type GroupsCreateMutationBody = RequestCreateGroupDto;
export type GroupsCreateMutationError = ErrorType<ResponseErrorDto>;

export const useGroupsCreate = <TError = ErrorType<ResponseErrorDto>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsCreate>>,
    TError,
    { data: RequestCreateGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupsCreate>>, { data: RequestCreateGroupDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return groupsCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof groupsCreate>>, TError, { data: RequestCreateGroupDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * 나이스 비교용 출결 데이터 조회 (선생님용)
 * @summary 그룹 조회(나이스 비교용)
 */
export const groupsFindComparison = (
  params: GroupsFindComparisonParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Group[]>({ url: `/api/groups/comparison`, method: 'get', params, signal }, options);
};

export const getGroupsFindComparisonQueryKey = (params: GroupsFindComparisonParams) => [
  `/api/groups/comparison`,
  ...(params ? [params] : []),
];

export type GroupsFindComparisonQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindComparison>>>;
export type GroupsFindComparisonQueryError = ErrorType<unknown>;

export const useGroupsFindComparison = <
  TData = Awaited<ReturnType<typeof groupsFindComparison>>,
  TError = ErrorType<unknown>,
>(
  params: GroupsFindComparisonParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindComparison>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindComparisonQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindComparison>>> = ({ signal }) =>
    groupsFindComparison(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindComparison>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 내 학교의 모든 KLASS 그룹 조회
 */
export const groupsFindAllKlass = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Group[]>({ url: `/api/groups/klass-groups`, method: 'get', signal }, options);
};

export const getGroupsFindAllKlassQueryKey = () => [`/api/groups/klass-groups`];

export type GroupsFindAllKlassQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindAllKlass>>>;
export type GroupsFindAllKlassQueryError = ErrorType<unknown>;

export const useGroupsFindAllKlass = <
  TData = Awaited<ReturnType<typeof groupsFindAllKlass>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindAllKlass>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindAllKlassQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindAllKlass>>> = ({ signal }) =>
    groupsFindAllKlass(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindAllKlass>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 조회(id array)
 */
export const groupsFindAllByIds = (
  params: GroupsFindAllByIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseGroupByIdsDto>({ url: `/api/groups/groups-by-ids`, method: 'get', params, signal }, options);
};

export const getGroupsFindAllByIdsQueryKey = (params: GroupsFindAllByIdsParams) => [
  `/api/groups/groups-by-ids`,
  ...(params ? [params] : []),
];

export type GroupsFindAllByIdsQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindAllByIds>>>;
export type GroupsFindAllByIdsQueryError = ErrorType<unknown>;

export const useGroupsFindAllByIds = <
  TData = Awaited<ReturnType<typeof groupsFindAllByIds>>,
  TError = ErrorType<unknown>,
>(
  params: GroupsFindAllByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindAllByIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindAllByIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindAllByIds>>> = ({ signal }) =>
    groupsFindAllByIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindAllByIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학교의 klass 그룹 조회(선생님용)
 */
export const groupsFindAllKlassBySchool = (
  params?: GroupsFindAllKlassBySchoolParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseGroupDto[]>(
    { url: `/api/groups/get-klass-by-school`, method: 'get', params, signal },
    options,
  );
};

export const getGroupsFindAllKlassBySchoolQueryKey = (params?: GroupsFindAllKlassBySchoolParams) => [
  `/api/groups/get-klass-by-school`,
  ...(params ? [params] : []),
];

export type GroupsFindAllKlassBySchoolQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindAllKlassBySchool>>>;
export type GroupsFindAllKlassBySchoolQueryError = ErrorType<unknown>;

export const useGroupsFindAllKlassBySchool = <
  TData = Awaited<ReturnType<typeof groupsFindAllKlassBySchool>>,
  TError = ErrorType<unknown>,
>(
  params?: GroupsFindAllKlassBySchoolParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindAllKlassBySchool>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindAllKlassBySchoolQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindAllKlassBySchool>>> = ({ signal }) =>
    groupsFindAllKlassBySchool(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindAllKlassBySchool>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 klass 그룹 조회(선생님용)
 */
export const groupsFindKlassByUser = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseGroupDto[]>({ url: `/api/groups/get-klass-by-user`, method: 'get', signal }, options);
};

export const getGroupsFindKlassByUserQueryKey = () => [`/api/groups/get-klass-by-user`];

export type GroupsFindKlassByUserQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindKlassByUser>>>;
export type GroupsFindKlassByUserQueryError = ErrorType<unknown>;

export const useGroupsFindKlassByUser = <
  TData = Awaited<ReturnType<typeof groupsFindKlassByUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindKlassByUser>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindKlassByUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindKlassByUser>>> = ({ signal }) =>
    groupsFindKlassByUser(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindKlassByUser>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 klub 그룹 조회(선생님용)
 */
export const groupsFindKlubBySchoolIdAndUserId = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseGroupDto[]>({ url: `/api/groups/get-klub-by-user`, method: 'get', signal }, options);
};

export const getGroupsFindKlubBySchoolIdAndUserIdQueryKey = () => [`/api/groups/get-klub-by-user`];

export type GroupsFindKlubBySchoolIdAndUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsFindKlubBySchoolIdAndUserId>>
>;
export type GroupsFindKlubBySchoolIdAndUserIdQueryError = ErrorType<unknown>;

export const useGroupsFindKlubBySchoolIdAndUserId = <
  TData = Awaited<ReturnType<typeof groupsFindKlubBySchoolIdAndUserId>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindKlubBySchoolIdAndUserId>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindKlubBySchoolIdAndUserIdQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindKlubBySchoolIdAndUserId>>> = ({ signal }) =>
    groupsFindKlubBySchoolIdAndUserId(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindKlubBySchoolIdAndUserId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님 개인별 모든 과목 조회
 */
export const groupsFindSubjectByUser = (
  params?: GroupsFindSubjectByUserParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseSubjectGroupDto[]>(
    { url: `/api/groups/get-subject-by-user`, method: 'get', params, signal },
    options,
  );
};

export const getGroupsFindSubjectByUserQueryKey = (params?: GroupsFindSubjectByUserParams) => [
  `/api/groups/get-subject-by-user`,
  ...(params ? [params] : []),
];

export type GroupsFindSubjectByUserQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindSubjectByUser>>>;
export type GroupsFindSubjectByUserQueryError = ErrorType<unknown>;

export const useGroupsFindSubjectByUser = <
  TData = Awaited<ReturnType<typeof groupsFindSubjectByUser>>,
  TError = ErrorType<unknown>,
>(
  params?: GroupsFindSubjectByUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindSubjectByUser>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindSubjectByUserQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindSubjectByUser>>> = ({ signal }) =>
    groupsFindSubjectByUser(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindSubjectByUser>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 시간표 그룹 조회
 */
export const groupsFindLectureGroupsByTeacher = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Group[]>({ url: `/api/groups/lecture`, method: 'get', signal }, options);
};

export const getGroupsFindLectureGroupsByTeacherQueryKey = () => [`/api/groups/lecture`];

export type GroupsFindLectureGroupsByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsFindLectureGroupsByTeacher>>
>;
export type GroupsFindLectureGroupsByTeacherQueryError = ErrorType<unknown>;

export const useGroupsFindLectureGroupsByTeacher = <
  TData = Awaited<ReturnType<typeof groupsFindLectureGroupsByTeacher>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindLectureGroupsByTeacher>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindLectureGroupsByTeacherQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindLectureGroupsByTeacher>>> = ({ signal }) =>
    groupsFindLectureGroupsByTeacher(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindLectureGroupsByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 단건 조회
 */
export const groupsFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Group>({ url: `/api/groups/${id}`, method: 'get', signal }, options);
};

export const getGroupsFindOneQueryKey = (id: number) => [`/api/groups/${id}`];

export type GroupsFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof groupsFindOne>>>;
export type GroupsFindOneQueryError = ErrorType<unknown>;

export const useGroupsFindOne = <TData = Awaited<ReturnType<typeof groupsFindOne>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsFindOne>>> = ({ signal }) =>
    groupsFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 수정
 */
export const groupsUpdate = (
  id: number,
  requestCreateGroupDto: RequestCreateGroupDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Group>(
    {
      url: `/api/groups/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateGroupDto,
    },
    options,
  );
};

export type GroupsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof groupsUpdate>>>;
export type GroupsUpdateMutationBody = RequestCreateGroupDto;
export type GroupsUpdateMutationError = ErrorType<void>;

export const useGroupsUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsUpdate>>,
    TError,
    { id: number; data: RequestCreateGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupsUpdate>>,
    { id: number; data: RequestCreateGroupDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return groupsUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupsUpdate>>,
    TError,
    { id: number; data: RequestCreateGroupDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 그룹 삭제
 */
export const groupsDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/groups/${id}`, method: 'delete' }, options);
};

export type GroupsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof groupsDelete>>>;

export type GroupsDeleteMutationError = ErrorType<unknown>;

export const useGroupsDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof groupsDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupsDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return groupsDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof groupsDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 시간표 v3 자리배치도 업데이트
 */
export const groupsUpdateSeatPosition = (
  id: number,
  groupsUpdateSeatPositionBody: GroupsUpdateSeatPositionBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Group>(
    {
      url: `/api/groups/update-seat-position/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: groupsUpdateSeatPositionBody,
    },
    options,
  );
};

export type GroupsUpdateSeatPositionMutationResult = NonNullable<Awaited<ReturnType<typeof groupsUpdateSeatPosition>>>;
export type GroupsUpdateSeatPositionMutationBody = GroupsUpdateSeatPositionBody;
export type GroupsUpdateSeatPositionMutationError = ErrorType<unknown>;

export const useGroupsUpdateSeatPosition = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsUpdateSeatPosition>>,
    TError,
    { id: number; data: GroupsUpdateSeatPositionBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupsUpdateSeatPosition>>,
    { id: number; data: GroupsUpdateSeatPositionBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return groupsUpdateSeatPosition(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupsUpdateSeatPosition>>,
    TError,
    { id: number; data: GroupsUpdateSeatPositionBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 파일 업로드
 */
export const imagesUploadImage = (
  uploadFileType: UploadFileTypeEnum,
  imagesUploadImageBody: ImagesUploadImageBody,
  options?: SecondParameter<typeof mutator>,
) => {
  const formData = new FormData();
  if (imagesUploadImageBody.file !== undefined) {
    formData.append('file', imagesUploadImageBody.file);
  }

  return mutator<string>(
    {
      url: `/api/images/${uploadFileType}`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export type ImagesUploadImageMutationResult = NonNullable<Awaited<ReturnType<typeof imagesUploadImage>>>;
export type ImagesUploadImageMutationBody = ImagesUploadImageBody;
export type ImagesUploadImageMutationError = ErrorType<unknown>;

export const useImagesUploadImage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imagesUploadImage>>,
    TError,
    { uploadFileType: UploadFileTypeEnum; data: ImagesUploadImageBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imagesUploadImage>>,
    { uploadFileType: UploadFileTypeEnum; data: ImagesUploadImageBody }
  > = (props) => {
    const { uploadFileType, data } = props ?? {};

    return imagesUploadImage(uploadFileType, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof imagesUploadImage>>,
    TError,
    { uploadFileType: UploadFileTypeEnum; data: ImagesUploadImageBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @deprecated
 * @summary http://localhost:8000/api/docs#/%ED%95%99%EC%83%9D%ED%99%9C%EB%8F%99/StudentActivityController_findByUserId
 */
export const lifeRecordGetLifeActivityByStudent = (
  studentId: number,
  params: LifeRecordGetLifeActivityByStudentParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<void>({ url: `/api/life_record/student/${studentId}`, method: 'get', params, signal }, options);
};

export const getLifeRecordGetLifeActivityByStudentQueryKey = (
  studentId: number,
  params: LifeRecordGetLifeActivityByStudentParams,
) => [`/api/life_record/student/${studentId}`, ...(params ? [params] : [])];

export type LifeRecordGetLifeActivityByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof lifeRecordGetLifeActivityByStudent>>
>;
export type LifeRecordGetLifeActivityByStudentQueryError = ErrorType<unknown>;

export const useLifeRecordGetLifeActivityByStudent = <
  TData = Awaited<ReturnType<typeof lifeRecordGetLifeActivityByStudent>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  params: LifeRecordGetLifeActivityByStudentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof lifeRecordGetLifeActivityByStudent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLifeRecordGetLifeActivityByStudentQueryKey(studentId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lifeRecordGetLifeActivityByStudent>>> = ({ signal }) =>
    lifeRecordGetLifeActivityByStudent(studentId, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof lifeRecordGetLifeActivityByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!studentId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록부 총정리 다운로드
 */
export const lifeRecordDownloadRecordSummary = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/life_record/download/summary/${groupId}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getLifeRecordDownloadRecordSummaryQueryKey = (groupId: number) => [
  `/api/life_record/download/summary/${groupId}`,
];

export type LifeRecordDownloadRecordSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof lifeRecordDownloadRecordSummary>>
>;
export type LifeRecordDownloadRecordSummaryQueryError = ErrorType<unknown>;

export const useLifeRecordDownloadRecordSummary = <
  TData = Awaited<ReturnType<typeof lifeRecordDownloadRecordSummary>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof lifeRecordDownloadRecordSummary>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLifeRecordDownloadRecordSummaryQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lifeRecordDownloadRecordSummary>>> = ({ signal }) =>
    lifeRecordDownloadRecordSummary(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof lifeRecordDownloadRecordSummary>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록부 활동내역 다운로드
 */
export const lifeRecordDownloadRecordActivity = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/life_record/download/activity/${groupId}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getLifeRecordDownloadRecordActivityQueryKey = (groupId: number) => [
  `/api/life_record/download/activity/${groupId}`,
];

export type LifeRecordDownloadRecordActivityQueryResult = NonNullable<
  Awaited<ReturnType<typeof lifeRecordDownloadRecordActivity>>
>;
export type LifeRecordDownloadRecordActivityQueryError = ErrorType<unknown>;

export const useLifeRecordDownloadRecordActivity = <
  TData = Awaited<ReturnType<typeof lifeRecordDownloadRecordActivity>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof lifeRecordDownloadRecordActivity>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLifeRecordDownloadRecordActivityQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lifeRecordDownloadRecordActivity>>> = ({ signal }) =>
    lifeRecordDownloadRecordActivity(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof lifeRecordDownloadRecordActivity>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @deprecated
 * @summary http://localhost:8000/api/docs#/%ED%95%99%EC%83%9D%20%EA%B7%B8%EB%A3%B9/StudentGroupsController_findByGroupId
 */
export const lifeRecordGetLifeActivity = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<void>({ url: `/api/life_record/${groupId}`, method: 'get', signal }, options);
};

export const getLifeRecordGetLifeActivityQueryKey = (groupId: number) => [`/api/life_record/${groupId}`];

export type LifeRecordGetLifeActivityQueryResult = NonNullable<Awaited<ReturnType<typeof lifeRecordGetLifeActivity>>>;
export type LifeRecordGetLifeActivityQueryError = ErrorType<unknown>;

export const useLifeRecordGetLifeActivity = <
  TData = Awaited<ReturnType<typeof lifeRecordGetLifeActivity>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof lifeRecordGetLifeActivity>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLifeRecordGetLifeActivityQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lifeRecordGetLifeActivity>>> = ({ signal }) =>
    lifeRecordGetLifeActivity(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof lifeRecordGetLifeActivity>>, TError, TData>(queryKey, queryFn, {
    enabled: !!groupId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 목록 조회
 */
export const newsLettersFindAll = (
  params?: NewsLettersFindAllParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedNewsletterDto>({ url: `/api/newsletters`, method: 'get', params, signal }, options);
};

export const getNewsLettersFindAllQueryKey = (params?: NewsLettersFindAllParams) => [
  `/api/newsletters`,
  ...(params ? [params] : []),
];

export type NewsLettersFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof newsLettersFindAll>>>;
export type NewsLettersFindAllQueryError = ErrorType<unknown>;

export const useNewsLettersFindAll = <
  TData = Awaited<ReturnType<typeof newsLettersFindAll>>,
  TError = ErrorType<unknown>,
>(
  params?: NewsLettersFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof newsLettersFindAll>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersFindAll>>> = ({ signal }) =>
    newsLettersFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 추가
 */
export const newsLettersCreate = (
  requestCreateNewsletterDto: RequestCreateNewsletterDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Newsletter>(
    {
      url: `/api/newsletters`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateNewsletterDto,
    },
    options,
  );
};

export type NewsLettersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof newsLettersCreate>>>;
export type NewsLettersCreateMutationBody = RequestCreateNewsletterDto;
export type NewsLettersCreateMutationError = ErrorType<void>;

export const useNewsLettersCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newsLettersCreate>>,
    TError,
    { data: RequestCreateNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newsLettersCreate>>,
    { data: RequestCreateNewsletterDto }
  > = (props) => {
    const { data } = props ?? {};

    return newsLettersCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof newsLettersCreate>>,
    TError,
    { data: RequestCreateNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문 알림 전송, 미확인 추가
 */
export const newsLettersPostCreateJob = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<boolean>({ url: `/api/newsletters/post-create-job/${id}`, method: 'get', signal }, options);
};

export const getNewsLettersPostCreateJobQueryKey = (id: number) => [`/api/newsletters/post-create-job/${id}`];

export type NewsLettersPostCreateJobQueryResult = NonNullable<Awaited<ReturnType<typeof newsLettersPostCreateJob>>>;
export type NewsLettersPostCreateJobQueryError = ErrorType<void>;

export const useNewsLettersPostCreateJob = <
  TData = Awaited<ReturnType<typeof newsLettersPostCreateJob>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof newsLettersPostCreateJob>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersPostCreateJobQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersPostCreateJob>>> = ({ signal }) =>
    newsLettersPostCreateJob(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersPostCreateJob>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 제목 조회 (메시지용)
 */
export const newsLettersFindTitleByIds = (
  params?: NewsLettersFindTitleByIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Newsletter[]>({ url: `/api/newsletters/title`, method: 'get', params, signal }, options);
};

export const getNewsLettersFindTitleByIdsQueryKey = (params?: NewsLettersFindTitleByIdsParams) => [
  `/api/newsletters/title`,
  ...(params ? [params] : []),
];

export type NewsLettersFindTitleByIdsQueryResult = NonNullable<Awaited<ReturnType<typeof newsLettersFindTitleByIds>>>;
export type NewsLettersFindTitleByIdsQueryError = ErrorType<void>;

export const useNewsLettersFindTitleByIds = <
  TData = Awaited<ReturnType<typeof newsLettersFindTitleByIds>>,
  TError = ErrorType<void>,
>(
  params?: NewsLettersFindTitleByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof newsLettersFindTitleByIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersFindTitleByIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersFindTitleByIds>>> = ({ signal }) =>
    newsLettersFindTitleByIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersFindTitleByIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 단건 조회
 */
export const newsLettersFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseNewsletterDetailDto>({ url: `/api/newsletters/${id}`, method: 'get', signal }, options);
};

export const getNewsLettersFindOneQueryKey = (id: number) => [`/api/newsletters/${id}`];

export type NewsLettersFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof newsLettersFindOne>>>;
export type NewsLettersFindOneQueryError = ErrorType<unknown>;

export const useNewsLettersFindOne = <
  TData = Awaited<ReturnType<typeof newsLettersFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof newsLettersFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersFindOne>>> = ({ signal }) =>
    newsLettersFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 발행안된 설문일때만 수정가능
 * @summary 가정통신문 수정
 */
export const newsLettersUpdate = (
  id: number,
  requestUpdateNewsletterDto: RequestUpdateNewsletterDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Newsletter>(
    {
      url: `/api/newsletters/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateNewsletterDto,
    },
    options,
  );
};

export type NewsLettersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof newsLettersUpdate>>>;
export type NewsLettersUpdateMutationBody = RequestUpdateNewsletterDto;
export type NewsLettersUpdateMutationError = ErrorType<void>;

export const useNewsLettersUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newsLettersUpdate>>,
    TError,
    { id: number; data: RequestUpdateNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newsLettersUpdate>>,
    { id: number; data: RequestUpdateNewsletterDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return newsLettersUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof newsLettersUpdate>>,
    TError,
    { id: number; data: RequestUpdateNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문 삭제
 */
export const newsLettersDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/newsletters/${id}`, method: 'delete' }, options);
};

export type NewsLettersDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof newsLettersDelete>>>;

export type NewsLettersDeleteMutationError = ErrorType<unknown>;

export const useNewsLettersDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof newsLettersDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof newsLettersDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return newsLettersDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof newsLettersDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 가정통신문 마감기한 수정
 */
export const newsLettersUpdateEndAt = (
  id: number,
  newsLettersUpdateEndAtBody: NewsLettersUpdateEndAtBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Newsletter>(
    {
      url: `/api/newsletters/${id}/end_at`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: newsLettersUpdateEndAtBody,
    },
    options,
  );
};

export type NewsLettersUpdateEndAtMutationResult = NonNullable<Awaited<ReturnType<typeof newsLettersUpdateEndAt>>>;
export type NewsLettersUpdateEndAtMutationBody = NewsLettersUpdateEndAtBody;
export type NewsLettersUpdateEndAtMutationError = ErrorType<void>;

export const useNewsLettersUpdateEndAt = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newsLettersUpdateEndAt>>,
    TError,
    { id: number; data: NewsLettersUpdateEndAtBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newsLettersUpdateEndAt>>,
    { id: number; data: NewsLettersUpdateEndAtBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return newsLettersUpdateEndAt(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof newsLettersUpdateEndAt>>,
    TError,
    { id: number; data: NewsLettersUpdateEndAtBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문 발행
 */
export const newsLettersPublish = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<void>({ url: `/api/newsletters/publish/${id}`, method: 'get', signal }, options);
};

export const getNewsLettersPublishQueryKey = (id: number) => [`/api/newsletters/publish/${id}`];

export type NewsLettersPublishQueryResult = NonNullable<Awaited<ReturnType<typeof newsLettersPublish>>>;
export type NewsLettersPublishQueryError = ErrorType<unknown>;

export const useNewsLettersPublish = <
  TData = Awaited<ReturnType<typeof newsLettersPublish>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof newsLettersPublish>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersPublishQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersPublish>>> = ({ signal }) =>
    newsLettersPublish(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersPublish>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 푸시 재수행
 */
export const newsLettersRepublish = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/newsletters/publish/${id}`, method: 'post' }, options);
};

export type NewsLettersRepublishMutationResult = NonNullable<Awaited<ReturnType<typeof newsLettersRepublish>>>;

export type NewsLettersRepublishMutationError = ErrorType<unknown>;

export const useNewsLettersRepublish = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof newsLettersRepublish>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof newsLettersRepublish>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return newsLettersRepublish(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof newsLettersRepublish>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 가정통신문 미확인자 명단 다운로드
 */
export const newsLettersDownloadUnreadStudentList = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/newsletters/unread-student-download/${id}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getNewsLettersDownloadUnreadStudentListQueryKey = (id: number) => [
  `/api/newsletters/unread-student-download/${id}`,
];

export type NewsLettersDownloadUnreadStudentListQueryResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersDownloadUnreadStudentList>>
>;
export type NewsLettersDownloadUnreadStudentListQueryError = ErrorType<unknown>;

export const useNewsLettersDownloadUnreadStudentList = <
  TData = Awaited<ReturnType<typeof newsLettersDownloadUnreadStudentList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof newsLettersDownloadUnreadStudentList>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersDownloadUnreadStudentListQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersDownloadUnreadStudentList>>> = ({ signal }) =>
    newsLettersDownloadUnreadStudentList(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersDownloadUnreadStudentList>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 다운로드
 */
export const newsLettersDownloadSubmitters = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/newsletters/download/${id}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getNewsLettersDownloadSubmittersQueryKey = (id: number) => [`/api/newsletters/download/${id}`];

export type NewsLettersDownloadSubmittersQueryResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersDownloadSubmitters>>
>;
export type NewsLettersDownloadSubmittersQueryError = ErrorType<unknown>;

export const useNewsLettersDownloadSubmitters = <
  TData = Awaited<ReturnType<typeof newsLettersDownloadSubmitters>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof newsLettersDownloadSubmitters>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersDownloadSubmittersQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersDownloadSubmitters>>> = ({ signal }) =>
    newsLettersDownloadSubmitters(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersDownloadSubmitters>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 목록 조회
 */
export const noticesFindAll = (
  params?: NoticesFindAllParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedNoticeDto>({ url: `/api/notices`, method: 'get', params, signal }, options);
};

export const getNoticesFindAllQueryKey = (params?: NoticesFindAllParams) => [
  `/api/notices`,
  ...(params ? [params] : []),
];

export type NoticesFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof noticesFindAll>>>;
export type NoticesFindAllQueryError = ErrorType<unknown>;

export const useNoticesFindAll = <TData = Awaited<ReturnType<typeof noticesFindAll>>, TError = ErrorType<unknown>>(
  params?: NoticesFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof noticesFindAll>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNoticesFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof noticesFindAll>>> = ({ signal }) =>
    noticesFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof noticesFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 추가
 */
export const noticesCreate = (
  requestCreateNoticeDto: RequestCreateNoticeDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Notice>(
    {
      url: `/api/notices`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateNoticeDto,
    },
    options,
  );
};

export type NoticesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof noticesCreate>>>;
export type NoticesCreateMutationBody = RequestCreateNoticeDto;
export type NoticesCreateMutationError = ErrorType<void>;

export const useNoticesCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof noticesCreate>>,
    TError,
    { data: RequestCreateNoticeDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof noticesCreate>>, { data: RequestCreateNoticeDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return noticesCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof noticesCreate>>, TError, { data: RequestCreateNoticeDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 공지사항 알림 전송, 미확인 추가
 */
export const noticesPostCreateJob = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<boolean>({ url: `/api/notices/post-create-job/${id}`, method: 'get', signal }, options);
};

export const getNoticesPostCreateJobQueryKey = (id: number) => [`/api/notices/post-create-job/${id}`];

export type NoticesPostCreateJobQueryResult = NonNullable<Awaited<ReturnType<typeof noticesPostCreateJob>>>;
export type NoticesPostCreateJobQueryError = ErrorType<void>;

export const useNoticesPostCreateJob = <
  TData = Awaited<ReturnType<typeof noticesPostCreateJob>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof noticesPostCreateJob>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNoticesPostCreateJobQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof noticesPostCreateJob>>> = ({ signal }) =>
    noticesPostCreateJob(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof noticesPostCreateJob>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 제목 조회 (메시지용)
 */
export const noticesFindTitleByIds = (
  params?: NoticesFindTitleByIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Notice[]>({ url: `/api/notices/title`, method: 'get', params, signal }, options);
};

export const getNoticesFindTitleByIdsQueryKey = (params?: NoticesFindTitleByIdsParams) => [
  `/api/notices/title`,
  ...(params ? [params] : []),
];

export type NoticesFindTitleByIdsQueryResult = NonNullable<Awaited<ReturnType<typeof noticesFindTitleByIds>>>;
export type NoticesFindTitleByIdsQueryError = ErrorType<void>;

export const useNoticesFindTitleByIds = <
  TData = Awaited<ReturnType<typeof noticesFindTitleByIds>>,
  TError = ErrorType<void>,
>(
  params?: NoticesFindTitleByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof noticesFindTitleByIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNoticesFindTitleByIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof noticesFindTitleByIds>>> = ({ signal }) =>
    noticesFindTitleByIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof noticesFindTitleByIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 단건 조회
 */
export const noticesFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Notice>({ url: `/api/notices/${id}`, method: 'get', signal }, options);
};

export const getNoticesFindOneQueryKey = (id: number) => [`/api/notices/${id}`];

export type NoticesFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof noticesFindOne>>>;
export type NoticesFindOneQueryError = ErrorType<unknown>;

export const useNoticesFindOne = <TData = Awaited<ReturnType<typeof noticesFindOne>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof noticesFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNoticesFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof noticesFindOne>>> = ({ signal }) =>
    noticesFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof noticesFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 수정
 */
export const noticesUpdate = (
  id: number,
  requestCreateNoticeDto: RequestCreateNoticeDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Notice>(
    {
      url: `/api/notices/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateNoticeDto,
    },
    options,
  );
};

export type NoticesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof noticesUpdate>>>;
export type NoticesUpdateMutationBody = RequestCreateNoticeDto;
export type NoticesUpdateMutationError = ErrorType<void>;

export const useNoticesUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof noticesUpdate>>,
    TError,
    { id: number; data: RequestCreateNoticeDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof noticesUpdate>>,
    { id: number; data: RequestCreateNoticeDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return noticesUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof noticesUpdate>>,
    TError,
    { id: number; data: RequestCreateNoticeDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 공지사항 삭제
 */
export const noticesDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/notices/${id}`, method: 'delete' }, options);
};

export type NoticesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof noticesDelete>>>;

export type NoticesDeleteMutationError = ErrorType<unknown>;

export const useNoticesDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof noticesDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof noticesDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return noticesDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof noticesDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 내 알림 설정 조회
 */
export const notificationSettingsGetMine = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<NotificationSetting>({ url: `/api/notification-settings/mine`, method: 'get', signal }, options);
};

export const getNotificationSettingsGetMineQueryKey = () => [`/api/notification-settings/mine`];

export type NotificationSettingsGetMineQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationSettingsGetMine>>
>;
export type NotificationSettingsGetMineQueryError = ErrorType<unknown>;

export const useNotificationSettingsGetMine = <
  TData = Awaited<ReturnType<typeof notificationSettingsGetMine>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof notificationSettingsGetMine>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationSettingsGetMineQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationSettingsGetMine>>> = ({ signal }) =>
    notificationSettingsGetMine(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof notificationSettingsGetMine>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 알림 여부 조회
 */
export const notificationSettingsNotificationCheck = (
  requestNotificationCheckDto: RequestNotificationCheckDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseNotificationCheckDto>(
    {
      url: `/api/notification-settings/notification-check`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestNotificationCheckDto,
    },
    options,
  );
};

export type NotificationSettingsNotificationCheckMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationSettingsNotificationCheck>>
>;
export type NotificationSettingsNotificationCheckMutationBody = RequestNotificationCheckDto;
export type NotificationSettingsNotificationCheckMutationError = ErrorType<void>;

export const useNotificationSettingsNotificationCheck = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationSettingsNotificationCheck>>,
    TError,
    { data: RequestNotificationCheckDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationSettingsNotificationCheck>>,
    { data: RequestNotificationCheckDto }
  > = (props) => {
    const { data } = props ?? {};

    return notificationSettingsNotificationCheck(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof notificationSettingsNotificationCheck>>,
    TError,
    { data: RequestNotificationCheckDto },
    TContext
  >(mutationFn, mutationOptions);
};

export const notificationSettingsUpdate = (
  id: number,
  requestUpdateNotificationSettingDto: RequestUpdateNotificationSettingDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/notification-settings/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateNotificationSettingDto,
    },
    options,
  );
};

export type NotificationSettingsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationSettingsUpdate>>
>;
export type NotificationSettingsUpdateMutationBody = RequestUpdateNotificationSettingDto;
export type NotificationSettingsUpdateMutationError = ErrorType<unknown>;

export const useNotificationSettingsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationSettingsUpdate>>,
    TError,
    { id: number; data: RequestUpdateNotificationSettingDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationSettingsUpdate>>,
    { id: number; data: RequestUpdateNotificationSettingDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return notificationSettingsUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof notificationSettingsUpdate>>,
    TError,
    { id: number; data: RequestUpdateNotificationSettingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 최근 읽지 않은 알림
 */
export const notificationLogFindRecent = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<NotificationLog>({ url: `/api/notification-logs/recent`, method: 'get', signal }, options);
};

export const getNotificationLogFindRecentQueryKey = () => [`/api/notification-logs/recent`];

export type NotificationLogFindRecentQueryResult = NonNullable<Awaited<ReturnType<typeof notificationLogFindRecent>>>;
export type NotificationLogFindRecentQueryError = ErrorType<unknown>;

export const useNotificationLogFindRecent = <
  TData = Awaited<ReturnType<typeof notificationLogFindRecent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof notificationLogFindRecent>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationLogFindRecentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationLogFindRecent>>> = ({ signal }) =>
    notificationLogFindRecent(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof notificationLogFindRecent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 알림 로그 목록
 */
export const notificationLogFindAll = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<NotificationLog[]>({ url: `/api/notification-logs`, method: 'get', signal }, options);
};

export const getNotificationLogFindAllQueryKey = () => [`/api/notification-logs`];

export type NotificationLogFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof notificationLogFindAll>>>;
export type NotificationLogFindAllQueryError = ErrorType<unknown>;

export const useNotificationLogFindAll = <
  TData = Awaited<ReturnType<typeof notificationLogFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof notificationLogFindAll>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationLogFindAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationLogFindAll>>> = ({ signal }) =>
    notificationLogFindAll(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof notificationLogFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 확인증 목록(선생님용), 레거시 거의 그대로 유지
 */
export const outingsFindAllByTeacher = (
  params: OutingsFindAllByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedOutingDto>({ url: `/api/outings/teacher`, method: 'get', params, signal }, options);
};

export const getOutingsFindAllByTeacherQueryKey = (params: OutingsFindAllByTeacherParams) => [
  `/api/outings/teacher`,
  ...(params ? [params] : []),
];

export type OutingsFindAllByTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof outingsFindAllByTeacher>>>;
export type OutingsFindAllByTeacherQueryError = ErrorType<unknown>;

export const useOutingsFindAllByTeacher = <
  TData = Awaited<ReturnType<typeof outingsFindAllByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: OutingsFindAllByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof outingsFindAllByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsFindAllByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsFindAllByTeacher>>> = ({ signal }) =>
    outingsFindAllByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof outingsFindAllByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결서류관리 확인증 목록(선생님용)
 */
export const outingsFindHistory = (
  params: OutingsFindHistoryParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedOutingDto>({ url: `/api/outings/history`, method: 'get', params, signal }, options);
};

export const getOutingsFindHistoryQueryKey = (params: OutingsFindHistoryParams) => [
  `/api/outings/history`,
  ...(params ? [params] : []),
];

export type OutingsFindHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof outingsFindHistory>>>;
export type OutingsFindHistoryQueryError = ErrorType<unknown>;

export const useOutingsFindHistory = <
  TData = Awaited<ReturnType<typeof outingsFindHistory>>,
  TError = ErrorType<unknown>,
>(
  params: OutingsFindHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof outingsFindHistory>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsFindHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsFindHistory>>> = ({ signal }) =>
    outingsFindHistory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof outingsFindHistory>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 확인증현황 엑셀 다운로드(선생님용)
 */
export const outingsDownloadOutings = (
  params: OutingsDownloadOutingsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>({ url: `/api/outings/download`, method: 'get', params, responseType: 'blob', signal }, options);
};

export const getOutingsDownloadOutingsQueryKey = (params: OutingsDownloadOutingsParams) => [
  `/api/outings/download`,
  ...(params ? [params] : []),
];

export type OutingsDownloadOutingsQueryResult = NonNullable<Awaited<ReturnType<typeof outingsDownloadOutings>>>;
export type OutingsDownloadOutingsQueryError = ErrorType<unknown>;

export const useOutingsDownloadOutings = <
  TData = Awaited<ReturnType<typeof outingsDownloadOutings>>,
  TError = ErrorType<unknown>,
>(
  params: OutingsDownloadOutingsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof outingsDownloadOutings>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsDownloadOutingsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsDownloadOutings>>> = ({ signal }) =>
    outingsDownloadOutings(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof outingsDownloadOutings>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 확인증 목록(학생용)
 */
export const outingsFindAllByStudent = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<Outing[]>({ url: `/api/outings/list-by-student`, method: 'get', signal }, options);
};

export const getOutingsFindAllByStudentQueryKey = () => [`/api/outings/list-by-student`];

export type OutingsFindAllByStudentQueryResult = NonNullable<Awaited<ReturnType<typeof outingsFindAllByStudent>>>;
export type OutingsFindAllByStudentQueryError = ErrorType<unknown>;

export const useOutingsFindAllByStudent = <
  TData = Awaited<ReturnType<typeof outingsFindAllByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof outingsFindAllByStudent>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsFindAllByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsFindAllByStudent>>> = ({ signal }) =>
    outingsFindAllByStudent(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof outingsFindAllByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 확인증 단건 조회
 */
export const outingsFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseCreateOutingDto>({ url: `/api/outings/${id}`, method: 'get', signal }, options);
};

export const getOutingsFindOneQueryKey = (id: number) => [`/api/outings/${id}`];

export type OutingsFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof outingsFindOne>>>;
export type OutingsFindOneQueryError = ErrorType<void>;

export const useOutingsFindOne = <TData = Awaited<ReturnType<typeof outingsFindOne>>, TError = ErrorType<void>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof outingsFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsFindOne>>> = ({ signal }) =>
    outingsFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof outingsFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 확인증 수정
 */
export const outingsUpdate = (
  id: number,
  requestCreateOutingDto: RequestCreateOutingDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Outing>(
    {
      url: `/api/outings/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateOutingDto,
    },
    options,
  );
};

export type OutingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof outingsUpdate>>>;
export type OutingsUpdateMutationBody = RequestCreateOutingDto;
export type OutingsUpdateMutationError = ErrorType<void>;

export const useOutingsUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsUpdate>>,
    TError,
    { id: number; data: RequestCreateOutingDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsUpdate>>,
    { id: number; data: RequestCreateOutingDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsUpdate>>,
    TError,
    { id: number; data: RequestCreateOutingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 확인증 삭제
 */
export const outingsDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/outings/${id}`, method: 'delete' }, options);
};

export type OutingsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof outingsDelete>>>;

export type OutingsDeleteMutationError = ErrorType<unknown>;

export const useOutingsDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof outingsDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof outingsDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return outingsDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof outingsDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 확인증 추가
 */
export const outingsCreate = (
  requestCreateOutingDto: RequestCreateOutingDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseCreateOutingDto>(
    {
      url: `/api/outings`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateOutingDto,
    },
    options,
  );
};

export type OutingsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof outingsCreate>>>;
export type OutingsCreateMutationBody = RequestCreateOutingDto;
export type OutingsCreateMutationError = ErrorType<void>;

export const useOutingsCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsCreate>>,
    TError,
    { data: RequestCreateOutingDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof outingsCreate>>, { data: RequestCreateOutingDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return outingsCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof outingsCreate>>, TError, { data: RequestCreateOutingDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 확인증 수정(선생님용)
 */
export const outingsUpdateByTeacher = (
  id: number,
  requestUpdateOutingDto: RequestUpdateOutingDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Outing>(
    {
      url: `/api/outings/update-by-teacher/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateOutingDto,
    },
    options,
  );
};

export type OutingsUpdateByTeacherMutationResult = NonNullable<Awaited<ReturnType<typeof outingsUpdateByTeacher>>>;
export type OutingsUpdateByTeacherMutationBody = RequestUpdateOutingDto;
export type OutingsUpdateByTeacherMutationError = ErrorType<void>;

export const useOutingsUpdateByTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateOutingDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsUpdateByTeacher>>,
    { id: number; data: RequestUpdateOutingDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsUpdateByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateOutingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 확인증 삭제요청
 */
export const outingsRequestDelete = (
  id: number,
  outingsRequestDeleteBody: OutingsRequestDeleteBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Outing>(
    {
      url: `/api/outings/request-delete/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: outingsRequestDeleteBody,
    },
    options,
  );
};

export type OutingsRequestDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof outingsRequestDelete>>>;
export type OutingsRequestDeleteMutationBody = OutingsRequestDeleteBody;
export type OutingsRequestDeleteMutationError = ErrorType<void>;

export const useOutingsRequestDelete = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsRequestDelete>>,
    TError,
    { id: number; data: OutingsRequestDeleteBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsRequestDelete>>,
    { id: number; data: OutingsRequestDeleteBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsRequestDelete(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsRequestDelete>>,
    TError,
    { id: number; data: OutingsRequestDeleteBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 확인증 반려
 */
export const outingsDeny = (
  id: number,
  outingsDenyBody: OutingsDenyBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/outings/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: outingsDenyBody,
    },
    options,
  );
};

export type OutingsDenyMutationResult = NonNullable<Awaited<ReturnType<typeof outingsDeny>>>;
export type OutingsDenyMutationBody = OutingsDenyBody;
export type OutingsDenyMutationError = ErrorType<unknown>;

export const useOutingsDeny = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsDeny>>,
    TError,
    { id: number; data: OutingsDenyBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof outingsDeny>>, { id: number; data: OutingsDenyBody }> = (
    props,
  ) => {
    const { id, data } = props ?? {};

    return outingsDeny(id, data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof outingsDeny>>, TError, { id: number; data: OutingsDenyBody }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 확인증 승인
 */
export const outingsApprove = (
  id: number,
  outingsApproveBody: OutingsApproveBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/outings/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: outingsApproveBody,
    },
    options,
  );
};

export type OutingsApproveMutationResult = NonNullable<Awaited<ReturnType<typeof outingsApprove>>>;
export type OutingsApproveMutationBody = OutingsApproveBody;
export type OutingsApproveMutationError = ErrorType<unknown>;

export const useOutingsApprove = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsApprove>>,
    TError,
    { id: number; data: OutingsApproveBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsApprove>>,
    { id: number; data: OutingsApproveBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsApprove(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsApprove>>,
    TError,
    { id: number; data: OutingsApproveBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 그룹 목록(+유저정보) 조회(선생님용)
 */
export const studentGroupsFindByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentGroup[]>({ url: `/api/student_groups/by-group/${groupId}`, method: 'get', signal }, options);
};

export const getStudentGroupsFindByGroupIdQueryKey = (groupId: number) => [`/api/student_groups/by-group/${groupId}`];

export type StudentGroupsFindByGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof studentGroupsFindByGroupId>>>;
export type StudentGroupsFindByGroupIdQueryError = ErrorType<unknown>;

export const useStudentGroupsFindByGroupId = <
  TData = Awaited<ReturnType<typeof studentGroupsFindByGroupId>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentGroupsFindByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentGroupsFindByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentGroupsFindByGroupId>>> = ({ signal }) =>
    studentGroupsFindByGroupId(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentGroupsFindByGroupId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!groupId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 내 그룹 목록 조회(학생용)
 */
export const studentGroupsFindByStudent = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<StudentGroup[]>({ url: `/api/student_groups/student`, method: 'get', signal }, options);
};

export const getStudentGroupsFindByStudentQueryKey = () => [`/api/student_groups/student`];

export type StudentGroupsFindByStudentQueryResult = NonNullable<Awaited<ReturnType<typeof studentGroupsFindByStudent>>>;
export type StudentGroupsFindByStudentQueryError = ErrorType<unknown>;

export const useStudentGroupsFindByStudent = <
  TData = Awaited<ReturnType<typeof studentGroupsFindByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof studentGroupsFindByStudent>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentGroupsFindByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentGroupsFindByStudent>>> = ({ signal }) =>
    studentGroupsFindByStudent(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentGroupsFindByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 여러 그룹ID로 학생 목록 조회(선생님용)
 */
export const studentGroupsFindByGroupIds = (
  params: StudentGroupsFindByGroupIdsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentGroup[]>({ url: `/api/student_groups/by-groups`, method: 'get', params, signal }, options);
};

export const getStudentGroupsFindByGroupIdsQueryKey = (params: StudentGroupsFindByGroupIdsParams) => [
  `/api/student_groups/by-groups`,
  ...(params ? [params] : []),
];

export type StudentGroupsFindByGroupIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsFindByGroupIds>>
>;
export type StudentGroupsFindByGroupIdsQueryError = ErrorType<unknown>;

export const useStudentGroupsFindByGroupIds = <
  TData = Awaited<ReturnType<typeof studentGroupsFindByGroupIds>>,
  TError = ErrorType<unknown>,
>(
  params: StudentGroupsFindByGroupIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentGroupsFindByGroupIds>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentGroupsFindByGroupIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentGroupsFindByGroupIds>>> = ({ signal }) =>
    studentGroupsFindByGroupIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentGroupsFindByGroupIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 그룹 목록(+유저정보,학년반) 조회(선생님용)
 */
export const studentGroupsFindWithKlassByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseStudentGroupWithKlassDto[]>(
    { url: `/api/student_groups/by-group-with-klass/${groupId}`, method: 'get', signal },
    options,
  );
};

export const getStudentGroupsFindWithKlassByGroupIdQueryKey = (groupId: number) => [
  `/api/student_groups/by-group-with-klass/${groupId}`,
];

export type StudentGroupsFindWithKlassByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsFindWithKlassByGroupId>>
>;
export type StudentGroupsFindWithKlassByGroupIdQueryError = ErrorType<unknown>;

export const useStudentGroupsFindWithKlassByGroupId = <
  TData = Awaited<ReturnType<typeof studentGroupsFindWithKlassByGroupId>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentGroupsFindWithKlassByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentGroupsFindWithKlassByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentGroupsFindWithKlassByGroupId>>> = ({ signal }) =>
    studentGroupsFindWithKlassByGroupId(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentGroupsFindWithKlassByGroupId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 명렬표 다운로드(선생님용)
 */
export const studentGroupsDownloadStudentListByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/student_groups/download/${groupId}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getStudentGroupsDownloadStudentListByGroupIdQueryKey = (groupId: number) => [
  `/api/student_groups/download/${groupId}`,
];

export type StudentGroupsDownloadStudentListByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsDownloadStudentListByGroupId>>
>;
export type StudentGroupsDownloadStudentListByGroupIdQueryError = ErrorType<unknown>;

export const useStudentGroupsDownloadStudentListByGroupId = <
  TData = Awaited<ReturnType<typeof studentGroupsDownloadStudentListByGroupId>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentGroupsDownloadStudentListByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentGroupsDownloadStudentListByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentGroupsDownloadStudentListByGroupId>>> = ({ signal }) =>
    studentGroupsDownloadStudentListByGroupId(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentGroupsDownloadStudentListByGroupId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 klass history(학생 마이페이지에서 사용)
 */
export const studentGroupsFindKlassHistoryByStudent = (
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentGroup[]>(
    { url: `/api/student_groups/klass-history-by-student`, method: 'get', signal },
    options,
  );
};

export const getStudentGroupsFindKlassHistoryByStudentQueryKey = () => [`/api/student_groups/klass-history-by-student`];

export type StudentGroupsFindKlassHistoryByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsFindKlassHistoryByStudent>>
>;
export type StudentGroupsFindKlassHistoryByStudentQueryError = ErrorType<unknown>;

export const useStudentGroupsFindKlassHistoryByStudent = <
  TData = Awaited<ReturnType<typeof studentGroupsFindKlassHistoryByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof studentGroupsFindKlassHistoryByStudent>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentGroupsFindKlassHistoryByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentGroupsFindKlassHistoryByStudent>>> = ({ signal }) =>
    studentGroupsFindKlassHistoryByStudent(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentGroupsFindKlassHistoryByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 학년 그룹 목록 조회
 */
export const studentGroupsFindAllByKlasses = (
  params: StudentGroupsFindAllByKlassesParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentGroup[]>({ url: `/api/student_groups/by-klasses`, method: 'get', params, signal }, options);
};

export const getStudentGroupsFindAllByKlassesQueryKey = (params: StudentGroupsFindAllByKlassesParams) => [
  `/api/student_groups/by-klasses`,
  ...(params ? [params] : []),
];

export type StudentGroupsFindAllByKlassesQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsFindAllByKlasses>>
>;
export type StudentGroupsFindAllByKlassesQueryError = ErrorType<unknown>;

export const useStudentGroupsFindAllByKlasses = <
  TData = Awaited<ReturnType<typeof studentGroupsFindAllByKlasses>>,
  TError = ErrorType<unknown>,
>(
  params: StudentGroupsFindAllByKlassesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentGroupsFindAllByKlasses>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentGroupsFindAllByKlassesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentGroupsFindAllByKlasses>>> = ({ signal }) =>
    studentGroupsFindAllByKlasses(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentGroupsFindAllByKlasses>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 캘린더 목록
 */
export const schedulesFindAll = (
  params: SchedulesFindAllParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Schedule[]>({ url: `/api/schedules`, method: 'get', params, signal }, options);
};

export const getSchedulesFindAllQueryKey = (params: SchedulesFindAllParams) => [
  `/api/schedules`,
  ...(params ? [params] : []),
];

export type SchedulesFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof schedulesFindAll>>>;
export type SchedulesFindAllQueryError = ErrorType<unknown>;

export const useSchedulesFindAll = <TData = Awaited<ReturnType<typeof schedulesFindAll>>, TError = ErrorType<unknown>>(
  params: SchedulesFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schedulesFindAll>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchedulesFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulesFindAll>>> = ({ signal }) =>
    schedulesFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schedulesFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 캘린더 추가
 */
export const schedulesCreate = (
  requestCreateScheduleDto: RequestCreateScheduleDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Schedule>(
    {
      url: `/api/schedules`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateScheduleDto,
    },
    options,
  );
};

export type SchedulesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulesCreate>>>;
export type SchedulesCreateMutationBody = RequestCreateScheduleDto;
export type SchedulesCreateMutationError = ErrorType<void>;

export const useSchedulesCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof schedulesCreate>>,
    TError,
    { data: RequestCreateScheduleDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof schedulesCreate>>,
    { data: RequestCreateScheduleDto }
  > = (props) => {
    const { data } = props ?? {};

    return schedulesCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof schedulesCreate>>, TError, { data: RequestCreateScheduleDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 체험학습 신청불가 캘린더 목록(학생용)
 */
export const schedulesFindRejectSchedule = (
  params: SchedulesFindRejectScheduleParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Schedule[]>({ url: `/api/schedules/reject`, method: 'get', params, signal }, options);
};

export const getSchedulesFindRejectScheduleQueryKey = (params: SchedulesFindRejectScheduleParams) => [
  `/api/schedules/reject`,
  ...(params ? [params] : []),
];

export type SchedulesFindRejectScheduleQueryResult = NonNullable<
  Awaited<ReturnType<typeof schedulesFindRejectSchedule>>
>;
export type SchedulesFindRejectScheduleQueryError = ErrorType<unknown>;

export const useSchedulesFindRejectSchedule = <
  TData = Awaited<ReturnType<typeof schedulesFindRejectSchedule>>,
  TError = ErrorType<unknown>,
>(
  params: SchedulesFindRejectScheduleParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schedulesFindRejectSchedule>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchedulesFindRejectScheduleQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulesFindRejectSchedule>>> = ({ signal }) =>
    schedulesFindRejectSchedule(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schedulesFindRejectSchedule>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 캘린더 수정
 */
export const schedulesUpdate = (
  id: number,
  requestCreateScheduleDto: RequestCreateScheduleDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Schedule>(
    {
      url: `/api/schedules/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateScheduleDto,
    },
    options,
  );
};

export type SchedulesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulesUpdate>>>;
export type SchedulesUpdateMutationBody = RequestCreateScheduleDto;
export type SchedulesUpdateMutationError = ErrorType<void>;

export const useSchedulesUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof schedulesUpdate>>,
    TError,
    { id: number; data: RequestCreateScheduleDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof schedulesUpdate>>,
    { id: number; data: RequestCreateScheduleDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return schedulesUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof schedulesUpdate>>,
    TError,
    { id: number; data: RequestCreateScheduleDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 캘린더 삭제
 */
export const schedulesDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/schedules/${id}`, method: 'delete' }, options);
};

export type SchedulesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulesDelete>>>;

export type SchedulesDeleteMutationError = ErrorType<unknown>;

export const useSchedulesDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof schedulesDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulesDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return schedulesDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof schedulesDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학교 목록
 */
export const schoolsFindAll = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<School[]>({ url: `/api/schools`, method: 'get', signal }, options);
};

export const getSchoolsFindAllQueryKey = () => [`/api/schools`];

export type SchoolsFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof schoolsFindAll>>>;
export type SchoolsFindAllQueryError = ErrorType<unknown>;

export const useSchoolsFindAll = <
  TData = Awaited<ReturnType<typeof schoolsFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof schoolsFindAll>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolsFindAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolsFindAll>>> = ({ signal }) =>
    schoolsFindAll(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolsFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학교 단건 조회
 */
export const schoolsFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<School>({ url: `/api/schools/${id}`, method: 'get', signal }, options);
};

export const getSchoolsFindOneQueryKey = (id: number) => [`/api/schools/${id}`];

export type SchoolsFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof schoolsFindOne>>>;
export type SchoolsFindOneQueryError = ErrorType<unknown>;

export const useSchoolsFindOne = <TData = Awaited<ReturnType<typeof schoolsFindOne>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schoolsFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolsFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolsFindOne>>> = ({ signal }) =>
    schoolsFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolsFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록 목록 조회
 */
export const summariesFindByStudentGroupId = (
  params: SummariesFindByStudentGroupIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Summary[]>({ url: `/api/summaries`, method: 'get', params, signal }, options);
};

export const getSummariesFindByStudentGroupIdQueryKey = (params: SummariesFindByStudentGroupIdParams) => [
  `/api/summaries`,
  ...(params ? [params] : []),
];

export type SummariesFindByStudentGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof summariesFindByStudentGroupId>>
>;
export type SummariesFindByStudentGroupIdQueryError = ErrorType<unknown>;

export const useSummariesFindByStudentGroupId = <
  TData = Awaited<ReturnType<typeof summariesFindByStudentGroupId>>,
  TError = ErrorType<unknown>,
>(
  params: SummariesFindByStudentGroupIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof summariesFindByStudentGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSummariesFindByStudentGroupIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof summariesFindByStudentGroupId>>> = ({ signal }) =>
    summariesFindByStudentGroupId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof summariesFindByStudentGroupId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록 생성
 */
export const summariesCreate = (
  requestCreateSummaryDto: RequestCreateSummaryDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Summary>(
    {
      url: `/api/summaries`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateSummaryDto,
    },
    options,
  );
};

export type SummariesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof summariesCreate>>>;
export type SummariesCreateMutationBody = RequestCreateSummaryDto;
export type SummariesCreateMutationError = ErrorType<unknown>;

export const useSummariesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof summariesCreate>>,
    TError,
    { data: RequestCreateSummaryDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof summariesCreate>>, { data: RequestCreateSummaryDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return summariesCreate(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof summariesCreate>>, TError, { data: RequestCreateSummaryDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 생활기록 수정
 */
export const summariesUpdate = (
  id: number,
  requestUpdateSummaryDto: RequestUpdateSummaryDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/summaries/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateSummaryDto,
    },
    options,
  );
};

export type SummariesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof summariesUpdate>>>;
export type SummariesUpdateMutationBody = RequestUpdateSummaryDto;
export type SummariesUpdateMutationError = ErrorType<void>;

export const useSummariesUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof summariesUpdate>>,
    TError,
    { id: number; data: RequestUpdateSummaryDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof summariesUpdate>>,
    { id: number; data: RequestUpdateSummaryDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return summariesUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof summariesUpdate>>,
    TError,
    { id: number; data: RequestUpdateSummaryDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 생활기록 삭제
 */
export const summariesDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/summaries/${id}`, method: 'delete' }, options);
};

export type SummariesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof summariesDelete>>>;

export type SummariesDeleteMutationError = ErrorType<unknown>;

export const useSummariesDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof summariesDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof summariesDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return summariesDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof summariesDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학생 역할 등록/수정
 */
export const studentRolesUpsertStudentRole = (
  requestUpsertStudentRoleDto: RequestUpsertStudentRoleDto[],
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/student-role/upsert`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpsertStudentRoleDto,
    },
    options,
  );
};

export type StudentRolesUpsertStudentRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentRolesUpsertStudentRole>>
>;
export type StudentRolesUpsertStudentRoleMutationBody = RequestUpsertStudentRoleDto[];
export type StudentRolesUpsertStudentRoleMutationError = ErrorType<unknown>;

export const useStudentRolesUpsertStudentRole = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentRolesUpsertStudentRole>>,
    TError,
    { data: RequestUpsertStudentRoleDto[] },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentRolesUpsertStudentRole>>,
    { data: RequestUpsertStudentRoleDto[] }
  > = (props) => {
    const { data } = props ?? {};

    return studentRolesUpsertStudentRole(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentRolesUpsertStudentRole>>,
    TError,
    { data: RequestUpsertStudentRoleDto[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동 목록 조회(선생님용)
 */
export const studentActivityFindByUserId = (
  params: StudentActivityFindByUserIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivity[]>({ url: `/api/student-activities`, method: 'get', params, signal }, options);
};

export const getStudentActivityFindByUserIdQueryKey = (params: StudentActivityFindByUserIdParams) => [
  `/api/student-activities`,
  ...(params ? [params] : []),
];

export type StudentActivityFindByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityFindByUserId>>
>;
export type StudentActivityFindByUserIdQueryError = ErrorType<void>;

export const useStudentActivityFindByUserId = <
  TData = Awaited<ReturnType<typeof studentActivityFindByUserId>>,
  TError = ErrorType<void>,
>(
  params: StudentActivityFindByUserIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityFindByUserId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityFindByUserIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityFindByUserId>>> = ({ signal }) =>
    studentActivityFindByUserId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityFindByUserId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생활동 단건 조회(선생님용)
 */
export const studentActivityFindOne = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<StudentActivity>({ url: `/api/student-activities/${id}`, method: 'get', signal }, options);
};

export const getStudentActivityFindOneQueryKey = (id: number) => [`/api/student-activities/${id}`];

export type StudentActivityFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof studentActivityFindOne>>>;
export type StudentActivityFindOneQueryError = ErrorType<void>;

export const useStudentActivityFindOne = <
  TData = Awaited<ReturnType<typeof studentActivityFindOne>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityFindOne>>> = ({ signal }) =>
    studentActivityFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생활동 수정(없으면 추가함)
 */
export const studentActivityUpdate = (
  id: number,
  requestUpdateStudentActivityDto: RequestUpdateStudentActivityDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentActivity>(
    {
      url: `/api/student-activities/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateStudentActivityDto,
    },
    options,
  );
};

export type StudentActivityUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof studentActivityUpdate>>>;
export type StudentActivityUpdateMutationBody = RequestUpdateStudentActivityDto;
export type StudentActivityUpdateMutationError = ErrorType<void>;

export const useStudentActivityUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityUpdate>>,
    TError,
    { id: number; data: RequestUpdateStudentActivityDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityUpdate>>,
    { id: number; data: RequestUpdateStudentActivityDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentActivityUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityUpdate>>,
    TError,
    { id: number; data: RequestUpdateStudentActivityDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동 단건 조회(학생용)
 */
export const studentActivityFindOneByActivityId = (
  activityId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivity>(
    { url: `/api/student-activities/by-student/${activityId}`, method: 'get', signal },
    options,
  );
};

export const getStudentActivityFindOneByActivityIdQueryKey = (activityId: number) => [
  `/api/student-activities/by-student/${activityId}`,
];

export type StudentActivityFindOneByActivityIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityFindOneByActivityId>>
>;
export type StudentActivityFindOneByActivityIdQueryError = ErrorType<void>;

export const useStudentActivityFindOneByActivityId = <
  TData = Awaited<ReturnType<typeof studentActivityFindOneByActivityId>>,
  TError = ErrorType<void>,
>(
  activityId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityFindOneByActivityId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityFindOneByActivityIdQueryKey(activityId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityFindOneByActivityId>>> = ({ signal }) =>
    studentActivityFindOneByActivityId(activityId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityFindOneByActivityId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!activityId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 피드백 업데이트
 */
export const studentActivityUpdateFeedback = (
  id: number,
  studentActivityUpdateFeedbackBody: StudentActivityUpdateFeedbackBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/student-activities/update-feedback/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: studentActivityUpdateFeedbackBody,
    },
    options,
  );
};

export type StudentActivityUpdateFeedbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityUpdateFeedback>>
>;
export type StudentActivityUpdateFeedbackMutationBody = StudentActivityUpdateFeedbackBody;
export type StudentActivityUpdateFeedbackMutationError = ErrorType<unknown>;

export const useStudentActivityUpdateFeedback = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityUpdateFeedback>>,
    TError,
    { id: number; data: StudentActivityUpdateFeedbackBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityUpdateFeedback>>,
    { id: number; data: StudentActivityUpdateFeedbackBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentActivityUpdateFeedback(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityUpdateFeedback>>,
    TError,
    { id: number; data: StudentActivityUpdateFeedbackBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 차시 조회(선생님용)
 */
export const studentActivitySessionFindByTeacher = (
  params: StudentActivitySessionFindByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivitySession[]>(
    { url: `/api/student-activity-session/teacher`, method: 'get', params, signal },
    options,
  );
};

export const getStudentActivitySessionFindByTeacherQueryKey = (params: StudentActivitySessionFindByTeacherParams) => [
  `/api/student-activity-session/teacher`,
  ...(params ? [params] : []),
];

export type StudentActivitySessionFindByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivitySessionFindByTeacher>>
>;
export type StudentActivitySessionFindByTeacherQueryError = ErrorType<void>;

export const useStudentActivitySessionFindByTeacher = <
  TData = Awaited<ReturnType<typeof studentActivitySessionFindByTeacher>>,
  TError = ErrorType<void>,
>(
  params: StudentActivitySessionFindByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivitySessionFindByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivitySessionFindByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivitySessionFindByTeacher>>> = ({ signal }) =>
    studentActivitySessionFindByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivitySessionFindByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 차시 단건 조회(선생님용)
 */
export const studentActivitySessionFindOneByTeacher = (
  params: StudentActivitySessionFindOneByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivitySession>(
    { url: `/api/student-activity-session/teacher/one`, method: 'get', params, signal },
    options,
  );
};

export const getStudentActivitySessionFindOneByTeacherQueryKey = (
  params: StudentActivitySessionFindOneByTeacherParams,
) => [`/api/student-activity-session/teacher/one`, ...(params ? [params] : [])];

export type StudentActivitySessionFindOneByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivitySessionFindOneByTeacher>>
>;
export type StudentActivitySessionFindOneByTeacherQueryError = ErrorType<void>;

export const useStudentActivitySessionFindOneByTeacher = <
  TData = Awaited<ReturnType<typeof studentActivitySessionFindOneByTeacher>>,
  TError = ErrorType<void>,
>(
  params: StudentActivitySessionFindOneByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivitySessionFindOneByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivitySessionFindOneByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivitySessionFindOneByTeacher>>> = ({ signal }) =>
    studentActivitySessionFindOneByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivitySessionFindOneByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 차시 단건 조회(학생용)
 */
export const studentActivitySessionFindOneByStudent = (
  params: StudentActivitySessionFindOneByStudentParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivitySession>(
    { url: `/api/student-activity-session/one`, method: 'get', params, signal },
    options,
  );
};

export const getStudentActivitySessionFindOneByStudentQueryKey = (
  params: StudentActivitySessionFindOneByStudentParams,
) => [`/api/student-activity-session/one`, ...(params ? [params] : [])];

export type StudentActivitySessionFindOneByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivitySessionFindOneByStudent>>
>;
export type StudentActivitySessionFindOneByStudentQueryError = ErrorType<void>;

export const useStudentActivitySessionFindOneByStudent = <
  TData = Awaited<ReturnType<typeof studentActivitySessionFindOneByStudent>>,
  TError = ErrorType<void>,
>(
  params: StudentActivitySessionFindOneByStudentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivitySessionFindOneByStudent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivitySessionFindOneByStudentQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivitySessionFindOneByStudent>>> = ({ signal }) =>
    studentActivitySessionFindOneByStudent(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivitySessionFindOneByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 차시 생성&수정
 */
export const studentActivitySessionSaveOne = (
  requestUpdateStudentActivitySessionDto: RequestUpdateStudentActivitySessionDto,
  params: StudentActivitySessionSaveOneParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentActivitySession>(
    {
      url: `/api/student-activity-session`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateStudentActivitySessionDto,
      params,
    },
    options,
  );
};

export type StudentActivitySessionSaveOneMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivitySessionSaveOne>>
>;
export type StudentActivitySessionSaveOneMutationBody = RequestUpdateStudentActivitySessionDto;
export type StudentActivitySessionSaveOneMutationError = ErrorType<void>;

export const useStudentActivitySessionSaveOne = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivitySessionSaveOne>>,
    TError,
    { data: RequestUpdateStudentActivitySessionDto; params: StudentActivitySessionSaveOneParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivitySessionSaveOne>>,
    { data: RequestUpdateStudentActivitySessionDto; params: StudentActivitySessionSaveOneParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return studentActivitySessionSaveOne(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivitySessionSaveOne>>,
    TError,
    { data: RequestUpdateStudentActivitySessionDto; params: StudentActivitySessionSaveOneParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동댓글 목록 조회
 */
export const studentActivityCommentFindAll = (
  studentActivityId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Comment[]>(
    { url: `/api/student-activity-comment/${studentActivityId}`, method: 'get', signal },
    options,
  );
};

export const getStudentActivityCommentFindAllQueryKey = (studentActivityId: number) => [
  `/api/student-activity-comment/${studentActivityId}`,
];

export type StudentActivityCommentFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentFindAll>>
>;
export type StudentActivityCommentFindAllQueryError = ErrorType<unknown>;

export const useStudentActivityCommentFindAll = <
  TData = Awaited<ReturnType<typeof studentActivityCommentFindAll>>,
  TError = ErrorType<unknown>,
>(
  studentActivityId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityCommentFindAll>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityCommentFindAllQueryKey(studentActivityId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityCommentFindAll>>> = ({ signal }) =>
    studentActivityCommentFindAll(studentActivityId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityCommentFindAll>>, TError, TData>(queryKey, queryFn, {
    enabled: !!studentActivityId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생활동댓글 추가
 */
export const studentActivityCommentCreate = (
  requestCreateActivityCommentDto: RequestCreateActivityCommentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Comment>(
    {
      url: `/api/student-activity-comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivityCommentDto,
    },
    options,
  );
};

export type StudentActivityCommentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentCreate>>
>;
export type StudentActivityCommentCreateMutationBody = RequestCreateActivityCommentDto;
export type StudentActivityCommentCreateMutationError = ErrorType<void>;

export const useStudentActivityCommentCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityCommentCreate>>,
    TError,
    { data: RequestCreateActivityCommentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityCommentCreate>>,
    { data: RequestCreateActivityCommentDto }
  > = (props) => {
    const { data } = props ?? {};

    return studentActivityCommentCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityCommentCreate>>,
    TError,
    { data: RequestCreateActivityCommentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동댓글 수정
 */
export const studentActivityCommentUpdate = (
  id: number,
  studentActivityCommentUpdateBody: StudentActivityCommentUpdateBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Comment>(
    {
      url: `/api/student-activity-comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: studentActivityCommentUpdateBody,
    },
    options,
  );
};

export type StudentActivityCommentUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentUpdate>>
>;
export type StudentActivityCommentUpdateMutationBody = StudentActivityCommentUpdateBody;
export type StudentActivityCommentUpdateMutationError = ErrorType<void>;

export const useStudentActivityCommentUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityCommentUpdate>>,
    TError,
    { id: number; data: StudentActivityCommentUpdateBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityCommentUpdate>>,
    { id: number; data: StudentActivityCommentUpdateBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentActivityCommentUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityCommentUpdate>>,
    TError,
    { id: number; data: StudentActivityCommentUpdateBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동댓글 삭제
 */
export const studentActivityCommentDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/student-activity-comment/${id}`, method: 'delete' }, options);
};

export type StudentActivityCommentDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentDelete>>
>;

export type StudentActivityCommentDeleteMutationError = ErrorType<unknown>;

export const useStudentActivityCommentDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityCommentDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof studentActivityCommentDelete>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return studentActivityCommentDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof studentActivityCommentDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 가정통신문(학생설문) 단건 조회(선생님용)
 */
export const studentNewsletterFindOne = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentNewsletter>({ url: `/api/student-newsletters/${id}`, method: 'get', signal }, options);
};

export const getStudentNewsletterFindOneQueryKey = (id: number) => [`/api/student-newsletters/${id}`];

export type StudentNewsletterFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof studentNewsletterFindOne>>>;
export type StudentNewsletterFindOneQueryError = ErrorType<unknown>;

export const useStudentNewsletterFindOne = <
  TData = Awaited<ReturnType<typeof studentNewsletterFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentNewsletterFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentNewsletterFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentNewsletterFindOne>>> = ({ signal }) =>
    studentNewsletterFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentNewsletterFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 단건 조회(학생용)
 */
export const studentNewsletterFindOneByStudent = (
  newsletterId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentNewsletter>(
    { url: `/api/student-newsletters/by-student/${newsletterId}`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterFindOneByStudentQueryKey = (newsletterId: number) => [
  `/api/student-newsletters/by-student/${newsletterId}`,
];

export type StudentNewsletterFindOneByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterFindOneByStudent>>
>;
export type StudentNewsletterFindOneByStudentQueryError = ErrorType<unknown>;

export const useStudentNewsletterFindOneByStudent = <
  TData = Awaited<ReturnType<typeof studentNewsletterFindOneByStudent>>,
  TError = ErrorType<unknown>,
>(
  newsletterId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentNewsletterFindOneByStudent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentNewsletterFindOneByStudentQueryKey(newsletterId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentNewsletterFindOneByStudent>>> = ({ signal }) =>
    studentNewsletterFindOneByStudent(newsletterId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentNewsletterFindOneByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!newsletterId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 목록 조회(by newsletterId)
 */
export const studentNewsletterFindAllByNewsletterId = (
  newsletterId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentNewsletter[]>(
    { url: `/api/student-newsletters/by-newsletter-id/${newsletterId}`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterFindAllByNewsletterIdQueryKey = (newsletterId: number) => [
  `/api/student-newsletters/by-newsletter-id/${newsletterId}`,
];

export type StudentNewsletterFindAllByNewsletterIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterFindAllByNewsletterId>>
>;
export type StudentNewsletterFindAllByNewsletterIdQueryError = ErrorType<void>;

export const useStudentNewsletterFindAllByNewsletterId = <
  TData = Awaited<ReturnType<typeof studentNewsletterFindAllByNewsletterId>>,
  TError = ErrorType<void>,
>(
  newsletterId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentNewsletterFindAllByNewsletterId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentNewsletterFindAllByNewsletterIdQueryKey(newsletterId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentNewsletterFindAllByNewsletterId>>> = ({ signal }) =>
    studentNewsletterFindAllByNewsletterId(newsletterId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentNewsletterFindAllByNewsletterId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!newsletterId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(공지사항) 미확인자 조회(by newsletterId)
 */
export const studentNewsletterFindUnreadUsersByNewsletterId = (
  newsletterId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseChatAttendeeDto[]>(
    { url: `/api/student-newsletters/${newsletterId}/unread-notice-users`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterFindUnreadUsersByNewsletterIdQueryKey = (newsletterId: number) => [
  `/api/student-newsletters/${newsletterId}/unread-notice-users`,
];

export type StudentNewsletterFindUnreadUsersByNewsletterIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterFindUnreadUsersByNewsletterId>>
>;
export type StudentNewsletterFindUnreadUsersByNewsletterIdQueryError = ErrorType<void>;

export const useStudentNewsletterFindUnreadUsersByNewsletterId = <
  TData = Awaited<ReturnType<typeof studentNewsletterFindUnreadUsersByNewsletterId>>,
  TError = ErrorType<void>,
>(
  newsletterId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentNewsletterFindUnreadUsersByNewsletterId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentNewsletterFindUnreadUsersByNewsletterIdQueryKey(newsletterId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentNewsletterFindUnreadUsersByNewsletterId>>> = ({
    signal,
  }) => studentNewsletterFindUnreadUsersByNewsletterId(newsletterId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentNewsletterFindUnreadUsersByNewsletterId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!newsletterId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 단건 조회(by UUID)
 */
export const studentNewsletterFindOneByUUID = (
  uuid: string,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentNewsletter>(
    { url: `/api/student-newsletters/by-uuid/${uuid}`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterFindOneByUUIDQueryKey = (uuid: string) => [`/api/student-newsletters/by-uuid/${uuid}`];

export type StudentNewsletterFindOneByUUIDQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterFindOneByUUID>>
>;
export type StudentNewsletterFindOneByUUIDQueryError = ErrorType<unknown>;

export const useStudentNewsletterFindOneByUUID = <
  TData = Awaited<ReturnType<typeof studentNewsletterFindOneByUUID>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentNewsletterFindOneByUUID>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentNewsletterFindOneByUUIDQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentNewsletterFindOneByUUID>>> = ({ signal }) =>
    studentNewsletterFindOneByUUID(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentNewsletterFindOneByUUID>>, TError, TData>(queryKey, queryFn, {
    enabled: !!uuid,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 승인
 */
export const studentNewsletterApprove = (
  uuid: string,
  requestApproveStudentNewsletterDto: RequestApproveStudentNewsletterDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/student-newsletters/approve/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestApproveStudentNewsletterDto,
    },
    options,
  );
};

export type StudentNewsletterApproveMutationResult = NonNullable<Awaited<ReturnType<typeof studentNewsletterApprove>>>;
export type StudentNewsletterApproveMutationBody = RequestApproveStudentNewsletterDto;
export type StudentNewsletterApproveMutationError = ErrorType<unknown>;

export const useStudentNewsletterApprove = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentNewsletterApprove>>,
    TError,
    { uuid: string; data: RequestApproveStudentNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentNewsletterApprove>>,
    { uuid: string; data: RequestApproveStudentNewsletterDto }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return studentNewsletterApprove(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentNewsletterApprove>>,
    TError,
    { uuid: string; data: RequestApproveStudentNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문(학생설문) 답변
 */
export const studentNewsletterUpsert = (
  requestUpsertStudentNewsletterDto: RequestUpsertStudentNewsletterDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentNewsletter>(
    {
      url: `/api/student-newsletters`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpsertStudentNewsletterDto,
    },
    options,
  );
};

export type StudentNewsletterUpsertMutationResult = NonNullable<Awaited<ReturnType<typeof studentNewsletterUpsert>>>;
export type StudentNewsletterUpsertMutationBody = RequestUpsertStudentNewsletterDto;
export type StudentNewsletterUpsertMutationError = ErrorType<unknown>;

export const useStudentNewsletterUpsert = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentNewsletterUpsert>>,
    TError,
    { data: RequestUpsertStudentNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentNewsletterUpsert>>,
    { data: RequestUpsertStudentNewsletterDto }
  > = (props) => {
    const { data } = props ?? {};

    return studentNewsletterUpsert(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentNewsletterUpsert>>,
    TError,
    { data: RequestUpsertStudentNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 학생별 조회(선생님용)
 */
export const studentActivityV3FindStudentCard = (
  params: StudentActivityV3FindStudentCardParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivityV3[]>(
    { url: `/api/student-activityv3/teacher/student-card`, method: 'get', params, signal },
    options,
  );
};

export const getStudentActivityV3FindStudentCardQueryKey = (params: StudentActivityV3FindStudentCardParams) => [
  `/api/student-activityv3/teacher/student-card`,
  ...(params ? [params] : []),
];

export type StudentActivityV3FindStudentCardQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityV3FindStudentCard>>
>;
export type StudentActivityV3FindStudentCardQueryError = ErrorType<void>;

export const useStudentActivityV3FindStudentCard = <
  TData = Awaited<ReturnType<typeof studentActivityV3FindStudentCard>>,
  TError = ErrorType<void>,
>(
  params: StudentActivityV3FindStudentCardParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityV3FindStudentCard>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityV3FindStudentCardQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityV3FindStudentCard>>> = ({ signal }) =>
    studentActivityV3FindStudentCard(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityV3FindStudentCard>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 활동 세부정보 조회(선생님용)
 */
export const studentActivityV3FindByTeacher = (
  params: StudentActivityV3FindByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivityV3>({ url: `/api/student-activityv3/teacher`, method: 'get', params, signal }, options);
};

export const getStudentActivityV3FindByTeacherQueryKey = (params: StudentActivityV3FindByTeacherParams) => [
  `/api/student-activityv3/teacher`,
  ...(params ? [params] : []),
];

export type StudentActivityV3FindByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityV3FindByTeacher>>
>;
export type StudentActivityV3FindByTeacherQueryError = ErrorType<void>;

export const useStudentActivityV3FindByTeacher = <
  TData = Awaited<ReturnType<typeof studentActivityV3FindByTeacher>>,
  TError = ErrorType<void>,
>(
  params: StudentActivityV3FindByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityV3FindByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityV3FindByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityV3FindByTeacher>>> = ({ signal }) =>
    studentActivityV3FindByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityV3FindByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 관찰기록/활동요약 수정
 */
export const studentActivityV3SaveByTeacher = (
  requestUpdateStudentActivityV3Dto: RequestUpdateStudentActivityV3Dto,
  params: StudentActivityV3SaveByTeacherParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentActivityV3>(
    {
      url: `/api/student-activityv3/teacher`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateStudentActivityV3Dto,
      params,
    },
    options,
  );
};

export type StudentActivityV3SaveByTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityV3SaveByTeacher>>
>;
export type StudentActivityV3SaveByTeacherMutationBody = RequestUpdateStudentActivityV3Dto;
export type StudentActivityV3SaveByTeacherMutationError = ErrorType<void>;

export const useStudentActivityV3SaveByTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityV3SaveByTeacher>>,
    TError,
    { data: RequestUpdateStudentActivityV3Dto; params: StudentActivityV3SaveByTeacherParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityV3SaveByTeacher>>,
    { data: RequestUpdateStudentActivityV3Dto; params: StudentActivityV3SaveByTeacherParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return studentActivityV3SaveByTeacher(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityV3SaveByTeacher>>,
    TError,
    { data: RequestUpdateStudentActivityV3Dto; params: StudentActivityV3SaveByTeacherParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 활동 세부정보 조회(학생용)
 */
export const studentActivityV3FindByStudent = (
  params: StudentActivityV3FindByStudentParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentActivityV3>({ url: `/api/student-activityv3/student`, method: 'get', params, signal }, options);
};

export const getStudentActivityV3FindByStudentQueryKey = (params: StudentActivityV3FindByStudentParams) => [
  `/api/student-activityv3/student`,
  ...(params ? [params] : []),
];

export type StudentActivityV3FindByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityV3FindByStudent>>
>;
export type StudentActivityV3FindByStudentQueryError = ErrorType<void>;

export const useStudentActivityV3FindByStudent = <
  TData = Awaited<ReturnType<typeof studentActivityV3FindByStudent>>,
  TError = ErrorType<void>,
>(
  params: StudentActivityV3FindByStudentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityV3FindByStudent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityV3FindByStudentQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityV3FindByStudent>>> = ({ signal }) =>
    studentActivityV3FindByStudent(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityV3FindByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 활동 보고서 수정
 */
export const studentActivityV3SaveStudentText = (
  requestUpdateStudentTextDto: RequestUpdateStudentTextDto,
  params: StudentActivityV3SaveStudentTextParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentActivityV3>(
    {
      url: `/api/student-activityv3/student-text`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateStudentTextDto,
      params,
    },
    options,
  );
};

export type StudentActivityV3SaveStudentTextMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityV3SaveStudentText>>
>;
export type StudentActivityV3SaveStudentTextMutationBody = RequestUpdateStudentTextDto;
export type StudentActivityV3SaveStudentTextMutationError = ErrorType<void>;

export const useStudentActivityV3SaveStudentText = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityV3SaveStudentText>>,
    TError,
    { data: RequestUpdateStudentTextDto; params: StudentActivityV3SaveStudentTextParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityV3SaveStudentText>>,
    { data: RequestUpdateStudentTextDto; params: StudentActivityV3SaveStudentTextParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return studentActivityV3SaveStudentText(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityV3SaveStudentText>>,
    TError,
    { data: RequestUpdateStudentTextDto; params: StudentActivityV3SaveStudentTextParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동요약 일괄 다운로드
 */
export const studentActivityV3DownloadRecordSummary = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/student-activityv3/download/summary/${groupId}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getStudentActivityV3DownloadRecordSummaryQueryKey = (groupId: number) => [
  `/api/student-activityv3/download/summary/${groupId}`,
];

export type StudentActivityV3DownloadRecordSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityV3DownloadRecordSummary>>
>;
export type StudentActivityV3DownloadRecordSummaryQueryError = ErrorType<unknown>;

export const useStudentActivityV3DownloadRecordSummary = <
  TData = Awaited<ReturnType<typeof studentActivityV3DownloadRecordSummary>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentActivityV3DownloadRecordSummary>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityV3DownloadRecordSummaryQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityV3DownloadRecordSummary>>> = ({ signal }) =>
    studentActivityV3DownloadRecordSummary(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentActivityV3DownloadRecordSummary>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 교사 그룹 목록 조회, group controller 조회 사용하도록 변경
 */
export const teacherGroupsGetTeacherGroups = (
  params: TeacherGroupsGetTeacherGroupsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<void>({ url: `/api/teacher-groups`, method: 'get', params, signal }, options);
};

export const getTeacherGroupsGetTeacherGroupsQueryKey = (params: TeacherGroupsGetTeacherGroupsParams) => [
  `/api/teacher-groups`,
  ...(params ? [params] : []),
];

export type TeacherGroupsGetTeacherGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof teacherGroupsGetTeacherGroups>>
>;
export type TeacherGroupsGetTeacherGroupsQueryError = ErrorType<unknown>;

export const useTeacherGroupsGetTeacherGroups = <
  TData = Awaited<ReturnType<typeof teacherGroupsGetTeacherGroups>>,
  TError = ErrorType<unknown>,
>(
  params: TeacherGroupsGetTeacherGroupsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teacherGroupsGetTeacherGroups>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeacherGroupsGetTeacherGroupsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teacherGroupsGetTeacherGroups>>> = ({ signal }) =>
    teacherGroupsGetTeacherGroups(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof teacherGroupsGetTeacherGroups>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 과목 조회
 */
export const teacherGroupsFindBySubject = (
  params?: TeacherGroupsFindBySubjectParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<TeacherGroup[]>({ url: `/api/teacher-groups/subject`, method: 'get', params, signal }, options);
};

export const getTeacherGroupsFindBySubjectQueryKey = (params?: TeacherGroupsFindBySubjectParams) => [
  `/api/teacher-groups/subject`,
  ...(params ? [params] : []),
];

export type TeacherGroupsFindBySubjectQueryResult = NonNullable<Awaited<ReturnType<typeof teacherGroupsFindBySubject>>>;
export type TeacherGroupsFindBySubjectQueryError = ErrorType<unknown>;

export const useTeacherGroupsFindBySubject = <
  TData = Awaited<ReturnType<typeof teacherGroupsFindBySubject>>,
  TError = ErrorType<unknown>,
>(
  params?: TeacherGroupsFindBySubjectParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teacherGroupsFindBySubject>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeacherGroupsFindBySubjectQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teacherGroupsFindBySubject>>> = ({ signal }) =>
    teacherGroupsFindBySubject(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof teacherGroupsFindBySubject>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 그룹 조회
 */
export const teacherGroupsFindAllByTeacher = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<TeacherGroup[]>({ url: `/api/teacher-groups/by-teacher`, method: 'get', signal }, options);
};

export const getTeacherGroupsFindAllByTeacherQueryKey = () => [`/api/teacher-groups/by-teacher`];

export type TeacherGroupsFindAllByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof teacherGroupsFindAllByTeacher>>
>;
export type TeacherGroupsFindAllByTeacherQueryError = ErrorType<unknown>;

export const useTeacherGroupsFindAllByTeacher = <
  TData = Awaited<ReturnType<typeof teacherGroupsFindAllByTeacher>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof teacherGroupsFindAllByTeacher>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeacherGroupsFindAllByTeacherQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teacherGroupsFindAllByTeacher>>> = ({ signal }) =>
    teacherGroupsFindAllByTeacher(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof teacherGroupsFindAllByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹에 속한 선생님 조회
 */
export const teacherGroupsFindTeachersWithKlassByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<TeacherGroup[]>({ url: `/api/teacher-groups/by-group/${groupId}`, method: 'get', signal }, options);
};

export const getTeacherGroupsFindTeachersWithKlassByGroupIdQueryKey = (groupId: number) => [
  `/api/teacher-groups/by-group/${groupId}`,
];

export type TeacherGroupsFindTeachersWithKlassByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof teacherGroupsFindTeachersWithKlassByGroupId>>
>;
export type TeacherGroupsFindTeachersWithKlassByGroupIdQueryError = ErrorType<unknown>;

export const useTeacherGroupsFindTeachersWithKlassByGroupId = <
  TData = Awaited<ReturnType<typeof teacherGroupsFindTeachersWithKlassByGroupId>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teacherGroupsFindTeachersWithKlassByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeacherGroupsFindTeachersWithKlassByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teacherGroupsFindTeachersWithKlassByGroupId>>> = ({
    signal,
  }) => teacherGroupsFindTeachersWithKlassByGroupId(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof teacherGroupsFindTeachersWithKlassByGroupId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 단건 조회
 */
export const timetablesFindOne = (
  year: number,
  semester: number,
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Timetable>(
    { url: `/api/timetable-v1/${year}/${semester}/${groupId}`, method: 'get', signal },
    options,
  );
};

export const getTimetablesFindOneQueryKey = (year: number, semester: number, groupId: number) => [
  `/api/timetable-v1/${year}/${semester}/${groupId}`,
];

export type TimetablesFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof timetablesFindOne>>>;
export type TimetablesFindOneQueryError = ErrorType<unknown>;

export const useTimetablesFindOne = <
  TData = Awaited<ReturnType<typeof timetablesFindOne>>,
  TError = ErrorType<unknown>,
>(
  year: number,
  semester: number,
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablesFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablesFindOneQueryKey(year, semester, groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesFindOne>>> = ({ signal }) =>
    timetablesFindOne(year, semester, groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablesFindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(year && semester && groupId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 추가/수정
 */
export const timetablesCreateOrUpdate = (
  requestCreateTimetableDto: RequestCreateTimetableDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Timetable>(
    {
      url: `/api/timetable-v1`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateTimetableDto,
    },
    options,
  );
};

export type TimetablesCreateOrUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof timetablesCreateOrUpdate>>>;
export type TimetablesCreateOrUpdateMutationBody = RequestCreateTimetableDto;
export type TimetablesCreateOrUpdateMutationError = ErrorType<void>;

export const useTimetablesCreateOrUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetablesCreateOrUpdate>>,
    TError,
    { data: RequestCreateTimetableDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetablesCreateOrUpdate>>,
    { data: RequestCreateTimetableDto }
  > = (props) => {
    const { data } = props ?? {};

    return timetablesCreateOrUpdate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetablesCreateOrUpdate>>,
    TError,
    { data: RequestCreateTimetableDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 시간표 v2 단건 조회
 */
export const timetablesV2FindOne = (
  year: number,
  semester: number,
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<TimetableV2>({ url: `/api/timetable/${year}/${semester}/${groupId}`, method: 'get', signal }, options);
};

export const getTimetablesV2FindOneQueryKey = (year: number, semester: number, groupId: number) => [
  `/api/timetable/${year}/${semester}/${groupId}`,
];

export type TimetablesV2FindOneQueryResult = NonNullable<Awaited<ReturnType<typeof timetablesV2FindOne>>>;
export type TimetablesV2FindOneQueryError = ErrorType<unknown>;

export const useTimetablesV2FindOne = <
  TData = Awaited<ReturnType<typeof timetablesV2FindOne>>,
  TError = ErrorType<unknown>,
>(
  year: number,
  semester: number,
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablesV2FindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablesV2FindOneQueryKey(year, semester, groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesV2FindOne>>> = ({ signal }) =>
    timetablesV2FindOne(year, semester, groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablesV2FindOne>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(year && semester && groupId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 v2 선생님 목록
 */
export const timetablesV2GetAllTeacher = (
  params: TimetablesV2GetAllTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseTimetableV2TeacherDto[]>(
    { url: `/api/timetable/allteacher`, method: 'get', params, signal },
    options,
  );
};

export const getTimetablesV2GetAllTeacherQueryKey = (params: TimetablesV2GetAllTeacherParams) => [
  `/api/timetable/allteacher`,
  ...(params ? [params] : []),
];

export type TimetablesV2GetAllTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof timetablesV2GetAllTeacher>>>;
export type TimetablesV2GetAllTeacherQueryError = ErrorType<unknown>;

export const useTimetablesV2GetAllTeacher = <
  TData = Awaited<ReturnType<typeof timetablesV2GetAllTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: TimetablesV2GetAllTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablesV2GetAllTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablesV2GetAllTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesV2GetAllTeacher>>> = ({ signal }) =>
    timetablesV2GetAllTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablesV2GetAllTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 시간표 v2
 */
export const timetablesV2FindOneByStudent = (
  params: TimetablesV2FindOneByStudentParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<TimetableV2Student>({ url: `/api/timetable/by-student`, method: 'get', params, signal }, options);
};

export const getTimetablesV2FindOneByStudentQueryKey = (params: TimetablesV2FindOneByStudentParams) => [
  `/api/timetable/by-student`,
  ...(params ? [params] : []),
];

export type TimetablesV2FindOneByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablesV2FindOneByStudent>>
>;
export type TimetablesV2FindOneByStudentQueryError = ErrorType<unknown>;

export const useTimetablesV2FindOneByStudent = <
  TData = Awaited<ReturnType<typeof timetablesV2FindOneByStudent>>,
  TError = ErrorType<unknown>,
>(
  params: TimetablesV2FindOneByStudentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablesV2FindOneByStudent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablesV2FindOneByStudentQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesV2FindOneByStudent>>> = ({ signal }) =>
    timetablesV2FindOneByStudent(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablesV2FindOneByStudent>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님이 학생 시간표 조회
 */
export const timetablesV2FindOneByStudentId = (
  id: number,
  params: TimetablesV2FindOneByStudentIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<TimetableV2Student>({ url: `/api/timetable/student/${id}`, method: 'get', params, signal }, options);
};

export const getTimetablesV2FindOneByStudentIdQueryKey = (id: number, params: TimetablesV2FindOneByStudentIdParams) => [
  `/api/timetable/student/${id}`,
  ...(params ? [params] : []),
];

export type TimetablesV2FindOneByStudentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablesV2FindOneByStudentId>>
>;
export type TimetablesV2FindOneByStudentIdQueryError = ErrorType<unknown>;

export const useTimetablesV2FindOneByStudentId = <
  TData = Awaited<ReturnType<typeof timetablesV2FindOneByStudentId>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: TimetablesV2FindOneByStudentIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablesV2FindOneByStudentId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablesV2FindOneByStudentIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesV2FindOneByStudentId>>> = ({ signal }) =>
    timetablesV2FindOneByStudentId(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablesV2FindOneByStudentId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 v2 자리배치도 업데이트
 */
export const timetablesV2UpdateSeatPosition = (
  id: number,
  timetablesV2UpdateSeatPositionBody: TimetablesV2UpdateSeatPositionBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<TimetableV2>(
    {
      url: `/api/timetable/update-seat-position/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: timetablesV2UpdateSeatPositionBody,
    },
    options,
  );
};

export type TimetablesV2UpdateSeatPositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetablesV2UpdateSeatPosition>>
>;
export type TimetablesV2UpdateSeatPositionMutationBody = TimetablesV2UpdateSeatPositionBody;
export type TimetablesV2UpdateSeatPositionMutationError = ErrorType<unknown>;

export const useTimetablesV2UpdateSeatPosition = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetablesV2UpdateSeatPosition>>,
    TError,
    { id: number; data: TimetablesV2UpdateSeatPositionBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetablesV2UpdateSeatPosition>>,
    { id: number; data: TimetablesV2UpdateSeatPositionBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return timetablesV2UpdateSeatPosition(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetablesV2UpdateSeatPosition>>,
    TError,
    { id: number; data: TimetablesV2UpdateSeatPositionBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 아이디 찾기
 */
export const userFindId = (
  params: UserFindIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<boolean>({ url: `/api/users/find-id`, method: 'get', params, signal }, options);
};

export const getUserFindIdQueryKey = (params: UserFindIdParams) => [`/api/users/find-id`, ...(params ? [params] : [])];

export type UserFindIdQueryResult = NonNullable<Awaited<ReturnType<typeof userFindId>>>;
export type UserFindIdQueryError = ErrorType<unknown>;

export const useUserFindId = <TData = Awaited<ReturnType<typeof userFindId>>, TError = ErrorType<unknown>>(
  params: UserFindIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userFindId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserFindIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userFindId>>> = ({ signal }) =>
    userFindId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userFindId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 비밀번호 찾기
 */
export const userFindPassword = (
  requestFindPasswordDto: RequestFindPasswordDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/users/find-password`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestFindPasswordDto,
    },
    options,
  );
};

export type UserFindPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof userFindPassword>>>;
export type UserFindPasswordMutationBody = RequestFindPasswordDto;
export type UserFindPasswordMutationError = ErrorType<unknown>;

export const useUserFindPassword = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userFindPassword>>,
    TError,
    { data: RequestFindPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof userFindPassword>>, { data: RequestFindPasswordDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return userFindPassword(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userFindPassword>>, TError, { data: RequestFindPasswordDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 비밀번호 재설정
 */
export const userResetPassword = (
  requestResetPasswordDto: RequestResetPasswordDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/users/reset-password`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestResetPasswordDto,
    },
    options,
  );
};

export type UserResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof userResetPassword>>>;
export type UserResetPasswordMutationBody = RequestResetPasswordDto;
export type UserResetPasswordMutationError = ErrorType<void>;

export const useUserResetPassword = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userResetPassword>>,
    TError,
    { data: RequestResetPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userResetPassword>>,
    { data: RequestResetPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return userResetPassword(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userResetPassword>>,
    TError,
    { data: RequestResetPasswordDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * 회원의 accessToken 을 얻어냅니다.
 * @summary 로그인 API
 */
export const userLogin = (requestLoginDto: RequestLoginDto, options?: SecondParameter<typeof mutator>) => {
  return mutator<AuthResponse>(
    { url: `/api/users/login`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: requestLoginDto },
    options,
  );
};

export type UserLoginMutationResult = NonNullable<Awaited<ReturnType<typeof userLogin>>>;
export type UserLoginMutationBody = RequestLoginDto;
export type UserLoginMutationError = ErrorType<void>;

export const useUserLogin = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof userLogin>>, TError, { data: RequestLoginDto }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof userLogin>>, { data: RequestLoginDto }> = (props) => {
    const { data } = props ?? {};

    return userLogin(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userLogin>>, TError, { data: RequestLoginDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * refreshToken 을 이용해 회원의 accessToken 을 얻어냅니다.
 * @summary Refresh 로그인 API
 */
export const userRefreshLogin = (options?: SecondParameter<typeof mutator>) => {
  return mutator<AuthResponse>({ url: `/api/users/refresh-login`, method: 'post' }, options);
};

export type UserRefreshLoginMutationResult = NonNullable<Awaited<ReturnType<typeof userRefreshLogin>>>;

export type UserRefreshLoginMutationError = ErrorType<unknown>;

export const useUserRefreshLogin = <TError = ErrorType<unknown>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof userRefreshLogin>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof userRefreshLogin>>, TVariables> = () => {
    return userRefreshLogin(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userRefreshLogin>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 로그아웃 API
 */
export const userLogout = (options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/users/logout`, method: 'post' }, options);
};

export type UserLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof userLogout>>>;

export type UserLogoutMutationError = ErrorType<unknown>;

export const useUserLogout = <TError = ErrorType<unknown>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof userLogout>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof userLogout>>, TVariables> = () => {
    return userLogout(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userLogout>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
};

/**
 * @summary 로그인 유저정보(부모) + 자녀조회
 */
export const userMeWithChildren = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ParentDto>({ url: `/api/users/me/children`, method: 'get', signal }, options);
};

export const getUserMeWithChildrenQueryKey = () => [`/api/users/me/children`];

export type UserMeWithChildrenQueryResult = NonNullable<Awaited<ReturnType<typeof userMeWithChildren>>>;
export type UserMeWithChildrenQueryError = ErrorType<unknown>;

export const useUserMeWithChildren = <
  TData = Awaited<ReturnType<typeof userMeWithChildren>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userMeWithChildren>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserMeWithChildrenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userMeWithChildren>>> = ({ signal }) =>
    userMeWithChildren(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userMeWithChildren>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 로그인 유저정보 조회
 */
export const userMe = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseUserDto>({ url: `/api/users/me`, method: 'get', signal }, options);
};

export const getUserMeQueryKey = () => [`/api/users/me`];

export type UserMeQueryResult = NonNullable<Awaited<ReturnType<typeof userMe>>>;
export type UserMeQueryError = ErrorType<unknown>;

export const useUserMe = <TData = Awaited<ReturnType<typeof userMe>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userMe>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userMe>>> = ({ signal }) => userMe(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userMe>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 마이페이지 내 정보 수정
 */
export const userUpdateMe = (updateUserDto: UpdateUserDto, options?: SecondParameter<typeof mutator>) => {
  return mutator<ResponseUserDto>(
    { url: `/api/users/me`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: updateUserDto },
    options,
  );
};

export type UserUpdateMeMutationResult = NonNullable<Awaited<ReturnType<typeof userUpdateMe>>>;
export type UserUpdateMeMutationBody = UpdateUserDto;
export type UserUpdateMeMutationError = ErrorType<unknown>;

export const useUserUpdateMe = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof userUpdateMe>>, TError, { data: UpdateUserDto }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof userUpdateMe>>, { data: UpdateUserDto }> = (props) => {
    const { data } = props ?? {};

    return userUpdateMe(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userUpdateMe>>, TError, { data: UpdateUserDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 최초회원가입정보입력(first login)
 */
export const userUpdateMyInfoAtFirstLogin = (
  requestUpdateMyInfoAtFirstLoginDto: RequestUpdateMyInfoAtFirstLoginDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseUserDto>(
    {
      url: `/api/users/update-my-info-at-first-login`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateMyInfoAtFirstLoginDto,
    },
    options,
  );
};

export type UserUpdateMyInfoAtFirstLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof userUpdateMyInfoAtFirstLogin>>
>;
export type UserUpdateMyInfoAtFirstLoginMutationBody = RequestUpdateMyInfoAtFirstLoginDto;
export type UserUpdateMyInfoAtFirstLoginMutationError = ErrorType<void>;

export const useUserUpdateMyInfoAtFirstLogin = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userUpdateMyInfoAtFirstLogin>>,
    TError,
    { data: RequestUpdateMyInfoAtFirstLoginDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userUpdateMyInfoAtFirstLogin>>,
    { data: RequestUpdateMyInfoAtFirstLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateMyInfoAtFirstLogin(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userUpdateMyInfoAtFirstLogin>>,
    TError,
    { data: RequestUpdateMyInfoAtFirstLoginDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 부모 회원가입 요청(알림톡 발송)
 */
export const userSendParentSignUp = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<boolean>({ url: `/api/users/send-parent-sign-up`, method: 'get', signal }, options);
};

export const getUserSendParentSignUpQueryKey = () => [`/api/users/send-parent-sign-up`];

export type UserSendParentSignUpQueryResult = NonNullable<Awaited<ReturnType<typeof userSendParentSignUp>>>;
export type UserSendParentSignUpQueryError = ErrorType<unknown>;

export const useUserSendParentSignUp = <
  TData = Awaited<ReturnType<typeof userSendParentSignUp>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userSendParentSignUp>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserSendParentSignUpQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userSendParentSignUp>>> = ({ signal }) =>
    userSendParentSignUp(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userSendParentSignUp>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 부모 회원가입 요청v2(알림톡 발송)
 */
export const userSendParentSignUpV2 = (
  requestSendParentSignUpDto: RequestSendParentSignUpDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/users/send-parent-sign-up-v2`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestSendParentSignUpDto,
    },
    options,
  );
};

export type UserSendParentSignUpV2MutationResult = NonNullable<Awaited<ReturnType<typeof userSendParentSignUpV2>>>;
export type UserSendParentSignUpV2MutationBody = RequestSendParentSignUpDto;
export type UserSendParentSignUpV2MutationError = ErrorType<unknown>;

export const useUserSendParentSignUpV2 = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSendParentSignUpV2>>,
    TError,
    { data: RequestSendParentSignUpDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSendParentSignUpV2>>,
    { data: RequestSendParentSignUpDto }
  > = (props) => {
    const { data } = props ?? {};

    return userSendParentSignUpV2(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userSendParentSignUpV2>>,
    TError,
    { data: RequestSendParentSignUpDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 부모 회원가입
 */
export const userParentSignUp = (
  schoolId: number,
  requestParentSignUpDto: RequestParentSignUpDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<AuthResponse>(
    {
      url: `/api/users/parent-sign-up/${schoolId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestParentSignUpDto,
    },
    options,
  );
};

export type UserParentSignUpMutationResult = NonNullable<Awaited<ReturnType<typeof userParentSignUp>>>;
export type UserParentSignUpMutationBody = RequestParentSignUpDto;
export type UserParentSignUpMutationError = ErrorType<unknown>;

export const useUserParentSignUp = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userParentSignUp>>,
    TError,
    { schoolId: number; data: RequestParentSignUpDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userParentSignUp>>,
    { schoolId: number; data: RequestParentSignUpDto }
  > = (props) => {
    const { schoolId, data } = props ?? {};

    return userParentSignUp(schoolId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userParentSignUp>>,
    TError,
    { schoolId: number; data: RequestParentSignUpDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 부모 회원가입 직후 자녀 추가
 */
export const userCreateChildren = (uuid: string, options?: SecondParameter<typeof mutator>) => {
  return mutator<AuthResponse>({ url: `/api/users/children/${uuid}`, method: 'post' }, options);
};

export type UserCreateChildrenMutationResult = NonNullable<Awaited<ReturnType<typeof userCreateChildren>>>;

export type UserCreateChildrenMutationError = ErrorType<unknown>;

export const useUserCreateChildren = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof userCreateChildren>>, TError, { uuid: string }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof userCreateChildren>>, { uuid: string }> = (props) => {
    const { uuid } = props ?? {};

    return userCreateChildren(uuid, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userCreateChildren>>, TError, { uuid: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학부모-학생 연결 제거
 */
export const userDeleteChild = (
  requestRemoveChildDto: RequestRemoveChildDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/users/remove-child`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestRemoveChildDto,
    },
    options,
  );
};

export type UserDeleteChildMutationResult = NonNullable<Awaited<ReturnType<typeof userDeleteChild>>>;
export type UserDeleteChildMutationBody = RequestRemoveChildDto;
export type UserDeleteChildMutationError = ErrorType<void>;

export const useUserDeleteChild = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userDeleteChild>>,
    TError,
    { data: RequestRemoveChildDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof userDeleteChild>>, { data: RequestRemoveChildDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return userDeleteChild(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof userDeleteChild>>, TError, { data: RequestRemoveChildDto }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 모든 선생님 조회
 */
export const userGetAllTeachers = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseTeachersDto[]>({ url: `/api/users/teachers`, method: 'get', signal }, options);
};

export const getUserGetAllTeachersQueryKey = () => [`/api/users/teachers`];

export type UserGetAllTeachersQueryResult = NonNullable<Awaited<ReturnType<typeof userGetAllTeachers>>>;
export type UserGetAllTeachersQueryError = ErrorType<unknown>;

export const useUserGetAllTeachers = <
  TData = Awaited<ReturnType<typeof userGetAllTeachers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userGetAllTeachers>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetAllTeachersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userGetAllTeachers>>> = ({ signal }) =>
    userGetAllTeachers(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userGetAllTeachers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급의 모든 보호자 조회(선생님만 조회 가능)
 */
export const userGetParentsByGroupId = (
  params: UserGetParentsByGroupIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseGetParentsByStudentGroupDto[]>(
    { url: `/api/users/parents`, method: 'get', params, signal },
    options,
  );
};

export const getUserGetParentsByGroupIdQueryKey = (params: UserGetParentsByGroupIdParams) => [
  `/api/users/parents`,
  ...(params ? [params] : []),
];

export type UserGetParentsByGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof userGetParentsByGroupId>>>;
export type UserGetParentsByGroupIdQueryError = ErrorType<unknown>;

export const useUserGetParentsByGroupId = <
  TData = Awaited<ReturnType<typeof userGetParentsByGroupId>>,
  TError = ErrorType<unknown>,
>(
  params: UserGetParentsByGroupIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userGetParentsByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetParentsByGroupIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userGetParentsByGroupId>>> = ({ signal }) =>
    userGetParentsByGroupId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userGetParentsByGroupId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표/출석체크 학생(출결,역할,자리) 조회
 */
export const userGetUserAttendance = (
  params: UserGetUserAttendanceParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<void>({ url: `/api/users/attendance`, method: 'get', params, signal }, options);
};

export const getUserGetUserAttendanceQueryKey = (params: UserGetUserAttendanceParams) => [
  `/api/users/attendance`,
  ...(params ? [params] : []),
];

export type UserGetUserAttendanceQueryResult = NonNullable<Awaited<ReturnType<typeof userGetUserAttendance>>>;
export type UserGetUserAttendanceQueryError = ErrorType<unknown>;

export const useUserGetUserAttendance = <
  TData = Awaited<ReturnType<typeof userGetUserAttendance>>,
  TError = ErrorType<unknown>,
>(
  params: UserGetUserAttendanceParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userGetUserAttendance>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetUserAttendanceQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userGetUserAttendance>>> = ({ signal }) =>
    userGetUserAttendance(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userGetUserAttendance>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const userGetAttendanceBook = (
  params: UserGetAttendanceBookParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<void>({ url: `/api/users/attendancebook`, method: 'get', params, signal }, options);
};

export const getUserGetAttendanceBookQueryKey = (params: UserGetAttendanceBookParams) => [
  `/api/users/attendancebook`,
  ...(params ? [params] : []),
];

export type UserGetAttendanceBookQueryResult = NonNullable<Awaited<ReturnType<typeof userGetAttendanceBook>>>;
export type UserGetAttendanceBookQueryError = ErrorType<unknown>;

export const useUserGetAttendanceBook = <
  TData = Awaited<ReturnType<typeof userGetAttendanceBook>>,
  TError = ErrorType<unknown>,
>(
  params: UserGetAttendanceBookParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userGetAttendanceBook>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetAttendanceBookQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userGetAttendanceBook>>> = ({ signal }) =>
    userGetAttendanceBook(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userGetAttendanceBook>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 토큰 재발급
 */
export const userRefreshToken = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<AuthResponse>({ url: `/api/users/refresh-token`, method: 'get', signal }, options);
};

export const getUserRefreshTokenQueryKey = () => [`/api/users/refresh-token`];

export type UserRefreshTokenQueryResult = NonNullable<Awaited<ReturnType<typeof userRefreshToken>>>;
export type UserRefreshTokenQueryError = ErrorType<unknown>;

export const useUserRefreshToken = <
  TData = Awaited<ReturnType<typeof userRefreshToken>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userRefreshToken>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserRefreshTokenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userRefreshToken>>> = ({ signal }) =>
    userRefreshToken(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userRefreshToken>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary SSO token 생성/조회
 */
export const userSsoToken = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<string>({ url: `/api/users/sso-token`, method: 'get', signal }, options);
};

export const getUserSsoTokenQueryKey = () => [`/api/users/sso-token`];

export type UserSsoTokenQueryResult = NonNullable<Awaited<ReturnType<typeof userSsoToken>>>;
export type UserSsoTokenQueryError = ErrorType<unknown>;

export const useUserSsoToken = <
  TData = Awaited<ReturnType<typeof userSsoToken>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userSsoToken>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserSsoTokenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userSsoToken>>> = ({ signal }) =>
    userSsoToken(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userSsoToken>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 부모 회원가입때 자녀 조회
 */
export const userGetUser = (uuid: string, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseUserDto>({ url: `/api/users/${uuid}`, method: 'get', signal }, options);
};

export const getUserGetUserQueryKey = (uuid: string) => [`/api/users/${uuid}`];

export type UserGetUserQueryResult = NonNullable<Awaited<ReturnType<typeof userGetUser>>>;
export type UserGetUserQueryError = ErrorType<unknown>;

export const useUserGetUser = <TData = Awaited<ReturnType<typeof userGetUser>>, TError = ErrorType<unknown>>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userGetUser>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetUserQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userGetUser>>> = ({ signal }) =>
    userGetUser(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userGetUser>>, TError, TData>(queryKey, queryFn, {
    enabled: !!uuid,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 메시지 전송
 */
export const chatCreateMessage = (
  id: number,
  requestCreateChatMessageDto: RequestCreateChatMessageDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<number>(
    {
      url: `/api/chat/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateChatMessageDto,
    },
    options,
  );
};

export type ChatCreateMessageMutationResult = NonNullable<Awaited<ReturnType<typeof chatCreateMessage>>>;
export type ChatCreateMessageMutationBody = RequestCreateChatMessageDto;
export type ChatCreateMessageMutationError = ErrorType<unknown>;

export const useChatCreateMessage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatCreateMessage>>,
    TError,
    { id: number; data: RequestCreateChatMessageDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatCreateMessage>>,
    { id: number; data: RequestCreateChatMessageDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return chatCreateMessage(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatCreateMessage>>,
    TError,
    { id: number; data: RequestCreateChatMessageDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 메시지 전송 취소
 */
export const chatDeleteMessage = (id: number, chatid: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<number>({ url: `/api/chat/${id}/chat/${chatid}`, method: 'delete' }, options);
};

export type ChatDeleteMessageMutationResult = NonNullable<Awaited<ReturnType<typeof chatDeleteMessage>>>;

export type ChatDeleteMessageMutationError = ErrorType<unknown>;

export const useChatDeleteMessage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatDeleteMessage>>,
    TError,
    { id: number; chatid: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof chatDeleteMessage>>, { id: number; chatid: number }> = (
    props,
  ) => {
    const { id, chatid } = props ?? {};

    return chatDeleteMessage(id, chatid, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof chatDeleteMessage>>, TError, { id: number; chatid: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 내가 속한 채팅방 리스트 조회
 */
export const chatroomGetChatroomInfo = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseChatroomInfoDto[]>({ url: `/api/chatroom`, method: 'get', signal }, options);
};

export const getChatroomGetChatroomInfoQueryKey = () => [`/api/chatroom`];

export type ChatroomGetChatroomInfoQueryResult = NonNullable<Awaited<ReturnType<typeof chatroomGetChatroomInfo>>>;
export type ChatroomGetChatroomInfoQueryError = ErrorType<unknown>;

export const useChatroomGetChatroomInfo = <
  TData = Awaited<ReturnType<typeof chatroomGetChatroomInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomGetChatroomInfo>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomGetChatroomInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomGetChatroomInfo>>> = ({ signal }) =>
    chatroomGetChatroomInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomGetChatroomInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 채팅방 생성
 */
export const chatroomCreateChatRoom = (
  requestCreateChatroomDto: RequestCreateChatroomDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<number>(
    {
      url: `/api/chatroom`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateChatroomDto,
    },
    options,
  );
};

export type ChatroomCreateChatRoomMutationResult = NonNullable<Awaited<ReturnType<typeof chatroomCreateChatRoom>>>;
export type ChatroomCreateChatRoomMutationBody = RequestCreateChatroomDto;
export type ChatroomCreateChatRoomMutationError = ErrorType<unknown>;

export const useChatroomCreateChatRoom = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomCreateChatRoom>>,
    TError,
    { data: RequestCreateChatroomDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatroomCreateChatRoom>>,
    { data: RequestCreateChatroomDto }
  > = (props) => {
    const { data } = props ?? {};

    return chatroomCreateChatRoom(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatroomCreateChatRoom>>,
    TError,
    { data: RequestCreateChatroomDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 채팅방 인원 초대
 */
export const chatroomAddUser = (
  id: number,
  requestAddUsersChatroomDto: RequestAddUsersChatroomDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<number[]>(
    {
      url: `/api/chatroom/${id}/user`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestAddUsersChatroomDto,
    },
    options,
  );
};

export type ChatroomAddUserMutationResult = NonNullable<Awaited<ReturnType<typeof chatroomAddUser>>>;
export type ChatroomAddUserMutationBody = RequestAddUsersChatroomDto;
export type ChatroomAddUserMutationError = ErrorType<unknown>;

export const useChatroomAddUser = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomAddUser>>,
    TError,
    { id: number; data: RequestAddUsersChatroomDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatroomAddUser>>,
    { id: number; data: RequestAddUsersChatroomDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return chatroomAddUser(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatroomAddUser>>,
    TError,
    { id: number; data: RequestAddUsersChatroomDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 채팅방 내보내기
 */
export const chatroomRemoveUser = (id: number, userid: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/chatroom/${id}/user/${userid}`, method: 'delete' }, options);
};

export type ChatroomRemoveUserMutationResult = NonNullable<Awaited<ReturnType<typeof chatroomRemoveUser>>>;

export type ChatroomRemoveUserMutationError = ErrorType<unknown>;

export const useChatroomRemoveUser = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomRemoveUser>>,
    TError,
    { id: number; userid: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof chatroomRemoveUser>>, { id: number; userid: number }> = (
    props,
  ) => {
    const { id, userid } = props ?? {};

    return chatroomRemoveUser(id, userid, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof chatroomRemoveUser>>, TError, { id: number; userid: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 채팅방 나가기
 */
export const chatroomExpiredChatRoom = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/chatroom/${id}`, method: 'delete' }, options);
};

export type ChatroomExpiredChatRoomMutationResult = NonNullable<Awaited<ReturnType<typeof chatroomExpiredChatRoom>>>;

export type ChatroomExpiredChatRoomMutationError = ErrorType<unknown>;

export const useChatroomExpiredChatRoom = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof chatroomExpiredChatRoom>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof chatroomExpiredChatRoom>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return chatroomExpiredChatRoom(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof chatroomExpiredChatRoom>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 채팅방 상세 정보 조회
 */
export const chatroomGetChatroomDetailInfo = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseChatroomInfoDetailDto[]>({ url: `/api/chatroom/${id}`, method: 'get', signal }, options);
};

export const getChatroomGetChatroomDetailInfoQueryKey = (id: number) => [`/api/chatroom/${id}`];

export type ChatroomGetChatroomDetailInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatroomGetChatroomDetailInfo>>
>;
export type ChatroomGetChatroomDetailInfoQueryError = ErrorType<unknown>;

export const useChatroomGetChatroomDetailInfo = <
  TData = Awaited<ReturnType<typeof chatroomGetChatroomDetailInfo>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomGetChatroomDetailInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomGetChatroomDetailInfoQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomGetChatroomDetailInfo>>> = ({ signal }) =>
    chatroomGetChatroomDetailInfo(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomGetChatroomDetailInfo>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 대화목록 불러오기
 */
export const chatroomGetMessageList = (
  id: number,
  params: ChatroomGetMessageListParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedChatMessageDto[]>(
    { url: `/api/chatroom/${id}/message`, method: 'get', params, signal },
    options,
  );
};

export const getChatroomGetMessageListQueryKey = (id: number, params: ChatroomGetMessageListParams) => [
  `/api/chatroom/${id}/message`,
  ...(params ? [params] : []),
];

export type ChatroomGetMessageListQueryResult = NonNullable<Awaited<ReturnType<typeof chatroomGetMessageList>>>;
export type ChatroomGetMessageListQueryError = ErrorType<unknown>;

export const useChatroomGetMessageList = <
  TData = Awaited<ReturnType<typeof chatroomGetMessageList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: ChatroomGetMessageListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomGetMessageList>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomGetMessageListQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomGetMessageList>>> = ({ signal }) =>
    chatroomGetMessageList(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomGetMessageList>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 채팅방 참가자의 읽은 정보 요청
 */
export const chatroomGetReadInfo = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ChatroomReadInfo[]>({ url: `/api/chatroom/${id}/read-info`, method: 'get', signal }, options);
};

export const getChatroomGetReadInfoQueryKey = (id: number) => [`/api/chatroom/${id}/read-info`];

export type ChatroomGetReadInfoQueryResult = NonNullable<Awaited<ReturnType<typeof chatroomGetReadInfo>>>;
export type ChatroomGetReadInfoQueryError = ErrorType<unknown>;

export const useChatroomGetReadInfo = <
  TData = Awaited<ReturnType<typeof chatroomGetReadInfo>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomGetReadInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomGetReadInfoQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomGetReadInfo>>> = ({ signal }) =>
    chatroomGetReadInfo(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomGetReadInfo>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 채팅방 상태 변경
 */
export const chatroomChangeChatroomStatus = (
  id: number,
  requestModifyChatroomStatusDto: RequestModifyChatroomStatusDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/chatroom/${id}/status`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestModifyChatroomStatusDto,
    },
    options,
  );
};

export type ChatroomChangeChatroomStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatroomChangeChatroomStatus>>
>;
export type ChatroomChangeChatroomStatusMutationBody = RequestModifyChatroomStatusDto;
export type ChatroomChangeChatroomStatusMutationError = ErrorType<unknown>;

export const useChatroomChangeChatroomStatus = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomChangeChatroomStatus>>,
    TError,
    { id: number; data: RequestModifyChatroomStatusDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatroomChangeChatroomStatus>>,
    { id: number; data: RequestModifyChatroomStatusDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return chatroomChangeChatroomStatus(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatroomChangeChatroomStatus>>,
    TError,
    { id: number; data: RequestModifyChatroomStatusDto },
    TContext
  >(mutationFn, mutationOptions);
};

export const chatroomTestJoin = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<void>({ url: `/api/chatroom/${id}/test/join`, method: 'get', signal }, options);
};

export const getChatroomTestJoinQueryKey = (id: number) => [`/api/chatroom/${id}/test/join`];

export type ChatroomTestJoinQueryResult = NonNullable<Awaited<ReturnType<typeof chatroomTestJoin>>>;
export type ChatroomTestJoinQueryError = ErrorType<unknown>;

export const useChatroomTestJoin = <TData = Awaited<ReturnType<typeof chatroomTestJoin>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomTestJoin>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomTestJoinQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomTestJoin>>> = ({ signal }) =>
    chatroomTestJoin(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomTestJoin>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const chatroomTestOut = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<void>({ url: `/api/chatroom/${id}/test/out`, method: 'get', signal }, options);
};

export const getChatroomTestOutQueryKey = (id: number) => [`/api/chatroom/${id}/test/out`];

export type ChatroomTestOutQueryResult = NonNullable<Awaited<ReturnType<typeof chatroomTestOut>>>;
export type ChatroomTestOutQueryError = ErrorType<unknown>;

export const useChatroomTestOut = <TData = Awaited<ReturnType<typeof chatroomTestOut>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomTestOut>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomTestOutQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomTestOut>>> = ({ signal }) =>
    chatroomTestOut(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomTestOut>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 채팅방 설정 수정
 */
export const chatroomUpdateChatroomInfo = (
  id: number,
  requestUpdateChatroomInfoDto: RequestUpdateChatroomInfoDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/chatroom/${id}/info`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateChatroomInfoDto,
    },
    options,
  );
};

export type ChatroomUpdateChatroomInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatroomUpdateChatroomInfo>>
>;
export type ChatroomUpdateChatroomInfoMutationBody = RequestUpdateChatroomInfoDto;
export type ChatroomUpdateChatroomInfoMutationError = ErrorType<unknown>;

export const useChatroomUpdateChatroomInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomUpdateChatroomInfo>>,
    TError,
    { id: number; data: RequestUpdateChatroomInfoDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatroomUpdateChatroomInfo>>,
    { id: number; data: RequestUpdateChatroomInfoDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return chatroomUpdateChatroomInfo(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatroomUpdateChatroomInfo>>,
    TError,
    { id: number; data: RequestUpdateChatroomInfoDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 채팅방 인원 검색
 */
export const chatroomSearchUsers = (
  id: number,
  params: ChatroomSearchUsersParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseChatAttendeeDto[]>(
    { url: `/api/chatroom/${id}/search-user`, method: 'get', params, signal },
    options,
  );
};

export const getChatroomSearchUsersQueryKey = (id: number, params: ChatroomSearchUsersParams) => [
  `/api/chatroom/${id}/search-user`,
  ...(params ? [params] : []),
];

export type ChatroomSearchUsersQueryResult = NonNullable<Awaited<ReturnType<typeof chatroomSearchUsers>>>;
export type ChatroomSearchUsersQueryError = ErrorType<unknown>;

export const useChatroomSearchUsers = <
  TData = Awaited<ReturnType<typeof chatroomSearchUsers>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: ChatroomSearchUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomSearchUsers>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomSearchUsersQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomSearchUsers>>> = ({ signal }) =>
    chatroomSearchUsers(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomSearchUsers>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 대시보드 데이터 조회
 */
export const dashboardGetDashBoardData = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseDashboardDto>({ url: `/api/dashboard`, method: 'get', signal }, options);
};

export const getDashboardGetDashBoardDataQueryKey = () => [`/api/dashboard`];

export type DashboardGetDashBoardDataQueryResult = NonNullable<Awaited<ReturnType<typeof dashboardGetDashBoardData>>>;
export type DashboardGetDashBoardDataQueryError = ErrorType<unknown>;

export const useDashboardGetDashBoardData = <
  TData = Awaited<ReturnType<typeof dashboardGetDashBoardData>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof dashboardGetDashBoardData>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDashboardGetDashBoardDataQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dashboardGetDashBoardData>>> = ({ signal }) =>
    dashboardGetDashBoardData(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof dashboardGetDashBoardData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 대시보드 데이터 조회
 */
export const dashboardGetStudentNotificationData = (
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseStudentNotificationDashboardDto>(
    { url: `/api/dashboard/student-notification`, method: 'get', signal },
    options,
  );
};

export const getDashboardGetStudentNotificationDataQueryKey = () => [`/api/dashboard/student-notification`];

export type DashboardGetStudentNotificationDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof dashboardGetStudentNotificationData>>
>;
export type DashboardGetStudentNotificationDataQueryError = ErrorType<unknown>;

export const useDashboardGetStudentNotificationData = <
  TData = Awaited<ReturnType<typeof dashboardGetStudentNotificationData>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof dashboardGetStudentNotificationData>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDashboardGetStudentNotificationDataQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dashboardGetStudentNotificationData>>> = ({ signal }) =>
    dashboardGetStudentNotificationData(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof dashboardGetStudentNotificationData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님 대시보드 데이터 조회
 */
export const dashboardGetTeacherNotificationData = (
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseTeacherNotificationDashboardDto>(
    { url: `/api/dashboard/teacher-notification`, method: 'get', signal },
    options,
  );
};

export const getDashboardGetTeacherNotificationDataQueryKey = () => [`/api/dashboard/teacher-notification`];

export type DashboardGetTeacherNotificationDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof dashboardGetTeacherNotificationData>>
>;
export type DashboardGetTeacherNotificationDataQueryError = ErrorType<unknown>;

export const useDashboardGetTeacherNotificationData = <
  TData = Awaited<ReturnType<typeof dashboardGetTeacherNotificationData>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof dashboardGetTeacherNotificationData>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDashboardGetTeacherNotificationDataQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dashboardGetTeacherNotificationData>>> = ({ signal }) =>
    dashboardGetTeacherNotificationData(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof dashboardGetTeacherNotificationData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 유형별 결재선 조회
 */
export const approvalLineGetApprovalLineByType2 = (
  params: ApprovalLineGetApprovalLineByType2Params,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseApprovalLineDto[]>(
    { url: `/api/admin/approval-line`, method: 'get', params, signal },
    options,
  );
};

export const getApprovalLineGetApprovalLineByType2QueryKey = (params: ApprovalLineGetApprovalLineByType2Params) => [
  `/api/admin/approval-line`,
  ...(params ? [params] : []),
];

export type ApprovalLineGetApprovalLineByType2QueryResult = NonNullable<
  Awaited<ReturnType<typeof approvalLineGetApprovalLineByType2>>
>;
export type ApprovalLineGetApprovalLineByType2QueryError = ErrorType<unknown>;

export const useApprovalLineGetApprovalLineByType2 = <
  TData = Awaited<ReturnType<typeof approvalLineGetApprovalLineByType2>>,
  TError = ErrorType<unknown>,
>(
  params: ApprovalLineGetApprovalLineByType2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof approvalLineGetApprovalLineByType2>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApprovalLineGetApprovalLineByType2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof approvalLineGetApprovalLineByType2>>> = ({ signal }) =>
    approvalLineGetApprovalLineByType2(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof approvalLineGetApprovalLineByType2>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 유형별 결재선 다건 생성/업데이트
 */
export const approvalLineCreateApprovalLines = (
  approvalLine: ApprovalLine[],
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/admin/approval-line`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: approvalLine,
    },
    options,
  );
};

export type ApprovalLineCreateApprovalLinesMutationResult = NonNullable<
  Awaited<ReturnType<typeof approvalLineCreateApprovalLines>>
>;
export type ApprovalLineCreateApprovalLinesMutationBody = ApprovalLine[];
export type ApprovalLineCreateApprovalLinesMutationError = ErrorType<unknown>;

export const useApprovalLineCreateApprovalLines = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvalLineCreateApprovalLines>>,
    TError,
    { data: ApprovalLine[] },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approvalLineCreateApprovalLines>>,
    { data: ApprovalLine[] }
  > = (props) => {
    const { data } = props ?? {};

    return approvalLineCreateApprovalLines(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof approvalLineCreateApprovalLines>>,
    TError,
    { data: ApprovalLine[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 결재라인 삭제
 */
export const approvalLineDeleteTeacher = (
  params: ApprovalLineDeleteTeacherParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>({ url: `/api/admin/approval-line`, method: 'delete', params }, options);
};

export type ApprovalLineDeleteTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof approvalLineDeleteTeacher>>
>;

export type ApprovalLineDeleteTeacherMutationError = ErrorType<unknown>;

export const useApprovalLineDeleteTeacher = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvalLineDeleteTeacher>>,
    TError,
    { params: ApprovalLineDeleteTeacherParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approvalLineDeleteTeacher>>,
    { params: ApprovalLineDeleteTeacherParams }
  > = (props) => {
    const { params } = props ?? {};

    return approvalLineDeleteTeacher(params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof approvalLineDeleteTeacher>>,
    TError,
    { params: ApprovalLineDeleteTeacherParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 확인증 결제중인 문서 업데이트
 */
export const approvalLineUpdateOutingApprovalLines = (options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/admin/approval-line/update/outing`, method: 'post' }, options);
};

export type ApprovalLineUpdateOutingApprovalLinesMutationResult = NonNullable<
  Awaited<ReturnType<typeof approvalLineUpdateOutingApprovalLines>>
>;

export type ApprovalLineUpdateOutingApprovalLinesMutationError = ErrorType<unknown>;

export const useApprovalLineUpdateOutingApprovalLines = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvalLineUpdateOutingApprovalLines>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approvalLineUpdateOutingApprovalLines>>,
    TVariables
  > = () => {
    return approvalLineUpdateOutingApprovalLines(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof approvalLineUpdateOutingApprovalLines>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 출결신고서 결제중인 문서 업데이트
 */
export const approvalLineUpdateabsentApprovalLines = (options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/admin/approval-line/update/absent`, method: 'post' }, options);
};

export type ApprovalLineUpdateabsentApprovalLinesMutationResult = NonNullable<
  Awaited<ReturnType<typeof approvalLineUpdateabsentApprovalLines>>
>;

export type ApprovalLineUpdateabsentApprovalLinesMutationError = ErrorType<unknown>;

export const useApprovalLineUpdateabsentApprovalLines = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvalLineUpdateabsentApprovalLines>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approvalLineUpdateabsentApprovalLines>>,
    TVariables
  > = () => {
    return approvalLineUpdateabsentApprovalLines(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof approvalLineUpdateabsentApprovalLines>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 체험학습 결제중인 문서 업데이트
 */
export const approvalLineUpdateFieldTripApprovalLines = (options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/admin/approval-line/update/fieldtrip`, method: 'post' }, options);
};

export type ApprovalLineUpdateFieldTripApprovalLinesMutationResult = NonNullable<
  Awaited<ReturnType<typeof approvalLineUpdateFieldTripApprovalLines>>
>;

export type ApprovalLineUpdateFieldTripApprovalLinesMutationError = ErrorType<unknown>;

export const useApprovalLineUpdateFieldTripApprovalLines = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvalLineUpdateFieldTripApprovalLines>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approvalLineUpdateFieldTripApprovalLines>>,
    TVariables
  > = () => {
    return approvalLineUpdateFieldTripApprovalLines(requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof approvalLineUpdateFieldTripApprovalLines>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 기본 정보 조회
 */
export const schoolManagementGetSchoolInfo = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<School>({ url: `/api/admin/school-management`, method: 'get', signal }, options);
};

export const getSchoolManagementGetSchoolInfoQueryKey = () => [`/api/admin/school-management`];

export type SchoolManagementGetSchoolInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof schoolManagementGetSchoolInfo>>
>;
export type SchoolManagementGetSchoolInfoQueryError = ErrorType<void>;

export const useSchoolManagementGetSchoolInfo = <
  TData = Awaited<ReturnType<typeof schoolManagementGetSchoolInfo>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof schoolManagementGetSchoolInfo>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolManagementGetSchoolInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolManagementGetSchoolInfo>>> = ({ signal }) =>
    schoolManagementGetSchoolInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolManagementGetSchoolInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 기본 정보 수정
 */
export const schoolManagementModifySchoolInfo = (
  requestUpdateSchoolInfoDto: RequestUpdateSchoolInfoDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<School>(
    {
      url: `/api/admin/school-management`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateSchoolInfoDto,
    },
    options,
  );
};

export type SchoolManagementModifySchoolInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof schoolManagementModifySchoolInfo>>
>;
export type SchoolManagementModifySchoolInfoMutationBody = RequestUpdateSchoolInfoDto;
export type SchoolManagementModifySchoolInfoMutationError = ErrorType<unknown>;

export const useSchoolManagementModifySchoolInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof schoolManagementModifySchoolInfo>>,
    TError,
    { data: RequestUpdateSchoolInfoDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof schoolManagementModifySchoolInfo>>,
    { data: RequestUpdateSchoolInfoDto }
  > = (props) => {
    const { data } = props ?? {};

    return schoolManagementModifySchoolInfo(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof schoolManagementModifySchoolInfo>>,
    TError,
    { data: RequestUpdateSchoolInfoDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 공휴일 불러오기
 */
export const schoolManagementLoadHolidayData = (
  params: SchoolManagementLoadHolidayDataParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<boolean>({ url: `/api/admin/school-management/holiday`, method: 'get', params, signal }, options);
};

export const getSchoolManagementLoadHolidayDataQueryKey = (params: SchoolManagementLoadHolidayDataParams) => [
  `/api/admin/school-management/holiday`,
  ...(params ? [params] : []),
];

export type SchoolManagementLoadHolidayDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof schoolManagementLoadHolidayData>>
>;
export type SchoolManagementLoadHolidayDataQueryError = ErrorType<void>;

export const useSchoolManagementLoadHolidayData = <
  TData = Awaited<ReturnType<typeof schoolManagementLoadHolidayData>>,
  TError = ErrorType<void>,
>(
  params: SchoolManagementLoadHolidayDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schoolManagementLoadHolidayData>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolManagementLoadHolidayDataQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolManagementLoadHolidayData>>> = ({ signal }) =>
    schoolManagementLoadHolidayData(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolManagementLoadHolidayData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공휴일 불러오기(연도 전체)
 */
export const schoolManagementLoadYearHolidayData = (
  params: SchoolManagementLoadYearHolidayDataParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<boolean[]>(
    { url: `/api/admin/school-management/holiday-year`, method: 'get', params, signal },
    options,
  );
};

export const getSchoolManagementLoadYearHolidayDataQueryKey = (params: SchoolManagementLoadYearHolidayDataParams) => [
  `/api/admin/school-management/holiday-year`,
  ...(params ? [params] : []),
];

export type SchoolManagementLoadYearHolidayDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof schoolManagementLoadYearHolidayData>>
>;
export type SchoolManagementLoadYearHolidayDataQueryError = ErrorType<void>;

export const useSchoolManagementLoadYearHolidayData = <
  TData = Awaited<ReturnType<typeof schoolManagementLoadYearHolidayData>>,
  TError = ErrorType<void>,
>(
  params: SchoolManagementLoadYearHolidayDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schoolManagementLoadYearHolidayData>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolManagementLoadYearHolidayDataQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolManagementLoadYearHolidayData>>> = ({ signal }) =>
    schoolManagementLoadYearHolidayData(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolManagementLoadYearHolidayData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 급식정보 불러오기
 */
export const schoolManagementLoadMealData = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<boolean>({ url: `/api/admin/school-management/meal`, method: 'get', signal }, options);
};

export const getSchoolManagementLoadMealDataQueryKey = () => [`/api/admin/school-management/meal`];

export type SchoolManagementLoadMealDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof schoolManagementLoadMealData>>
>;
export type SchoolManagementLoadMealDataQueryError = ErrorType<void>;

export const useSchoolManagementLoadMealData = <
  TData = Awaited<ReturnType<typeof schoolManagementLoadMealData>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof schoolManagementLoadMealData>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolManagementLoadMealDataQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolManagementLoadMealData>>> = ({ signal }) =>
    schoolManagementLoadMealData(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolManagementLoadMealData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary NEIS 시간표 불러오기
 */
export const schoolManagementLoadTimetableFromNeis = (
  params: SchoolManagementLoadTimetableFromNeisParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<boolean>(
    { url: `/api/admin/school-management/neis-timetable`, method: 'get', params, signal },
    options,
  );
};

export const getSchoolManagementLoadTimetableFromNeisQueryKey = (
  params: SchoolManagementLoadTimetableFromNeisParams,
) => [`/api/admin/school-management/neis-timetable`, ...(params ? [params] : [])];

export type SchoolManagementLoadTimetableFromNeisQueryResult = NonNullable<
  Awaited<ReturnType<typeof schoolManagementLoadTimetableFromNeis>>
>;
export type SchoolManagementLoadTimetableFromNeisQueryError = ErrorType<void>;

export const useSchoolManagementLoadTimetableFromNeis = <
  TData = Awaited<ReturnType<typeof schoolManagementLoadTimetableFromNeis>>,
  TError = ErrorType<void>,
>(
  params: SchoolManagementLoadTimetableFromNeisParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schoolManagementLoadTimetableFromNeis>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolManagementLoadTimetableFromNeisQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolManagementLoadTimetableFromNeis>>> = ({ signal }) =>
    schoolManagementLoadTimetableFromNeis(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolManagementLoadTimetableFromNeis>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님 정보 조회
 */
export const teacherManagementGetTeachers = (
  params?: TeacherManagementGetTeachersParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginationTeacherInfoDto>(
    { url: `/api/admin/teacher-management`, method: 'get', params, signal },
    options,
  );
};

export const getTeacherManagementGetTeachersQueryKey = (params?: TeacherManagementGetTeachersParams) => [
  `/api/admin/teacher-management`,
  ...(params ? [params] : []),
];

export type TeacherManagementGetTeachersQueryResult = NonNullable<
  Awaited<ReturnType<typeof teacherManagementGetTeachers>>
>;
export type TeacherManagementGetTeachersQueryError = ErrorType<unknown>;

export const useTeacherManagementGetTeachers = <
  TData = Awaited<ReturnType<typeof teacherManagementGetTeachers>>,
  TError = ErrorType<unknown>,
>(
  params?: TeacherManagementGetTeachersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teacherManagementGetTeachers>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeacherManagementGetTeachersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teacherManagementGetTeachers>>> = ({ signal }) =>
    teacherManagementGetTeachers(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof teacherManagementGetTeachers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님 정보 추가
 */
export const teacherManagementCreateTeacher = (
  requestCreateTeacherDto: RequestCreateTeacherDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseTeacherDto>(
    {
      url: `/api/admin/teacher-management`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateTeacherDto,
    },
    options,
  );
};

export type TeacherManagementCreateTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof teacherManagementCreateTeacher>>
>;
export type TeacherManagementCreateTeacherMutationBody = RequestCreateTeacherDto;
export type TeacherManagementCreateTeacherMutationError = ErrorType<void>;

export const useTeacherManagementCreateTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teacherManagementCreateTeacher>>,
    TError,
    { data: RequestCreateTeacherDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teacherManagementCreateTeacher>>,
    { data: RequestCreateTeacherDto }
  > = (props) => {
    const { data } = props ?? {};

    return teacherManagementCreateTeacher(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof teacherManagementCreateTeacher>>,
    TError,
    { data: RequestCreateTeacherDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 선생님 정보 상세 조회
 */
export const teacherManagementGetTeacherInfo = (
  id: number,
  params?: TeacherManagementGetTeacherInfoParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseTeacherDetailInfoDto>(
    { url: `/api/admin/teacher-management/${id}`, method: 'get', params, signal },
    options,
  );
};

export const getTeacherManagementGetTeacherInfoQueryKey = (
  id: number,
  params?: TeacherManagementGetTeacherInfoParams,
) => [`/api/admin/teacher-management/${id}`, ...(params ? [params] : [])];

export type TeacherManagementGetTeacherInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof teacherManagementGetTeacherInfo>>
>;
export type TeacherManagementGetTeacherInfoQueryError = ErrorType<void>;

export const useTeacherManagementGetTeacherInfo = <
  TData = Awaited<ReturnType<typeof teacherManagementGetTeacherInfo>>,
  TError = ErrorType<void>,
>(
  id: number,
  params?: TeacherManagementGetTeacherInfoParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teacherManagementGetTeacherInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeacherManagementGetTeacherInfoQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teacherManagementGetTeacherInfo>>> = ({ signal }) =>
    teacherManagementGetTeacherInfo(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof teacherManagementGetTeacherInfo>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님 정보 삭제
 */
export const teacherManagementDeleteTeacher = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/admin/teacher-management/${id}`, method: 'delete' }, options);
};

export type TeacherManagementDeleteTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof teacherManagementDeleteTeacher>>
>;

export type TeacherManagementDeleteTeacherMutationError = ErrorType<void>;

export const useTeacherManagementDeleteTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teacherManagementDeleteTeacher>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof teacherManagementDeleteTeacher>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return teacherManagementDeleteTeacher(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof teacherManagementDeleteTeacher>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 선생님 정보 수정
 */
export const teacherManagementUpdateTeacher = (
  id: number,
  requestModifyTeacherDto: RequestModifyTeacherDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseTeacherDetailInfoDto>(
    {
      url: `/api/admin/teacher-management/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestModifyTeacherDto,
    },
    options,
  );
};

export type TeacherManagementUpdateTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof teacherManagementUpdateTeacher>>
>;
export type TeacherManagementUpdateTeacherMutationBody = RequestModifyTeacherDto;
export type TeacherManagementUpdateTeacherMutationError = ErrorType<void>;

export const useTeacherManagementUpdateTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teacherManagementUpdateTeacher>>,
    TError,
    { id: number; data: RequestModifyTeacherDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teacherManagementUpdateTeacher>>,
    { id: number; data: RequestModifyTeacherDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return teacherManagementUpdateTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof teacherManagementUpdateTeacher>>,
    TError,
    { id: number; data: RequestModifyTeacherDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 선생님 정보 추가(다건)
 */
export const teacherManagementBulkCreateTeacher = (
  requestCreateTeacherDto: RequestCreateTeacherDto[],
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/admin/teacher-management/bulk`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateTeacherDto,
    },
    options,
  );
};

export type TeacherManagementBulkCreateTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof teacherManagementBulkCreateTeacher>>
>;
export type TeacherManagementBulkCreateTeacherMutationBody = RequestCreateTeacherDto[];
export type TeacherManagementBulkCreateTeacherMutationError = ErrorType<void>;

export const useTeacherManagementBulkCreateTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teacherManagementBulkCreateTeacher>>,
    TError,
    { data: RequestCreateTeacherDto[] },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teacherManagementBulkCreateTeacher>>,
    { data: RequestCreateTeacherDto[] }
  > = (props) => {
    const { data } = props ?? {};

    return teacherManagementBulkCreateTeacher(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof teacherManagementBulkCreateTeacher>>,
    TError,
    { data: RequestCreateTeacherDto[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가입요청 메일 전송(선생님)
 */
export const teacherManagementRequestSignUp = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/admin/teacher-management/${id}/send-request-signup`, method: 'post' }, options);
};

export type TeacherManagementRequestSignUpMutationResult = NonNullable<
  Awaited<ReturnType<typeof teacherManagementRequestSignUp>>
>;

export type TeacherManagementRequestSignUpMutationError = ErrorType<void>;

export const useTeacherManagementRequestSignUp = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teacherManagementRequestSignUp>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof teacherManagementRequestSignUp>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return teacherManagementRequestSignUp(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof teacherManagementRequestSignUp>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학생 정보 조회
 */
export const studentManagementGetStudents = (
  params?: StudentManagementGetStudentsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginationStudentInfoDto>(
    { url: `/api/admin/student-management`, method: 'get', params, signal },
    options,
  );
};

export const getStudentManagementGetStudentsQueryKey = (params?: StudentManagementGetStudentsParams) => [
  `/api/admin/student-management`,
  ...(params ? [params] : []),
];

export type StudentManagementGetStudentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementGetStudents>>
>;
export type StudentManagementGetStudentsQueryError = ErrorType<unknown>;

export const useStudentManagementGetStudents = <
  TData = Awaited<ReturnType<typeof studentManagementGetStudents>>,
  TError = ErrorType<unknown>,
>(
  params?: StudentManagementGetStudentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentManagementGetStudents>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentManagementGetStudentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentManagementGetStudents>>> = ({ signal }) =>
    studentManagementGetStudents(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentManagementGetStudents>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 정보 추가
 */
export const studentManagementCreateStudent = (
  requestCreateStudentDto: RequestCreateStudentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseStudentInfoDto>(
    {
      url: `/api/admin/student-management`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateStudentDto,
    },
    options,
  );
};

export type StudentManagementCreateStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementCreateStudent>>
>;
export type StudentManagementCreateStudentMutationBody = RequestCreateStudentDto;
export type StudentManagementCreateStudentMutationError = ErrorType<void>;

export const useStudentManagementCreateStudent = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentManagementCreateStudent>>,
    TError,
    { data: RequestCreateStudentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentManagementCreateStudent>>,
    { data: RequestCreateStudentDto }
  > = (props) => {
    const { data } = props ?? {};

    return studentManagementCreateStudent(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentManagementCreateStudent>>,
    TError,
    { data: RequestCreateStudentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 정보 상세 조회
 */
export const studentManagementGetStudentInfo = (
  id: number,
  params?: StudentManagementGetStudentInfoParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseStudentDetailInfoDto>(
    { url: `/api/admin/student-management/${id}`, method: 'get', params, signal },
    options,
  );
};

export const getStudentManagementGetStudentInfoQueryKey = (
  id: number,
  params?: StudentManagementGetStudentInfoParams,
) => [`/api/admin/student-management/${id}`, ...(params ? [params] : [])];

export type StudentManagementGetStudentInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementGetStudentInfo>>
>;
export type StudentManagementGetStudentInfoQueryError = ErrorType<void>;

export const useStudentManagementGetStudentInfo = <
  TData = Awaited<ReturnType<typeof studentManagementGetStudentInfo>>,
  TError = ErrorType<void>,
>(
  id: number,
  params?: StudentManagementGetStudentInfoParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentManagementGetStudentInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentManagementGetStudentInfoQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentManagementGetStudentInfo>>> = ({ signal }) =>
    studentManagementGetStudentInfo(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentManagementGetStudentInfo>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 정보 삭제
 */
export const studentManagementDeleteStudent = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/admin/student-management/${id}`, method: 'delete' }, options);
};

export type StudentManagementDeleteStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementDeleteStudent>>
>;

export type StudentManagementDeleteStudentMutationError = ErrorType<void>;

export const useStudentManagementDeleteStudent = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentManagementDeleteStudent>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof studentManagementDeleteStudent>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return studentManagementDeleteStudent(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof studentManagementDeleteStudent>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학생 정보 수정
 */
export const studentManagementUpdateStudent = (
  id: number,
  requestModifyStudentDto: RequestModifyStudentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseStudentDetailInfoDto>(
    {
      url: `/api/admin/student-management/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestModifyStudentDto,
    },
    options,
  );
};

export type StudentManagementUpdateStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementUpdateStudent>>
>;
export type StudentManagementUpdateStudentMutationBody = RequestModifyStudentDto;
export type StudentManagementUpdateStudentMutationError = ErrorType<void>;

export const useStudentManagementUpdateStudent = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentManagementUpdateStudent>>,
    TError,
    { id: number; data: RequestModifyStudentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentManagementUpdateStudent>>,
    { id: number; data: RequestModifyStudentDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentManagementUpdateStudent(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentManagementUpdateStudent>>,
    TError,
    { id: number; data: RequestModifyStudentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 정보 추가(다건)
 */
export const studentManagementBulkCreateStudent = (
  requestCreateStudentDto: RequestCreateStudentDto[],
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/admin/student-management/bulk`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateStudentDto,
    },
    options,
  );
};

export type StudentManagementBulkCreateStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementBulkCreateStudent>>
>;
export type StudentManagementBulkCreateStudentMutationBody = RequestCreateStudentDto[];
export type StudentManagementBulkCreateStudentMutationError = ErrorType<void>;

export const useStudentManagementBulkCreateStudent = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentManagementBulkCreateStudent>>,
    TError,
    { data: RequestCreateStudentDto[] },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentManagementBulkCreateStudent>>,
    { data: RequestCreateStudentDto[] }
  > = (props) => {
    const { data } = props ?? {};

    return studentManagementBulkCreateStudent(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentManagementBulkCreateStudent>>,
    TError,
    { data: RequestCreateStudentDto[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가입요청 메일 전송(학생)
 */
export const studentManagementRequestSignUp = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/admin/student-management/${id}/send-request-signup`, method: 'post' }, options);
};

export type StudentManagementRequestSignUpMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementRequestSignUp>>
>;

export type StudentManagementRequestSignUpMutationError = ErrorType<void>;

export const useStudentManagementRequestSignUp = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentManagementRequestSignUp>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof studentManagementRequestSignUp>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return studentManagementRequestSignUp(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof studentManagementRequestSignUp>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학생 진급 요청
 */
export const studentManagementAdvancedStudent = (
  requestAdvancedStudentDto: RequestAdvancedStudentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseStudentInfoDto>(
    {
      url: `/api/admin/student-management/advanced-student`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestAdvancedStudentDto,
    },
    options,
  );
};

export type StudentManagementAdvancedStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentManagementAdvancedStudent>>
>;
export type StudentManagementAdvancedStudentMutationBody = RequestAdvancedStudentDto;
export type StudentManagementAdvancedStudentMutationError = ErrorType<void>;

export const useStudentManagementAdvancedStudent = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentManagementAdvancedStudent>>,
    TError,
    { data: RequestAdvancedStudentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentManagementAdvancedStudent>>,
    { data: RequestAdvancedStudentDto }
  > = (props) => {
    const { data } = props ?? {};

    return studentManagementAdvancedStudent(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentManagementAdvancedStudent>>,
    TError,
    { data: RequestAdvancedStudentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 보호자 정보 조회
 */
export const parentManagementGetParents = (
  params?: ParentManagementGetParentsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginationParentInfoDto>(
    { url: `/api/admin/parent-management`, method: 'get', params, signal },
    options,
  );
};

export const getParentManagementGetParentsQueryKey = (params?: ParentManagementGetParentsParams) => [
  `/api/admin/parent-management`,
  ...(params ? [params] : []),
];

export type ParentManagementGetParentsQueryResult = NonNullable<Awaited<ReturnType<typeof parentManagementGetParents>>>;
export type ParentManagementGetParentsQueryError = ErrorType<unknown>;

export const useParentManagementGetParents = <
  TData = Awaited<ReturnType<typeof parentManagementGetParents>>,
  TError = ErrorType<unknown>,
>(
  params?: ParentManagementGetParentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof parentManagementGetParents>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getParentManagementGetParentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof parentManagementGetParents>>> = ({ signal }) =>
    parentManagementGetParents(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof parentManagementGetParents>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 보호자 정보 상세 조회
 */
export const parentManagementGetParentInfo = (
  id: number,
  params?: ParentManagementGetParentInfoParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseParentDetailInfoDto>(
    { url: `/api/admin/parent-management/${id}`, method: 'get', params, signal },
    options,
  );
};

export const getParentManagementGetParentInfoQueryKey = (id: number, params?: ParentManagementGetParentInfoParams) => [
  `/api/admin/parent-management/${id}`,
  ...(params ? [params] : []),
];

export type ParentManagementGetParentInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof parentManagementGetParentInfo>>
>;
export type ParentManagementGetParentInfoQueryError = ErrorType<void>;

export const useParentManagementGetParentInfo = <
  TData = Awaited<ReturnType<typeof parentManagementGetParentInfo>>,
  TError = ErrorType<void>,
>(
  id: number,
  params?: ParentManagementGetParentInfoParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof parentManagementGetParentInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getParentManagementGetParentInfoQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof parentManagementGetParentInfo>>> = ({ signal }) =>
    parentManagementGetParentInfo(id, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof parentManagementGetParentInfo>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 보호자 정보 삭제
 */
export const parentManagementDeleteParent = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/admin/parent-management/${id}`, method: 'delete' }, options);
};

export type ParentManagementDeleteParentMutationResult = NonNullable<
  Awaited<ReturnType<typeof parentManagementDeleteParent>>
>;

export type ParentManagementDeleteParentMutationError = ErrorType<void>;

export const useParentManagementDeleteParent = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof parentManagementDeleteParent>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof parentManagementDeleteParent>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return parentManagementDeleteParent(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof parentManagementDeleteParent>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 보호자 정보 수정
 */
export const parentManagementUpdateParent = (
  id: number,
  requestModifyParentDto: RequestModifyParentDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseParentDetailInfoDto>(
    {
      url: `/api/admin/parent-management/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestModifyParentDto,
    },
    options,
  );
};

export type ParentManagementUpdateParentMutationResult = NonNullable<
  Awaited<ReturnType<typeof parentManagementUpdateParent>>
>;
export type ParentManagementUpdateParentMutationBody = RequestModifyParentDto;
export type ParentManagementUpdateParentMutationError = ErrorType<void>;

export const useParentManagementUpdateParent = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof parentManagementUpdateParent>>,
    TError,
    { id: number; data: RequestModifyParentDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof parentManagementUpdateParent>>,
    { id: number; data: RequestModifyParentDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return parentManagementUpdateParent(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof parentManagementUpdateParent>>,
    TError,
    { id: number; data: RequestModifyParentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학급 정보 조회
 */
export const klassManagementGetKlassInfo = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseKlassInfoDto>({ url: `/api/admin/klass-management/${id}`, method: 'get', signal }, options);
};

export const getKlassManagementGetKlassInfoQueryKey = (id: number) => [`/api/admin/klass-management/${id}`];

export type KlassManagementGetKlassInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof klassManagementGetKlassInfo>>
>;
export type KlassManagementGetKlassInfoQueryError = ErrorType<void>;

export const useKlassManagementGetKlassInfo = <
  TData = Awaited<ReturnType<typeof klassManagementGetKlassInfo>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof klassManagementGetKlassInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getKlassManagementGetKlassInfoQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof klassManagementGetKlassInfo>>> = ({ signal }) =>
    klassManagementGetKlassInfo(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof klassManagementGetKlassInfo>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 삭제
 */
export const klassManagementDeleteKlass = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/admin/klass-management/${id}`, method: 'delete' }, options);
};

export type KlassManagementDeleteKlassMutationResult = NonNullable<
  Awaited<ReturnType<typeof klassManagementDeleteKlass>>
>;

export type KlassManagementDeleteKlassMutationError = ErrorType<void>;

export const useKlassManagementDeleteKlass = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof klassManagementDeleteKlass>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof klassManagementDeleteKlass>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return klassManagementDeleteKlass(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof klassManagementDeleteKlass>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 담임 선생님 변경
 */
export const klassManagementChangeHomeroomTeacher = (
  id: number,
  requestChangeHomeroomTeacherDto: RequestChangeHomeroomTeacherDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseKlassInfoDto>(
    {
      url: `/api/admin/klass-management/${id}/homeroom-teacher`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestChangeHomeroomTeacherDto,
    },
    options,
  );
};

export type KlassManagementChangeHomeroomTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof klassManagementChangeHomeroomTeacher>>
>;
export type KlassManagementChangeHomeroomTeacherMutationBody = RequestChangeHomeroomTeacherDto;
export type KlassManagementChangeHomeroomTeacherMutationError = ErrorType<void>;

export const useKlassManagementChangeHomeroomTeacher = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof klassManagementChangeHomeroomTeacher>>,
    TError,
    { id: number; data: RequestChangeHomeroomTeacherDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof klassManagementChangeHomeroomTeacher>>,
    { id: number; data: RequestChangeHomeroomTeacherDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return klassManagementChangeHomeroomTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof klassManagementChangeHomeroomTeacher>>,
    TError,
    { id: number; data: RequestChangeHomeroomTeacherDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학급 추가
 */
export const klassManagementCreateKlass = (
  requestCreateKlassDto: RequestCreateKlassDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Group>(
    {
      url: `/api/admin/klass-management`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateKlassDto,
    },
    options,
  );
};

export type KlassManagementCreateKlassMutationResult = NonNullable<
  Awaited<ReturnType<typeof klassManagementCreateKlass>>
>;
export type KlassManagementCreateKlassMutationBody = RequestCreateKlassDto;
export type KlassManagementCreateKlassMutationError = ErrorType<void>;

export const useKlassManagementCreateKlass = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof klassManagementCreateKlass>>,
    TError,
    { data: RequestCreateKlassDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof klassManagementCreateKlass>>,
    { data: RequestCreateKlassDto }
  > = (props) => {
    const { data } = props ?? {};

    return klassManagementCreateKlass(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof klassManagementCreateKlass>>,
    TError,
    { data: RequestCreateKlassDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 그룹 목록 조회
 */
export const groupManagementGetGroupList = (
  params?: GroupManagementGetGroupListParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Group[]>({ url: `/api/admin/group-management`, method: 'get', params, signal }, options);
};

export const getGroupManagementGetGroupListQueryKey = (params?: GroupManagementGetGroupListParams) => [
  `/api/admin/group-management`,
  ...(params ? [params] : []),
];

export type GroupManagementGetGroupListQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementGetGroupList>>
>;
export type GroupManagementGetGroupListQueryError = ErrorType<void>;

export const useGroupManagementGetGroupList = <
  TData = Awaited<ReturnType<typeof groupManagementGetGroupList>>,
  TError = ErrorType<void>,
>(
  params?: GroupManagementGetGroupListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupManagementGetGroupList>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupManagementGetGroupListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupManagementGetGroupList>>> = ({ signal }) =>
    groupManagementGetGroupList(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupManagementGetGroupList>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 추가
 */
export const groupManagementCreateGroup = (
  requestCreateGroupOnlyDto: RequestCreateGroupOnlyDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Group>(
    {
      url: `/api/admin/group-management`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateGroupOnlyDto,
    },
    options,
  );
};

export type GroupManagementCreateGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementCreateGroup>>
>;
export type GroupManagementCreateGroupMutationBody = RequestCreateGroupOnlyDto;
export type GroupManagementCreateGroupMutationError = ErrorType<ResponseErrorDto>;

export const useGroupManagementCreateGroup = <TError = ErrorType<ResponseErrorDto>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupManagementCreateGroup>>,
    TError,
    { data: RequestCreateGroupOnlyDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupManagementCreateGroup>>,
    { data: RequestCreateGroupOnlyDto }
  > = (props) => {
    const { data } = props ?? {};

    return groupManagementCreateGroup(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupManagementCreateGroup>>,
    TError,
    { data: RequestCreateGroupOnlyDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 그룹 상세 조회
 */
export const groupManagementGetGroupDetailInfo = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseGroupDetailInfoDto>(
    { url: `/api/admin/group-management/${id}`, method: 'get', signal },
    options,
  );
};

export const getGroupManagementGetGroupDetailInfoQueryKey = (id: number) => [`/api/admin/group-management/${id}`];

export type GroupManagementGetGroupDetailInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementGetGroupDetailInfo>>
>;
export type GroupManagementGetGroupDetailInfoQueryError = ErrorType<void>;

export const useGroupManagementGetGroupDetailInfo = <
  TData = Awaited<ReturnType<typeof groupManagementGetGroupDetailInfo>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupManagementGetGroupDetailInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupManagementGetGroupDetailInfoQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupManagementGetGroupDetailInfo>>> = ({ signal }) =>
    groupManagementGetGroupDetailInfo(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupManagementGetGroupDetailInfo>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 수정
 */
export const groupManagementUpdateGroup = (
  id: number,
  requestModifyGroupOnlyDto: RequestModifyGroupOnlyDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<void>(
    {
      url: `/api/admin/group-management/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestModifyGroupOnlyDto,
    },
    options,
  );
};

export type GroupManagementUpdateGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementUpdateGroup>>
>;
export type GroupManagementUpdateGroupMutationBody = RequestModifyGroupOnlyDto;
export type GroupManagementUpdateGroupMutationError = ErrorType<unknown>;

export const useGroupManagementUpdateGroup = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupManagementUpdateGroup>>,
    TError,
    { id: number; data: RequestModifyGroupOnlyDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupManagementUpdateGroup>>,
    { id: number; data: RequestModifyGroupOnlyDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return groupManagementUpdateGroup(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupManagementUpdateGroup>>,
    TError,
    { id: number; data: RequestModifyGroupOnlyDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 그룹 삭제
 */
export const groupManagementDeleteGroup = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/admin/group-management/${id}/group`, method: 'delete' }, options);
};

export type GroupManagementDeleteGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementDeleteGroup>>
>;

export type GroupManagementDeleteGroupMutationError = ErrorType<unknown>;

export const useGroupManagementDeleteGroup = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupManagementDeleteGroup>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupManagementDeleteGroup>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return groupManagementDeleteGroup(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof groupManagementDeleteGroup>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 선생님 추가
 */
export const groupManagementAddTeachers = (
  id: number,
  requestGroupTeachersDto: RequestGroupTeachersDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/admin/group-management/${id}/teacher`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestGroupTeachersDto,
    },
    options,
  );
};

export type GroupManagementAddTeachersMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementAddTeachers>>
>;
export type GroupManagementAddTeachersMutationBody = RequestGroupTeachersDto;
export type GroupManagementAddTeachersMutationError = ErrorType<unknown>;

export const useGroupManagementAddTeachers = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupManagementAddTeachers>>,
    TError,
    { id: number; data: RequestGroupTeachersDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupManagementAddTeachers>>,
    { id: number; data: RequestGroupTeachersDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return groupManagementAddTeachers(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupManagementAddTeachers>>,
    TError,
    { id: number; data: RequestGroupTeachersDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 선생님 삭제
 */
export const groupManagementDeleteTeacher = (
  id: number,
  teacherId: number,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>({ url: `/api/admin/group-management/${id}/teacher/${teacherId}`, method: 'delete' }, options);
};

export type GroupManagementDeleteTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementDeleteTeacher>>
>;

export type GroupManagementDeleteTeacherMutationError = ErrorType<unknown>;

export const useGroupManagementDeleteTeacher = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupManagementDeleteTeacher>>,
    TError,
    { id: number; teacherId: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupManagementDeleteTeacher>>,
    { id: number; teacherId: number }
  > = (props) => {
    const { id, teacherId } = props ?? {};

    return groupManagementDeleteTeacher(id, teacherId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupManagementDeleteTeacher>>,
    TError,
    { id: number; teacherId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 추가
 */
export const groupManagementAddStudent = (
  id: number,
  requestGroupStudentsDto: RequestGroupStudentsDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/admin/group-management/${id}/student`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestGroupStudentsDto,
    },
    options,
  );
};

export type GroupManagementAddStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementAddStudent>>
>;
export type GroupManagementAddStudentMutationBody = RequestGroupStudentsDto;
export type GroupManagementAddStudentMutationError = ErrorType<unknown>;

export const useGroupManagementAddStudent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupManagementAddStudent>>,
    TError,
    { id: number; data: RequestGroupStudentsDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupManagementAddStudent>>,
    { id: number; data: RequestGroupStudentsDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return groupManagementAddStudent(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupManagementAddStudent>>,
    TError,
    { id: number; data: RequestGroupStudentsDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 삭제
 */
export const groupManagementDeleteStudent = (
  id: number,
  studentId: number,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>({ url: `/api/admin/group-management/${id}/student/${studentId}`, method: 'delete' }, options);
};

export type GroupManagementDeleteStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupManagementDeleteStudent>>
>;

export type GroupManagementDeleteStudentMutationError = ErrorType<unknown>;

export const useGroupManagementDeleteStudent = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupManagementDeleteStudent>>,
    TError,
    { id: number; studentId: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupManagementDeleteStudent>>,
    { id: number; studentId: number }
  > = (props) => {
    const { id, studentId } = props ?? {};

    return groupManagementDeleteStudent(id, studentId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupManagementDeleteStudent>>,
    TError,
    { id: number; studentId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 선생님 목록 검색
 */
export const adminCommonSearchTeachers = (
  params?: AdminCommonSearchTeachersParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginationTeacherInfoDto>(
    { url: `/api/admin/common/search/teacher`, method: 'get', params, signal },
    options,
  );
};

export const getAdminCommonSearchTeachersQueryKey = (params?: AdminCommonSearchTeachersParams) => [
  `/api/admin/common/search/teacher`,
  ...(params ? [params] : []),
];

export type AdminCommonSearchTeachersQueryResult = NonNullable<Awaited<ReturnType<typeof adminCommonSearchTeachers>>>;
export type AdminCommonSearchTeachersQueryError = ErrorType<unknown>;

export const useAdminCommonSearchTeachers = <
  TData = Awaited<ReturnType<typeof adminCommonSearchTeachers>>,
  TError = ErrorType<unknown>,
>(
  params?: AdminCommonSearchTeachersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof adminCommonSearchTeachers>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminCommonSearchTeachersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCommonSearchTeachers>>> = ({ signal }) =>
    adminCommonSearchTeachers(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof adminCommonSearchTeachers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 목록 검색
 */
export const adminCommonSearchStudents = (
  params?: AdminCommonSearchStudentsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginationStudentInfoDto>(
    { url: `/api/admin/common/search/student`, method: 'get', params, signal },
    options,
  );
};

export const getAdminCommonSearchStudentsQueryKey = (params?: AdminCommonSearchStudentsParams) => [
  `/api/admin/common/search/student`,
  ...(params ? [params] : []),
];

export type AdminCommonSearchStudentsQueryResult = NonNullable<Awaited<ReturnType<typeof adminCommonSearchStudents>>>;
export type AdminCommonSearchStudentsQueryError = ErrorType<unknown>;

export const useAdminCommonSearchStudents = <
  TData = Awaited<ReturnType<typeof adminCommonSearchStudents>>,
  TError = ErrorType<unknown>,
>(
  params?: AdminCommonSearchStudentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof adminCommonSearchStudents>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminCommonSearchStudentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCommonSearchStudents>>> = ({ signal }) =>
    adminCommonSearchStudents(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof adminCommonSearchStudents>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학교의 klass 그룹 조회
 */
export const adminCommonFindAllKlassBySchool = (
  params?: AdminCommonFindAllKlassBySchoolParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseGroupDto[]>({ url: `/api/admin/common/search/klass`, method: 'get', params, signal }, options);
};

export const getAdminCommonFindAllKlassBySchoolQueryKey = (params?: AdminCommonFindAllKlassBySchoolParams) => [
  `/api/admin/common/search/klass`,
  ...(params ? [params] : []),
];

export type AdminCommonFindAllKlassBySchoolQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminCommonFindAllKlassBySchool>>
>;
export type AdminCommonFindAllKlassBySchoolQueryError = ErrorType<unknown>;

export const useAdminCommonFindAllKlassBySchool = <
  TData = Awaited<ReturnType<typeof adminCommonFindAllKlassBySchool>>,
  TError = ErrorType<unknown>,
>(
  params?: AdminCommonFindAllKlassBySchoolParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof adminCommonFindAllKlassBySchool>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminCommonFindAllKlassBySchoolQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCommonFindAllKlassBySchool>>> = ({ signal }) =>
    adminCommonFindAllKlassBySchool(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof adminCommonFindAllKlassBySchool>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학교의 선생님별 KLUB 그룹 조회
 */
export const adminCommonFindGroupByteacherBySchool = (
  params?: AdminCommonFindGroupByteacherBySchoolParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseGroupDto[]>({ url: `/api/admin/common/search/group`, method: 'get', params, signal }, options);
};

export const getAdminCommonFindGroupByteacherBySchoolQueryKey = (
  params?: AdminCommonFindGroupByteacherBySchoolParams,
) => [`/api/admin/common/search/group`, ...(params ? [params] : [])];

export type AdminCommonFindGroupByteacherBySchoolQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminCommonFindGroupByteacherBySchool>>
>;
export type AdminCommonFindGroupByteacherBySchoolQueryError = ErrorType<unknown>;

export const useAdminCommonFindGroupByteacherBySchool = <
  TData = Awaited<ReturnType<typeof adminCommonFindGroupByteacherBySchool>>,
  TError = ErrorType<unknown>,
>(
  params?: AdminCommonFindGroupByteacherBySchoolParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof adminCommonFindGroupByteacherBySchool>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminCommonFindGroupByteacherBySchoolQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adminCommonFindGroupByteacherBySchool>>> = ({ signal }) =>
    adminCommonFindGroupByteacherBySchool(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof adminCommonFindGroupByteacherBySchool>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 시간표 조회
 */
export const timetablev3GetTimetableByGroupId = (
  groupId: number,
  params: Timetablev3GetTimetableByGroupIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseTimetableV3Dto[]>(
    { url: `/api/timetable-v3/group/${groupId}`, method: 'get', params, signal },
    options,
  );
};

export const getTimetablev3GetTimetableByGroupIdQueryKey = (
  groupId: number,
  params: Timetablev3GetTimetableByGroupIdParams,
) => [`/api/timetable-v3/group/${groupId}`, ...(params ? [params] : [])];

export type Timetablev3GetTimetableByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablev3GetTimetableByGroupId>>
>;
export type Timetablev3GetTimetableByGroupIdQueryError = ErrorType<void>;

export const useTimetablev3GetTimetableByGroupId = <
  TData = Awaited<ReturnType<typeof timetablev3GetTimetableByGroupId>>,
  TError = ErrorType<void>,
>(
  groupId: number,
  params: Timetablev3GetTimetableByGroupIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablev3GetTimetableByGroupId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablev3GetTimetableByGroupIdQueryKey(groupId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablev3GetTimetableByGroupId>>> = ({ signal }) =>
    timetablev3GetTimetableByGroupId(groupId, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablev3GetTimetableByGroupId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 시간표 조회
 */
export const timetablev3GetTimetableByStudentId = (
  studentId: number,
  params: Timetablev3GetTimetableByStudentIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseTimetableV3Dto[]>(
    { url: `/api/timetable-v3/student/${studentId}`, method: 'get', params, signal },
    options,
  );
};

export const getTimetablev3GetTimetableByStudentIdQueryKey = (
  studentId: number,
  params: Timetablev3GetTimetableByStudentIdParams,
) => [`/api/timetable-v3/student/${studentId}`, ...(params ? [params] : [])];

export type Timetablev3GetTimetableByStudentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablev3GetTimetableByStudentId>>
>;
export type Timetablev3GetTimetableByStudentIdQueryError = ErrorType<void>;

export const useTimetablev3GetTimetableByStudentId = <
  TData = Awaited<ReturnType<typeof timetablev3GetTimetableByStudentId>>,
  TError = ErrorType<void>,
>(
  studentId: number,
  params: Timetablev3GetTimetableByStudentIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablev3GetTimetableByStudentId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablev3GetTimetableByStudentIdQueryKey(studentId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablev3GetTimetableByStudentId>>> = ({ signal }) =>
    timetablev3GetTimetableByStudentId(studentId, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablev3GetTimetableByStudentId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!studentId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님 시간표 조회
 */
export const timetablev3GetTimetableByTeacherId = (
  teacherId: number,
  params: Timetablev3GetTimetableByTeacherIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseTimetableV3Dto[]>(
    { url: `/api/timetable-v3/teacher/${teacherId}`, method: 'get', params, signal },
    options,
  );
};

export const getTimetablev3GetTimetableByTeacherIdQueryKey = (
  teacherId: number,
  params: Timetablev3GetTimetableByTeacherIdParams,
) => [`/api/timetable-v3/teacher/${teacherId}`, ...(params ? [params] : [])];

export type Timetablev3GetTimetableByTeacherIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablev3GetTimetableByTeacherId>>
>;
export type Timetablev3GetTimetableByTeacherIdQueryError = ErrorType<void>;

export const useTimetablev3GetTimetableByTeacherId = <
  TData = Awaited<ReturnType<typeof timetablev3GetTimetableByTeacherId>>,
  TError = ErrorType<void>,
>(
  teacherId: number,
  params: Timetablev3GetTimetableByTeacherIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablev3GetTimetableByTeacherId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablev3GetTimetableByTeacherIdQueryKey(teacherId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablev3GetTimetableByTeacherId>>> = ({ signal }) =>
    timetablev3GetTimetableByTeacherId(teacherId, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablev3GetTimetableByTeacherId>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!teacherId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 메시지 전송
 */
export const smsSendMessage = (
  requestCreateSmsMessageDto: RequestCreateSmsMessageDto[],
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/sms/send`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateSmsMessageDto,
    },
    options,
  );
};

export type SmsSendMessageMutationResult = NonNullable<Awaited<ReturnType<typeof smsSendMessage>>>;
export type SmsSendMessageMutationBody = RequestCreateSmsMessageDto[];
export type SmsSendMessageMutationError = ErrorType<unknown>;

export const useSmsSendMessage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof smsSendMessage>>,
    TError,
    { data: RequestCreateSmsMessageDto[] },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof smsSendMessage>>,
    { data: RequestCreateSmsMessageDto[] }
  > = (props) => {
    const { data } = props ?? {};

    return smsSendMessage(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof smsSendMessage>>,
    TError,
    { data: RequestCreateSmsMessageDto[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 메시지 조회
 */
export const smsGetFieldtripsByTeacher = (
  params: SmsGetFieldtripsByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedSmsHistoryDto>({ url: `/api/sms`, method: 'get', params, signal }, options);
};

export const getSmsGetFieldtripsByTeacherQueryKey = (params: SmsGetFieldtripsByTeacherParams) => [
  `/api/sms`,
  ...(params ? [params] : []),
];

export type SmsGetFieldtripsByTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof smsGetFieldtripsByTeacher>>>;
export type SmsGetFieldtripsByTeacherQueryError = ErrorType<unknown>;

export const useSmsGetFieldtripsByTeacher = <
  TData = Awaited<ReturnType<typeof smsGetFieldtripsByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: SmsGetFieldtripsByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof smsGetFieldtripsByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSmsGetFieldtripsByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof smsGetFieldtripsByTeacher>>> = ({ signal }) =>
    smsGetFieldtripsByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof smsGetFieldtripsByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 메시지 단건 재전송
 */
export const smsReSendMessage = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseCreateSmsMessageDto>({ url: `/api/sms/re-send/${id}`, method: 'get', signal }, options);
};

export const getSmsReSendMessageQueryKey = (id: number) => [`/api/sms/re-send/${id}`];

export type SmsReSendMessageQueryResult = NonNullable<Awaited<ReturnType<typeof smsReSendMessage>>>;
export type SmsReSendMessageQueryError = ErrorType<void>;

export const useSmsReSendMessage = <TData = Awaited<ReturnType<typeof smsReSendMessage>>, TError = ErrorType<void>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof smsReSendMessage>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSmsReSendMessageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof smsReSendMessage>>> = ({ signal }) =>
    smsReSendMessage(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof smsReSendMessage>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 남은 횟수 조회
 */
export const smsRemainSmsCredit = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<SmsCreditRemain>({ url: `/api/sms/remain-sms-credit`, method: 'get', signal }, options);
};

export const getSmsRemainSmsCreditQueryKey = () => [`/api/sms/remain-sms-credit`];

export type SmsRemainSmsCreditQueryResult = NonNullable<Awaited<ReturnType<typeof smsRemainSmsCredit>>>;
export type SmsRemainSmsCreditQueryError = ErrorType<unknown>;

export const useSmsRemainSmsCredit = <
  TData = Awaited<ReturnType<typeof smsRemainSmsCredit>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof smsRemainSmsCredit>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSmsRemainSmsCreditQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof smsRemainSmsCredit>>> = ({ signal }) =>
    smsRemainSmsCredit(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof smsRemainSmsCredit>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 조회
 */
export const timetableManagementGetTimeTable = (
  params: TimetableManagementGetTimeTableParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseTimetableV3Dto[]>(
    { url: `/api/admin/timetable-management`, method: 'get', params, signal },
    options,
  );
};

export const getTimetableManagementGetTimeTableQueryKey = (params: TimetableManagementGetTimeTableParams) => [
  `/api/admin/timetable-management`,
  ...(params ? [params] : []),
];

export type TimetableManagementGetTimeTableQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementGetTimeTable>>
>;
export type TimetableManagementGetTimeTableQueryError = ErrorType<unknown>;

export const useTimetableManagementGetTimeTable = <
  TData = Awaited<ReturnType<typeof timetableManagementGetTimeTable>>,
  TError = ErrorType<unknown>,
>(
  params: TimetableManagementGetTimeTableParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetableManagementGetTimeTable>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetableManagementGetTimeTableQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetableManagementGetTimeTable>>> = ({ signal }) =>
    timetableManagementGetTimeTable(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetableManagementGetTimeTable>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 이동 수업 목록 조회
 */
export const timetableManagementGetMoveLectureList = (
  params: TimetableManagementGetMoveLectureListParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Lecture[]>(
    { url: `/api/admin/timetable-management/move-lecture`, method: 'get', params, signal },
    options,
  );
};

export const getTimetableManagementGetMoveLectureListQueryKey = (
  params: TimetableManagementGetMoveLectureListParams,
) => [`/api/admin/timetable-management/move-lecture`, ...(params ? [params] : [])];

export type TimetableManagementGetMoveLectureListQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementGetMoveLectureList>>
>;
export type TimetableManagementGetMoveLectureListQueryError = ErrorType<unknown>;

export const useTimetableManagementGetMoveLectureList = <
  TData = Awaited<ReturnType<typeof timetableManagementGetMoveLectureList>>,
  TError = ErrorType<unknown>,
>(
  params: TimetableManagementGetMoveLectureListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetableManagementGetMoveLectureList>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetableManagementGetMoveLectureListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetableManagementGetMoveLectureList>>> = ({ signal }) =>
    timetableManagementGetMoveLectureList(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetableManagementGetMoveLectureList>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 수업 교환
 */
export const timetableManagementTradeLecture = (
  requestTradeLectureDto: RequestTradeLectureDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/admin/timetable-management/trade`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestTradeLectureDto,
    },
    options,
  );
};

export type TimetableManagementTradeLectureMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementTradeLecture>>
>;
export type TimetableManagementTradeLectureMutationBody = RequestTradeLectureDto;
export type TimetableManagementTradeLectureMutationError = ErrorType<void>;

export const useTimetableManagementTradeLecture = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetableManagementTradeLecture>>,
    TError,
    { data: RequestTradeLectureDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetableManagementTradeLecture>>,
    { data: RequestTradeLectureDto }
  > = (props) => {
    const { data } = props ?? {};

    return timetableManagementTradeLecture(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetableManagementTradeLecture>>,
    TError,
    { data: RequestTradeLectureDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 요일 교환
 */
export const timetableManagementTradeLectureDay = (
  requestTradeLectureDayDto: RequestTradeLectureDayDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/admin/timetable-management/trade-day`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestTradeLectureDayDto,
    },
    options,
  );
};

export type TimetableManagementTradeLectureDayMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementTradeLectureDay>>
>;
export type TimetableManagementTradeLectureDayMutationBody = RequestTradeLectureDayDto;
export type TimetableManagementTradeLectureDayMutationError = ErrorType<void>;

export const useTimetableManagementTradeLectureDay = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetableManagementTradeLectureDay>>,
    TError,
    { data: RequestTradeLectureDayDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetableManagementTradeLectureDay>>,
    { data: RequestTradeLectureDayDto }
  > = (props) => {
    const { data } = props ?? {};

    return timetableManagementTradeLectureDay(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetableManagementTradeLectureDay>>,
    TError,
    { data: RequestTradeLectureDayDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 수업 추가
 */
export const timetableManagementCreateMoveLecture = (
  week: number,
  lectureId: number,
  requestCreateLectureDto: RequestCreateLectureDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseLectureInfoDto>(
    {
      url: `/api/admin/timetable-management/lecture/${week}/${lectureId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateLectureDto,
    },
    options,
  );
};

export type TimetableManagementCreateMoveLectureMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementCreateMoveLecture>>
>;
export type TimetableManagementCreateMoveLectureMutationBody = RequestCreateLectureDto;
export type TimetableManagementCreateMoveLectureMutationError = ErrorType<void>;

export const useTimetableManagementCreateMoveLecture = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetableManagementCreateMoveLecture>>,
    TError,
    { week: number; lectureId: number; data: RequestCreateLectureDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetableManagementCreateMoveLecture>>,
    { week: number; lectureId: number; data: RequestCreateLectureDto }
  > = (props) => {
    const { week, lectureId, data } = props ?? {};

    return timetableManagementCreateMoveLecture(week, lectureId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetableManagementCreateMoveLecture>>,
    TError,
    { week: number; lectureId: number; data: RequestCreateLectureDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 수업 정보 수정
 */
export const timetableManagementModifyLectureInfo = (
  lectureId: number,
  week: number,
  requestModifyLectureInfoDto: RequestModifyLectureInfoDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseLectureInfoDto>(
    {
      url: `/api/admin/timetable-management/${lectureId}/${week}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestModifyLectureInfoDto,
    },
    options,
  );
};

export type TimetableManagementModifyLectureInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementModifyLectureInfo>>
>;
export type TimetableManagementModifyLectureInfoMutationBody = RequestModifyLectureInfoDto;
export type TimetableManagementModifyLectureInfoMutationError = ErrorType<void>;

export const useTimetableManagementModifyLectureInfo = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetableManagementModifyLectureInfo>>,
    TError,
    { lectureId: number; week: number; data: RequestModifyLectureInfoDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetableManagementModifyLectureInfo>>,
    { lectureId: number; week: number; data: RequestModifyLectureInfoDto }
  > = (props) => {
    const { lectureId, week, data } = props ?? {};

    return timetableManagementModifyLectureInfo(lectureId, week, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetableManagementModifyLectureInfo>>,
    TError,
    { lectureId: number; week: number; data: RequestModifyLectureInfoDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 주별 수업 상세 조회
 */
export const timetableManagementGetLectureInfoWithWeek = (
  lectureId: number,
  week: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseLectureInfoDto>(
    { url: `/api/admin/timetable-management/${lectureId}/${week}`, method: 'get', signal },
    options,
  );
};

export const getTimetableManagementGetLectureInfoWithWeekQueryKey = (lectureId: number, week: number) => [
  `/api/admin/timetable-management/${lectureId}/${week}`,
];

export type TimetableManagementGetLectureInfoWithWeekQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementGetLectureInfoWithWeek>>
>;
export type TimetableManagementGetLectureInfoWithWeekQueryError = ErrorType<void>;

export const useTimetableManagementGetLectureInfoWithWeek = <
  TData = Awaited<ReturnType<typeof timetableManagementGetLectureInfoWithWeek>>,
  TError = ErrorType<void>,
>(
  lectureId: number,
  week: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetableManagementGetLectureInfoWithWeek>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetableManagementGetLectureInfoWithWeekQueryKey(lectureId, week);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetableManagementGetLectureInfoWithWeek>>> = ({ signal }) =>
    timetableManagementGetLectureInfoWithWeek(lectureId, week, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetableManagementGetLectureInfoWithWeek>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(lectureId && week), ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 이동 수업 선택(학생 시간표 only)
 */
export const timetableManagementSetStudentMoveLecture = (
  requestModifyMoveLectureDto: RequestModifyMoveLectureDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>(
    {
      url: `/api/admin/timetable-management/move-lecture/student`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestModifyMoveLectureDto,
    },
    options,
  );
};

export type TimetableManagementSetStudentMoveLectureMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementSetStudentMoveLecture>>
>;
export type TimetableManagementSetStudentMoveLectureMutationBody = RequestModifyMoveLectureDto;
export type TimetableManagementSetStudentMoveLectureMutationError = ErrorType<void>;

export const useTimetableManagementSetStudentMoveLecture = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetableManagementSetStudentMoveLecture>>,
    TError,
    { data: RequestModifyMoveLectureDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetableManagementSetStudentMoveLecture>>,
    { data: RequestModifyMoveLectureDto }
  > = (props) => {
    const { data } = props ?? {};

    return timetableManagementSetStudentMoveLecture(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetableManagementSetStudentMoveLecture>>,
    TError,
    { data: RequestModifyMoveLectureDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 수업 상세 조회
 */
export const timetableManagementGetLectureInfo = (
  lectureId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseLectureInfoDto>(
    { url: `/api/admin/timetable-management/${lectureId}`, method: 'get', signal },
    options,
  );
};

export const getTimetableManagementGetLectureInfoQueryKey = (lectureId: number) => [
  `/api/admin/timetable-management/${lectureId}`,
];

export type TimetableManagementGetLectureInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementGetLectureInfo>>
>;
export type TimetableManagementGetLectureInfoQueryError = ErrorType<void>;

export const useTimetableManagementGetLectureInfo = <
  TData = Awaited<ReturnType<typeof timetableManagementGetLectureInfo>>,
  TError = ErrorType<void>,
>(
  lectureId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetableManagementGetLectureInfo>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetableManagementGetLectureInfoQueryKey(lectureId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetableManagementGetLectureInfo>>> = ({ signal }) =>
    timetableManagementGetLectureInfo(lectureId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetableManagementGetLectureInfo>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!lectureId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선택한 수업 삭제
 */
export const timetableManagementDeleteLecture = (
  lectureId: number,
  params?: TimetableManagementDeleteLectureParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean>({ url: `/api/admin/timetable-management/${lectureId}`, method: 'delete', params }, options);
};

export type TimetableManagementDeleteLectureMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetableManagementDeleteLecture>>
>;

export type TimetableManagementDeleteLectureMutationError = ErrorType<void>;

export const useTimetableManagementDeleteLecture = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetableManagementDeleteLecture>>,
    TError,
    { lectureId: number; params?: TimetableManagementDeleteLectureParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetableManagementDeleteLecture>>,
    { lectureId: number; params?: TimetableManagementDeleteLectureParams }
  > = (props) => {
    const { lectureId, params } = props ?? {};

    return timetableManagementDeleteLecture(lectureId, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetableManagementDeleteLecture>>,
    TError,
    { lectureId: number; params?: TimetableManagementDeleteLectureParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 사용자 검색
 */
export const commonUserSearch = (
  params?: CommonUserSearchParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseSearchUserDto[]>({ url: `/api/common/user/search`, method: 'get', params, signal }, options);
};

export const getCommonUserSearchQueryKey = (params?: CommonUserSearchParams) => [
  `/api/common/user/search`,
  ...(params ? [params] : []),
];

export type CommonUserSearchQueryResult = NonNullable<Awaited<ReturnType<typeof commonUserSearch>>>;
export type CommonUserSearchQueryError = ErrorType<unknown>;

export const useCommonUserSearch = <TData = Awaited<ReturnType<typeof commonUserSearch>>, TError = ErrorType<unknown>>(
  params?: CommonUserSearchParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof commonUserSearch>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCommonUserSearchQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof commonUserSearch>>> = ({ signal }) =>
    commonUserSearch(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof commonUserSearch>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 일반 OTP 발송
 */
export const otpSendPost = (otpSendPostBody: OtpSendPostBody, options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean | void>(
    { url: `/api/otp/send`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: otpSendPostBody },
    options,
  );
};

export type OtpSendPostMutationResult = NonNullable<Awaited<ReturnType<typeof otpSendPost>>>;
export type OtpSendPostMutationBody = OtpSendPostBody;
export type OtpSendPostMutationError = ErrorType<unknown>;

export const useOtpSendPost = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof otpSendPost>>, TError, { data: OtpSendPostBody }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof otpSendPost>>, { data: OtpSendPostBody }> = (props) => {
    const { data } = props ?? {};

    return otpSendPost(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof otpSendPost>>, TError, { data: OtpSendPostBody }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 유저 2차인증 OTP 발송
 */
export const otpSendPostAuth = (
  otpSendPostAuthBody: OtpSendPostAuthBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean | void>(
    {
      url: `/api/otp/send/auth`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: otpSendPostAuthBody,
    },
    options,
  );
};

export type OtpSendPostAuthMutationResult = NonNullable<Awaited<ReturnType<typeof otpSendPostAuth>>>;
export type OtpSendPostAuthMutationBody = OtpSendPostAuthBody;
export type OtpSendPostAuthMutationError = ErrorType<unknown>;

export const useOtpSendPostAuth = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof otpSendPostAuth>>,
    TError,
    { data: OtpSendPostAuthBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof otpSendPostAuth>>, { data: OtpSendPostAuthBody }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return otpSendPostAuth(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof otpSendPostAuth>>, TError, { data: OtpSendPostAuthBody }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary OTP 확인
 */
export const otpUserSearchPost = (
  otpUserSearchPostBody: OtpUserSearchPostBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean | void>(
    {
      url: `/api/otp/check`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: otpUserSearchPostBody,
    },
    options,
  );
};

export type OtpUserSearchPostMutationResult = NonNullable<Awaited<ReturnType<typeof otpUserSearchPost>>>;
export type OtpUserSearchPostMutationBody = OtpUserSearchPostBody;
export type OtpUserSearchPostMutationError = ErrorType<unknown>;

export const useOtpUserSearchPost = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof otpUserSearchPost>>,
    TError,
    { data: OtpUserSearchPostBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof otpUserSearchPost>>, { data: OtpUserSearchPostBody }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return otpUserSearchPost(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof otpUserSearchPost>>, TError, { data: OtpUserSearchPostBody }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 유저 2차인증 OTP 확인
 */
export const otpUserSearchPostAuth = (
  otpUserSearchPostAuthBody: OtpUserSearchPostAuthBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<boolean | void>(
    {
      url: `/api/otp/check/auth`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: otpUserSearchPostAuthBody,
    },
    options,
  );
};

export type OtpUserSearchPostAuthMutationResult = NonNullable<Awaited<ReturnType<typeof otpUserSearchPostAuth>>>;
export type OtpUserSearchPostAuthMutationBody = OtpUserSearchPostAuthBody;
export type OtpUserSearchPostAuthMutationError = ErrorType<unknown>;

export const useOtpUserSearchPostAuth = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof otpUserSearchPostAuth>>,
    TError,
    { data: OtpUserSearchPostAuthBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof otpUserSearchPostAuth>>,
    { data: OtpUserSearchPostAuthBody }
  > = (props) => {
    const { data } = props ?? {};

    return otpUserSearchPostAuth(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof otpUserSearchPostAuth>>,
    TError,
    { data: OtpUserSearchPostAuthBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 통계 조회
 */
export const smsManagementGetStatistics = (
  params: SmsManagementGetStatisticsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<SmsCreditHistory[]>(
    { url: `/api/admin/sms-management/statistics`, method: 'get', params, signal },
    options,
  );
};

export const getSmsManagementGetStatisticsQueryKey = (params: SmsManagementGetStatisticsParams) => [
  `/api/admin/sms-management/statistics`,
  ...(params ? [params] : []),
];

export type SmsManagementGetStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof smsManagementGetStatistics>>>;
export type SmsManagementGetStatisticsQueryError = ErrorType<unknown>;

export const useSmsManagementGetStatistics = <
  TData = Awaited<ReturnType<typeof smsManagementGetStatistics>>,
  TError = ErrorType<unknown>,
>(
  params: SmsManagementGetStatisticsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof smsManagementGetStatistics>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSmsManagementGetStatisticsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof smsManagementGetStatistics>>> = ({ signal }) =>
    smsManagementGetStatistics(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof smsManagementGetStatistics>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 이력 조회
 */
export const smsManagementGetSmsHistory = (
  params: SmsManagementGetSmsHistoryParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseSmsHistoryDto[]>(
    { url: `/api/admin/sms-management/history`, method: 'get', params, signal },
    options,
  );
};

export const getSmsManagementGetSmsHistoryQueryKey = (params: SmsManagementGetSmsHistoryParams) => [
  `/api/admin/sms-management/history`,
  ...(params ? [params] : []),
];

export type SmsManagementGetSmsHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof smsManagementGetSmsHistory>>>;
export type SmsManagementGetSmsHistoryQueryError = ErrorType<unknown>;

export const useSmsManagementGetSmsHistory = <
  TData = Awaited<ReturnType<typeof smsManagementGetSmsHistory>>,
  TError = ErrorType<unknown>,
>(
  params: SmsManagementGetSmsHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof smsManagementGetSmsHistory>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSmsManagementGetSmsHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof smsManagementGetSmsHistory>>> = ({ signal }) =>
    smsManagementGetSmsHistory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof smsManagementGetSmsHistory>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 충전 이력 조회
 */
export const smsManagementGetCreditCharge = (
  params: SmsManagementGetCreditChargeParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<SmsCreditCharge[]>(
    { url: `/api/admin/sms-management/credit-charge`, method: 'get', params, signal },
    options,
  );
};

export const getSmsManagementGetCreditChargeQueryKey = (params: SmsManagementGetCreditChargeParams) => [
  `/api/admin/sms-management/credit-charge`,
  ...(params ? [params] : []),
];

export type SmsManagementGetCreditChargeQueryResult = NonNullable<
  Awaited<ReturnType<typeof smsManagementGetCreditCharge>>
>;
export type SmsManagementGetCreditChargeQueryError = ErrorType<unknown>;

export const useSmsManagementGetCreditCharge = <
  TData = Awaited<ReturnType<typeof smsManagementGetCreditCharge>>,
  TError = ErrorType<unknown>,
>(
  params: SmsManagementGetCreditChargeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof smsManagementGetCreditCharge>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSmsManagementGetCreditChargeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof smsManagementGetCreditCharge>>> = ({ signal }) =>
    smsManagementGetCreditCharge(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof smsManagementGetCreditCharge>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 잔여량 조회
 */
export const smsManagementGetCreditRemain = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<SmsCreditRemain>({ url: `/api/admin/sms-management/credit-remain`, method: 'get', signal }, options);
};

export const getSmsManagementGetCreditRemainQueryKey = () => [`/api/admin/sms-management/credit-remain`];

export type SmsManagementGetCreditRemainQueryResult = NonNullable<
  Awaited<ReturnType<typeof smsManagementGetCreditRemain>>
>;
export type SmsManagementGetCreditRemainQueryError = ErrorType<unknown>;

export const useSmsManagementGetCreditRemain = <
  TData = Awaited<ReturnType<typeof smsManagementGetCreditRemain>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof smsManagementGetCreditRemain>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSmsManagementGetCreditRemainQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof smsManagementGetCreditRemain>>> = ({ signal }) =>
    smsManagementGetCreditRemain(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof smsManagementGetCreditRemain>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생의 진학/학습 정보
 */
export const studentPropertyFindByStudentId = (
  studentId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentProperty>({ url: `/api/studentproperty/${studentId}`, method: 'get', signal }, options);
};

export const getStudentPropertyFindByStudentIdQueryKey = (studentId: number) => [`/api/studentproperty/${studentId}`];

export type StudentPropertyFindByStudentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentPropertyFindByStudentId>>
>;
export type StudentPropertyFindByStudentIdQueryError = ErrorType<unknown>;

export const useStudentPropertyFindByStudentId = <
  TData = Awaited<ReturnType<typeof studentPropertyFindByStudentId>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentPropertyFindByStudentId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentPropertyFindByStudentIdQueryKey(studentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentPropertyFindByStudentId>>> = ({ signal }) =>
    studentPropertyFindByStudentId(studentId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentPropertyFindByStudentId>>, TError, TData>(queryKey, queryFn, {
    enabled: !!studentId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생의 진학/학습 정보 추가/수정
 */
export const studentPropertyCreateOrUpdate = (
  requestStudentPropertyDto: RequestStudentPropertyDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentProperty>(
    {
      url: `/api/studentproperty`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestStudentPropertyDto,
    },
    options,
  );
};

export type StudentPropertyCreateOrUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentPropertyCreateOrUpdate>>
>;
export type StudentPropertyCreateOrUpdateMutationBody = RequestStudentPropertyDto;
export type StudentPropertyCreateOrUpdateMutationError = ErrorType<void>;

export const useStudentPropertyCreateOrUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentPropertyCreateOrUpdate>>,
    TError,
    { data: RequestStudentPropertyDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentPropertyCreateOrUpdate>>,
    { data: RequestStudentPropertyDto }
  > = (props) => {
    const { data } = props ?? {};

    return studentPropertyCreateOrUpdate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentPropertyCreateOrUpdate>>,
    TError,
    { data: RequestStudentPropertyDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 서비스 공지사항 추가 (관리자용)
 */
export const announcementAddAnnouncement = (
  requestCreateAnnouncementDto: RequestCreateAnnouncementDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseAnnouncementDto>(
    {
      url: `/api/announcements`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateAnnouncementDto,
    },
    options,
  );
};

export type AnnouncementAddAnnouncementMutationResult = NonNullable<
  Awaited<ReturnType<typeof announcementAddAnnouncement>>
>;
export type AnnouncementAddAnnouncementMutationBody = RequestCreateAnnouncementDto;
export type AnnouncementAddAnnouncementMutationError = ErrorType<unknown>;

export const useAnnouncementAddAnnouncement = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof announcementAddAnnouncement>>,
    TError,
    { data: RequestCreateAnnouncementDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof announcementAddAnnouncement>>,
    { data: RequestCreateAnnouncementDto }
  > = (props) => {
    const { data } = props ?? {};

    return announcementAddAnnouncement(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof announcementAddAnnouncement>>,
    TError,
    { data: RequestCreateAnnouncementDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 전체/타입별 서비스 공지사항 조회
 */
export const announcementGetAllAnnouncements = (
  params: AnnouncementGetAllAnnouncementsParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedAnnouncementDto>(
    { url: `/api/announcements`, method: 'get', params, signal },
    options,
  );
};

export const getAnnouncementGetAllAnnouncementsQueryKey = (params: AnnouncementGetAllAnnouncementsParams) => [
  `/api/announcements`,
  ...(params ? [params] : []),
];

export type AnnouncementGetAllAnnouncementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof announcementGetAllAnnouncements>>
>;
export type AnnouncementGetAllAnnouncementsQueryError = ErrorType<unknown>;

export const useAnnouncementGetAllAnnouncements = <
  TData = Awaited<ReturnType<typeof announcementGetAllAnnouncements>>,
  TError = ErrorType<unknown>,
>(
  params: AnnouncementGetAllAnnouncementsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof announcementGetAllAnnouncements>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAnnouncementGetAllAnnouncementsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof announcementGetAllAnnouncements>>> = ({ signal }) =>
    announcementGetAllAnnouncements(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof announcementGetAllAnnouncements>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 전체/타입별 공지사항 조회 (관리자용)
 */
export const announcementGetAllAnnouncementsForAdmin = (
  params: AnnouncementGetAllAnnouncementsForAdminParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponsePaginatedAnnouncementDto>(
    { url: `/api/announcements/all`, method: 'get', params, signal },
    options,
  );
};

export const getAnnouncementGetAllAnnouncementsForAdminQueryKey = (
  params: AnnouncementGetAllAnnouncementsForAdminParams,
) => [`/api/announcements/all`, ...(params ? [params] : [])];

export type AnnouncementGetAllAnnouncementsForAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof announcementGetAllAnnouncementsForAdmin>>
>;
export type AnnouncementGetAllAnnouncementsForAdminQueryError = ErrorType<unknown>;

export const useAnnouncementGetAllAnnouncementsForAdmin = <
  TData = Awaited<ReturnType<typeof announcementGetAllAnnouncementsForAdmin>>,
  TError = ErrorType<unknown>,
>(
  params: AnnouncementGetAllAnnouncementsForAdminParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof announcementGetAllAnnouncementsForAdmin>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAnnouncementGetAllAnnouncementsForAdminQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof announcementGetAllAnnouncementsForAdmin>>> = ({ signal }) =>
    announcementGetAllAnnouncementsForAdmin(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof announcementGetAllAnnouncementsForAdmin>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활성화된 서비스 공지사항 전체 조회
 */
export const announcementGetActiveAnnouncements = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseAnnouncementDto[]>({ url: `/api/announcements/active`, method: 'get', signal }, options);
};

export const getAnnouncementGetActiveAnnouncementsQueryKey = () => [`/api/announcements/active`];

export type AnnouncementGetActiveAnnouncementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof announcementGetActiveAnnouncements>>
>;
export type AnnouncementGetActiveAnnouncementsQueryError = ErrorType<unknown>;

export const useAnnouncementGetActiveAnnouncements = <
  TData = Awaited<ReturnType<typeof announcementGetActiveAnnouncements>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof announcementGetActiveAnnouncements>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAnnouncementGetActiveAnnouncementsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof announcementGetActiveAnnouncements>>> = ({ signal }) =>
    announcementGetActiveAnnouncements(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof announcementGetActiveAnnouncements>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary ID로 서비스 공지사항 단일 조회
 */
export const announcementGetAnnouncementById = (
  id: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseAnnouncementDto>({ url: `/api/announcements/${id}`, method: 'get', signal }, options);
};

export const getAnnouncementGetAnnouncementByIdQueryKey = (id: number) => [`/api/announcements/${id}`];

export type AnnouncementGetAnnouncementByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof announcementGetAnnouncementById>>
>;
export type AnnouncementGetAnnouncementByIdQueryError = ErrorType<unknown>;

export const useAnnouncementGetAnnouncementById = <
  TData = Awaited<ReturnType<typeof announcementGetAnnouncementById>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof announcementGetAnnouncementById>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAnnouncementGetAnnouncementByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof announcementGetAnnouncementById>>> = ({ signal }) =>
    announcementGetAnnouncementById(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof announcementGetAnnouncementById>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 서비스 공지사항 삭제 (관리자용)
 */
export const announcementDeleteAnnouncement = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<ResponseAnnouncementDto>({ url: `/api/announcements/${id}`, method: 'delete' }, options);
};

export type AnnouncementDeleteAnnouncementMutationResult = NonNullable<
  Awaited<ReturnType<typeof announcementDeleteAnnouncement>>
>;

export type AnnouncementDeleteAnnouncementMutationError = ErrorType<unknown>;

export const useAnnouncementDeleteAnnouncement = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof announcementDeleteAnnouncement>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof announcementDeleteAnnouncement>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return announcementDeleteAnnouncement(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof announcementDeleteAnnouncement>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 서비스 공지사항 수정 (관리자용)
 */
export const announcementUpdateAnnouncement = (
  id: number,
  requestUpdateAnnouncementDto: RequestUpdateAnnouncementDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<ResponseAnnouncementDto>(
    {
      url: `/api/announcements/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateAnnouncementDto,
    },
    options,
  );
};

export type AnnouncementUpdateAnnouncementMutationResult = NonNullable<
  Awaited<ReturnType<typeof announcementUpdateAnnouncement>>
>;
export type AnnouncementUpdateAnnouncementMutationBody = RequestUpdateAnnouncementDto;
export type AnnouncementUpdateAnnouncementMutationError = ErrorType<unknown>;

export const useAnnouncementUpdateAnnouncement = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof announcementUpdateAnnouncement>>,
    TError,
    { id: number; data: RequestUpdateAnnouncementDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof announcementUpdateAnnouncement>>,
    { id: number; data: RequestUpdateAnnouncementDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return announcementUpdateAnnouncement(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof announcementUpdateAnnouncement>>,
    TError,
    { id: number; data: RequestUpdateAnnouncementDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 창체 활동 그룹 연관정보 조회(선생님용)
 */
export const groupActivityV3FindByTeacher = (
  params: GroupActivityV3FindByTeacherParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<GroupActivityV3[]>({ url: `/api/group-activityv3/teacher`, method: 'get', params, signal }, options);
};

export const getGroupActivityV3FindByTeacherQueryKey = (params: GroupActivityV3FindByTeacherParams) => [
  `/api/group-activityv3/teacher`,
  ...(params ? [params] : []),
];

export type GroupActivityV3FindByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupActivityV3FindByTeacher>>
>;
export type GroupActivityV3FindByTeacherQueryError = ErrorType<void>;

export const useGroupActivityV3FindByTeacher = <
  TData = Awaited<ReturnType<typeof groupActivityV3FindByTeacher>>,
  TError = ErrorType<void>,
>(
  params: GroupActivityV3FindByTeacherParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupActivityV3FindByTeacher>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupActivityV3FindByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupActivityV3FindByTeacher>>> = ({ signal }) =>
    groupActivityV3FindByTeacher(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupActivityV3FindByTeacher>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 나이스 비교 정보 가져오기
 */
export const niceComparisonsFindOne = (
  params: NiceComparisonsFindOneParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<NiceComparison>({ url: `/api/nice-comparison/one`, method: 'get', params, signal }, options);
};

export const getNiceComparisonsFindOneQueryKey = (params: NiceComparisonsFindOneParams) => [
  `/api/nice-comparison/one`,
  ...(params ? [params] : []),
];

export type NiceComparisonsFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof niceComparisonsFindOne>>>;
export type NiceComparisonsFindOneQueryError = ErrorType<void>;

export const useNiceComparisonsFindOne = <
  TData = Awaited<ReturnType<typeof niceComparisonsFindOne>>,
  TError = ErrorType<void>,
>(
  params: NiceComparisonsFindOneParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof niceComparisonsFindOne>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNiceComparisonsFindOneQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof niceComparisonsFindOne>>> = ({ signal }) =>
    niceComparisonsFindOne(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof niceComparisonsFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 나이스 비교 정보 리스트 가져오기
 */
export const niceComparisonsFindAll = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<NiceComparison[]>({ url: `/api/nice-comparison`, method: 'get', signal }, options);
};

export const getNiceComparisonsFindAllQueryKey = () => [`/api/nice-comparison`];

export type NiceComparisonsFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof niceComparisonsFindAll>>>;
export type NiceComparisonsFindAllQueryError = ErrorType<void>;

export const useNiceComparisonsFindAll = <
  TData = Awaited<ReturnType<typeof niceComparisonsFindAll>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof niceComparisonsFindAll>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNiceComparisonsFindAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof niceComparisonsFindAll>>> = ({ signal }) =>
    niceComparisonsFindAll(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof niceComparisonsFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 나이스 비교 추가
 */
export const niceComparisonsCreate = (
  requestCreateNiceComparisonDto: RequestCreateNiceComparisonDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<NiceComparison>(
    {
      url: `/api/nice-comparison`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateNiceComparisonDto,
    },
    options,
  );
};

export type NiceComparisonsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof niceComparisonsCreate>>>;
export type NiceComparisonsCreateMutationBody = RequestCreateNiceComparisonDto;
export type NiceComparisonsCreateMutationError = ErrorType<void>;

export const useNiceComparisonsCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof niceComparisonsCreate>>,
    TError,
    { data: RequestCreateNiceComparisonDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof niceComparisonsCreate>>,
    { data: RequestCreateNiceComparisonDto }
  > = (props) => {
    const { data } = props ?? {};

    return niceComparisonsCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof niceComparisonsCreate>>,
    TError,
    { data: RequestCreateNiceComparisonDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 나이스 비교 추가
 */
export const niceComparisonsUpdate = (
  id: number,
  requestUpdateNiceComparisonDto: RequestUpdateNiceComparisonDto,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<NiceComparison>(
    {
      url: `/api/nice-comparison/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateNiceComparisonDto,
    },
    options,
  );
};

export type NiceComparisonsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof niceComparisonsUpdate>>>;
export type NiceComparisonsUpdateMutationBody = RequestUpdateNiceComparisonDto;
export type NiceComparisonsUpdateMutationError = ErrorType<void>;

export const useNiceComparisonsUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof niceComparisonsUpdate>>,
    TError,
    { id: number; data: RequestUpdateNiceComparisonDto },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof niceComparisonsUpdate>>,
    { id: number; data: RequestUpdateNiceComparisonDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return niceComparisonsUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof niceComparisonsUpdate>>,
    TError,
    { id: number; data: RequestUpdateNiceComparisonDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 나이스 비교 삭제
 */
export const niceComparisonsDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/nice-comparison/${id}`, method: 'delete' }, options);
};

export type NiceComparisonsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof niceComparisonsDelete>>>;

export type NiceComparisonsDeleteMutationError = ErrorType<unknown>;

export const useNiceComparisonsDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof niceComparisonsDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof niceComparisonsDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return niceComparisonsDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof niceComparisonsDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 학생별 생활기록부 목록 조회
 */
export const studentRecordontrollerFindByStudentId = (
  params: StudentRecordontrollerFindByStudentIdParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentRecord[]>({ url: `/api/student-records`, method: 'get', params, signal }, options);
};

export const getStudentRecordontrollerFindByStudentIdQueryKey = (
  params: StudentRecordontrollerFindByStudentIdParams,
) => [`/api/student-records`, ...(params ? [params] : [])];

export type StudentRecordontrollerFindByStudentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentRecordontrollerFindByStudentId>>
>;
export type StudentRecordontrollerFindByStudentIdQueryError = ErrorType<void>;

export const useStudentRecordontrollerFindByStudentId = <
  TData = Awaited<ReturnType<typeof studentRecordontrollerFindByStudentId>>,
  TError = ErrorType<void>,
>(
  params: StudentRecordontrollerFindByStudentIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentRecordontrollerFindByStudentId>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentRecordontrollerFindByStudentIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentRecordontrollerFindByStudentId>>> = ({ signal }) =>
    studentRecordontrollerFindByStudentId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentRecordontrollerFindByStudentId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 생활기록부 작성
 */
export const studentRecordontrollerCreate = (
  requestCreateStudentRecordDto: RequestCreateStudentRecordDto,
  params: StudentRecordontrollerCreateParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentRecord>(
    {
      url: `/api/student-records`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateStudentRecordDto,
      params,
    },
    options,
  );
};

export type StudentRecordontrollerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentRecordontrollerCreate>>
>;
export type StudentRecordontrollerCreateMutationBody = RequestCreateStudentRecordDto;
export type StudentRecordontrollerCreateMutationError = ErrorType<void>;

export const useStudentRecordontrollerCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentRecordontrollerCreate>>,
    TError,
    { data: RequestCreateStudentRecordDto; params: StudentRecordontrollerCreateParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentRecordontrollerCreate>>,
    { data: RequestCreateStudentRecordDto; params: StudentRecordontrollerCreateParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return studentRecordontrollerCreate(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentRecordontrollerCreate>>,
    TError,
    { data: RequestCreateStudentRecordDto; params: StudentRecordontrollerCreateParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 관찰기록 수정
 */
export const studentRecordontrollerUpdate = (
  id: number,
  studentRecordontrollerUpdateBody: StudentRecordontrollerUpdateBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<UpdateResult>(
    {
      url: `/api/student-records/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: studentRecordontrollerUpdateBody,
    },
    options,
  );
};

export type StudentRecordontrollerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentRecordontrollerUpdate>>
>;
export type StudentRecordontrollerUpdateMutationBody = StudentRecordontrollerUpdateBody;
export type StudentRecordontrollerUpdateMutationError = ErrorType<void>;

export const useStudentRecordontrollerUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentRecordontrollerUpdate>>,
    TError,
    { id: number; data: StudentRecordontrollerUpdateBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentRecordontrollerUpdate>>,
    { id: number; data: StudentRecordontrollerUpdateBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentRecordontrollerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentRecordontrollerUpdate>>,
    TError,
    { id: number; data: StudentRecordontrollerUpdateBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동기록 초안 삭제
 */
export const studentRecordontrollerDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<StudentRecord>({ url: `/api/student-records/${id}`, method: 'delete' }, options);
};

export type StudentRecordontrollerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentRecordontrollerDelete>>
>;

export type StudentRecordontrollerDeleteMutationError = ErrorType<void>;

export const useStudentRecordontrollerDelete = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentRecordontrollerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof studentRecordontrollerDelete>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return studentRecordontrollerDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof studentRecordontrollerDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 생활기록부 총정리 다운로드
 */
export const studentRecordontrollerDownloadRecordSummary = (
  groupId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<Blob>(
    { url: `/api/student-records/download/summary/${groupId}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getStudentRecordontrollerDownloadRecordSummaryQueryKey = (groupId: number) => [
  `/api/student-records/download/summary/${groupId}`,
];

export type StudentRecordontrollerDownloadRecordSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentRecordontrollerDownloadRecordSummary>>
>;
export type StudentRecordontrollerDownloadRecordSummaryQueryError = ErrorType<unknown>;

export const useStudentRecordontrollerDownloadRecordSummary = <
  TData = Awaited<ReturnType<typeof studentRecordontrollerDownloadRecordSummary>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentRecordontrollerDownloadRecordSummary>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentRecordontrollerDownloadRecordSummaryQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentRecordontrollerDownloadRecordSummary>>> = ({
    signal,
  }) => studentRecordontrollerDownloadRecordSummary(groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentRecordontrollerDownloadRecordSummary>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!groupId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 내 성적 조회
 */
export const studentExamScoreGetMyScores = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<StudentExamScoreGetMyScores200>(
    { url: `/api/analysis/school-exam/users/my-scores`, method: 'get', signal },
    options,
  );
};

export const getStudentExamScoreGetMyScoresQueryKey = () => [`/api/analysis/school-exam/users/my-scores`];

export type StudentExamScoreGetMyScoresQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScoreGetMyScores>>
>;
export type StudentExamScoreGetMyScoresQueryError = ErrorType<unknown>;

export const useStudentExamScoreGetMyScores = <
  TData = Awaited<ReturnType<typeof studentExamScoreGetMyScores>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof studentExamScoreGetMyScores>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentExamScoreGetMyScoresQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentExamScoreGetMyScores>>> = ({ signal }) =>
    studentExamScoreGetMyScores(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentExamScoreGetMyScores>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 시험 성적 조회
 */
export const studentExamScoreGetStudentExamScores = (
  studentId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentExamScoreGetStudentExamScores200>(
    { url: `/api/analysis/school-exam/users/${studentId}/scores`, method: 'get', signal },
    options,
  );
};

export const getStudentExamScoreGetStudentExamScoresQueryKey = (studentId: number) => [
  `/api/analysis/school-exam/users/${studentId}/scores`,
];

export type StudentExamScoreGetStudentExamScoresQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScoreGetStudentExamScores>>
>;
export type StudentExamScoreGetStudentExamScoresQueryError = ErrorType<unknown>;

export const useStudentExamScoreGetStudentExamScores = <
  TData = Awaited<ReturnType<typeof studentExamScoreGetStudentExamScores>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentExamScoreGetStudentExamScores>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentExamScoreGetStudentExamScoresQueryKey(studentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentExamScoreGetStudentExamScores>>> = ({ signal }) =>
    studentExamScoreGetStudentExamScores(studentId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentExamScoreGetStudentExamScores>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!studentId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 시험 성적 차트 조회
 */
export const studentExamScoreGetStudentExamScoresChart = (
  studentId: number,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<StudentExamScoreGetStudentExamScoresChart200>(
    { url: `/api/analysis/school-exam/users/${studentId}/scores-chart`, method: 'get', signal },
    options,
  );
};

export const getStudentExamScoreGetStudentExamScoresChartQueryKey = (studentId: number) => [
  `/api/analysis/school-exam/users/${studentId}/scores-chart`,
];

export type StudentExamScoreGetStudentExamScoresChartQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScoreGetStudentExamScoresChart>>
>;
export type StudentExamScoreGetStudentExamScoresChartQueryError = ErrorType<unknown>;

export const useStudentExamScoreGetStudentExamScoresChart = <
  TData = Awaited<ReturnType<typeof studentExamScoreGetStudentExamScoresChart>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentExamScoreGetStudentExamScoresChart>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentExamScoreGetStudentExamScoresChartQueryKey(studentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentExamScoreGetStudentExamScoresChart>>> = ({ signal }) =>
    studentExamScoreGetStudentExamScoresChart(studentId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentExamScoreGetStudentExamScoresChart>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!studentId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학년도 학생 성적 업데이트
 */
export const studentExamScorePatchStudentExamScores = (
  params: StudentExamScorePatchStudentExamScoresParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<StudentExamScorePatchStudentExamScores200>(
    { url: `/api/analysis/school-exam/users/scores`, method: 'patch', params },
    options,
  );
};

export type StudentExamScorePatchStudentExamScoresMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScorePatchStudentExamScores>>
>;

export type StudentExamScorePatchStudentExamScoresMutationError = ErrorType<unknown>;

export const useStudentExamScorePatchStudentExamScores = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentExamScorePatchStudentExamScores>>,
    TError,
    { params: StudentExamScorePatchStudentExamScoresParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentExamScorePatchStudentExamScores>>,
    { params: StudentExamScorePatchStudentExamScoresParams }
  > = (props) => {
    const { params } = props ?? {};

    return studentExamScorePatchStudentExamScores(params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentExamScorePatchStudentExamScores>>,
    TError,
    { params: StudentExamScorePatchStudentExamScoresParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 성적파일 업로드
 */
export const studentExamScoreInsetClassScores = (
  studentExamScoreInsetClassScoresBody: StudentExamScoreInsetClassScoresBody,
  params: StudentExamScoreInsetClassScoresParams,
  options?: SecondParameter<typeof mutator>,
) => {
  const formData = new FormData();
  if (studentExamScoreInsetClassScoresBody.file !== undefined) {
    formData.append('file', studentExamScoreInsetClassScoresBody.file);
  }

  return mutator<void>(
    {
      url: `/api/analysis/school-exam/users/insert-class-scores`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options,
  );
};

export type StudentExamScoreInsetClassScoresMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScoreInsetClassScores>>
>;
export type StudentExamScoreInsetClassScoresMutationBody = StudentExamScoreInsetClassScoresBody;
export type StudentExamScoreInsetClassScoresMutationError = ErrorType<unknown>;

export const useStudentExamScoreInsetClassScores = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentExamScoreInsetClassScores>>,
    TError,
    { data: StudentExamScoreInsetClassScoresBody; params: StudentExamScoreInsetClassScoresParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentExamScoreInsetClassScores>>,
    { data: StudentExamScoreInsetClassScoresBody; params: StudentExamScoreInsetClassScoresParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return studentExamScoreInsetClassScores(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentExamScoreInsetClassScores>>,
    TError,
    { data: StudentExamScoreInsetClassScoresBody; params: StudentExamScoreInsetClassScoresParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 성적파일 다수 업로드
 */
export const studentExamScoreInsetClassScoresBulk = (
  studentExamScoreInsetClassScoresBulkBody: StudentExamScoreInsetClassScoresBulkBody,
  params: StudentExamScoreInsetClassScoresBulkParams,
  options?: SecondParameter<typeof mutator>,
) => {
  const formData = new FormData();
  if (studentExamScoreInsetClassScoresBulkBody.file !== undefined) {
    formData.append('file', studentExamScoreInsetClassScoresBulkBody.file);
  }

  return mutator<void>(
    {
      url: `/api/analysis/school-exam/users/insert-class-scores-bulk`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options,
  );
};

export type StudentExamScoreInsetClassScoresBulkMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScoreInsetClassScoresBulk>>
>;
export type StudentExamScoreInsetClassScoresBulkMutationBody = StudentExamScoreInsetClassScoresBulkBody;
export type StudentExamScoreInsetClassScoresBulkMutationError = ErrorType<unknown>;

export const useStudentExamScoreInsetClassScoresBulk = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentExamScoreInsetClassScoresBulk>>,
    TError,
    { data: StudentExamScoreInsetClassScoresBulkBody; params: StudentExamScoreInsetClassScoresBulkParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentExamScoreInsetClassScoresBulk>>,
    { data: StudentExamScoreInsetClassScoresBulkBody; params: StudentExamScoreInsetClassScoresBulkParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return studentExamScoreInsetClassScoresBulk(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentExamScoreInsetClassScoresBulk>>,
    TError,
    { data: StudentExamScoreInsetClassScoresBulkBody; params: StudentExamScoreInsetClassScoresBulkParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Fetch the scores files inserted for a given school id and year.
 */
export const studentExamScoreCheckScoreFile = (
  params: StudentExamScoreCheckScoreFileParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<void>(
    { url: `/api/analysis/school-exam/users/check-score-file`, method: 'get', params, signal },
    options,
  );
};

export const getStudentExamScoreCheckScoreFileQueryKey = (params: StudentExamScoreCheckScoreFileParams) => [
  `/api/analysis/school-exam/users/check-score-file`,
  ...(params ? [params] : []),
];

export type StudentExamScoreCheckScoreFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScoreCheckScoreFile>>
>;
export type StudentExamScoreCheckScoreFileQueryError = ErrorType<unknown>;

export const useStudentExamScoreCheckScoreFile = <
  TData = Awaited<ReturnType<typeof studentExamScoreCheckScoreFile>>,
  TError = ErrorType<unknown>,
>(
  params: StudentExamScoreCheckScoreFileParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentExamScoreCheckScoreFile>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentExamScoreCheckScoreFileQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentExamScoreCheckScoreFile>>> = ({ signal }) =>
    studentExamScoreCheckScoreFile(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentExamScoreCheckScoreFile>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시험성적 파일 업로드
 */
export const studentExamScoreInsetTestScores = (
  studentExamScoreInsetTestScoresBody: StudentExamScoreInsetTestScoresBody,
  params: StudentExamScoreInsetTestScoresParams,
  options?: SecondParameter<typeof mutator>,
) => {
  const formData = new FormData();
  if (studentExamScoreInsetTestScoresBody.file !== undefined) {
    formData.append('file', studentExamScoreInsetTestScoresBody.file);
  }

  return mutator<void>(
    {
      url: `/api/analysis/school-exam/users/insert-test-scores`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options,
  );
};

export type StudentExamScoreInsetTestScoresMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentExamScoreInsetTestScores>>
>;
export type StudentExamScoreInsetTestScoresMutationBody = StudentExamScoreInsetTestScoresBody;
export type StudentExamScoreInsetTestScoresMutationError = ErrorType<unknown>;

export const useStudentExamScoreInsetTestScores = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentExamScoreInsetTestScores>>,
    TError,
    { data: StudentExamScoreInsetTestScoresBody; params: StudentExamScoreInsetTestScoresParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentExamScoreInsetTestScores>>,
    { data: StudentExamScoreInsetTestScoresBody; params: StudentExamScoreInsetTestScoresParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return studentExamScoreInsetTestScores(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentExamScoreInsetTestScores>>,
    TError,
    { data: StudentExamScoreInsetTestScoresBody; params: StudentExamScoreInsetTestScoresParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생카드 학생정보 불러오기
 */
export const studentCardFindStudent = (id: number, options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseStudentCardStudentDto>(
    { url: `/api/student-card/student/${id}`, method: 'get', signal },
    options,
  );
};

export const getStudentCardFindStudentQueryKey = (id: number) => [`/api/student-card/student/${id}`];

export type StudentCardFindStudentQueryResult = NonNullable<Awaited<ReturnType<typeof studentCardFindStudent>>>;
export type StudentCardFindStudentQueryError = ErrorType<unknown>;

export const useStudentCardFindStudent = <
  TData = Awaited<ReturnType<typeof studentCardFindStudent>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof studentCardFindStudent>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentCardFindStudentQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentCardFindStudent>>> = ({ signal }) =>
    studentCardFindStudent(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof studentCardFindStudent>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 관찰기록 추가
 */
export const recordCreate = (
  recordCreateBody: RecordCreateBody,
  params: RecordCreateParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<Record>(
    {
      url: `/api/record`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: recordCreateBody,
      params,
    },
    options,
  );
};

export type RecordCreateMutationResult = NonNullable<Awaited<ReturnType<typeof recordCreate>>>;
export type RecordCreateMutationBody = RecordCreateBody;
export type RecordCreateMutationError = ErrorType<void>;

export const useRecordCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recordCreate>>,
    TError,
    { data: RecordCreateBody; params: RecordCreateParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recordCreate>>,
    { data: RecordCreateBody; params: RecordCreateParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return recordCreate(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof recordCreate>>,
    TError,
    { data: RecordCreateBody; params: RecordCreateParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 관찰기록 수정
 */
export const recordUpdate = (
  id: number,
  recordUpdateBody: RecordUpdateBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<UpdateResult>(
    {
      url: `/api/record/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: recordUpdateBody,
    },
    options,
  );
};

export type RecordUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof recordUpdate>>>;
export type RecordUpdateMutationBody = RecordUpdateBody;
export type RecordUpdateMutationError = ErrorType<void>;

export const useRecordUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recordUpdate>>,
    TError,
    { id: number; data: RecordUpdateBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recordUpdate>>,
    { id: number; data: RecordUpdateBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return recordUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof recordUpdate>>,
    TError,
    { id: number; data: RecordUpdateBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 관찰기록 삭제
 */
export const recordDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<Record>({ url: `/api/record/${id}`, method: 'delete' }, options);
};

export type RecordDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof recordDelete>>>;

export type RecordDeleteMutationError = ErrorType<void>;

export const useRecordDelete = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof recordDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof recordDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return recordDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof recordDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 피드백 추가
 */
export const sessionCommentCreate = (
  sessionCommentCreateBody: SessionCommentCreateBody,
  params: SessionCommentCreateParams,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<SessionComment>(
    {
      url: `/api/session-comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sessionCommentCreateBody,
      params,
    },
    options,
  );
};

export type SessionCommentCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sessionCommentCreate>>>;
export type SessionCommentCreateMutationBody = SessionCommentCreateBody;
export type SessionCommentCreateMutationError = ErrorType<void>;

export const useSessionCommentCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sessionCommentCreate>>,
    TError,
    { data: SessionCommentCreateBody; params: SessionCommentCreateParams },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sessionCommentCreate>>,
    { data: SessionCommentCreateBody; params: SessionCommentCreateParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return sessionCommentCreate(data, params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof sessionCommentCreate>>,
    TError,
    { data: SessionCommentCreateBody; params: SessionCommentCreateParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 피드백 수정
 */
export const sessionCommentUpdate = (
  id: number,
  sessionCommentUpdateBody: SessionCommentUpdateBody,
  options?: SecondParameter<typeof mutator>,
) => {
  return mutator<UpdateResult>(
    {
      url: `/api/session-comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: sessionCommentUpdateBody,
    },
    options,
  );
};

export type SessionCommentUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sessionCommentUpdate>>>;
export type SessionCommentUpdateMutationBody = SessionCommentUpdateBody;
export type SessionCommentUpdateMutationError = ErrorType<void>;

export const useSessionCommentUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sessionCommentUpdate>>,
    TError,
    { id: number; data: SessionCommentUpdateBody },
    TContext
  >;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sessionCommentUpdate>>,
    { id: number; data: SessionCommentUpdateBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return sessionCommentUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof sessionCommentUpdate>>,
    TError,
    { id: number; data: SessionCommentUpdateBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 피드백 삭제
 */
export const sessionCommentDelete = (id: number, options?: SecondParameter<typeof mutator>) => {
  return mutator<SessionComment>({ url: `/api/session-comment/${id}`, method: 'delete' }, options);
};

export type SessionCommentDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionCommentDelete>>>;

export type SessionCommentDeleteMutationError = ErrorType<void>;

export const useSessionCommentDelete = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sessionCommentDelete>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionCommentDelete>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return sessionCommentDelete(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof sessionCommentDelete>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 블록체인 지갑생성
 */
export const blockChainCreateWallet = (options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/block-chain/create-wallet`, method: 'post' }, options);
};

export type BlockChainCreateWalletMutationResult = NonNullable<Awaited<ReturnType<typeof blockChainCreateWallet>>>;

export type BlockChainCreateWalletMutationError = ErrorType<unknown>;

export const useBlockChainCreateWallet = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof blockChainCreateWallet>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof blockChainCreateWallet>>, TVariables> = () => {
    return blockChainCreateWallet(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof blockChainCreateWallet>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 블록체인 지갑조회
 */
export const blockChainGetWallet = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseBlockChainStatusDto>({ url: `/api/block-chain/wallet`, method: 'get', signal }, options);
};

export const getBlockChainGetWalletQueryKey = () => [`/api/block-chain/wallet`];

export type BlockChainGetWalletQueryResult = NonNullable<Awaited<ReturnType<typeof blockChainGetWallet>>>;
export type BlockChainGetWalletQueryError = ErrorType<unknown>;

export const useBlockChainGetWallet = <
  TData = Awaited<ReturnType<typeof blockChainGetWallet>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof blockChainGetWallet>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBlockChainGetWalletQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof blockChainGetWallet>>> = ({ signal }) =>
    blockChainGetWallet(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof blockChainGetWallet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 블록체인 문서함 생성
 */
export const blockChainCreateContract = (options?: SecondParameter<typeof mutator>) => {
  return mutator<boolean>({ url: `/api/block-chain/create-contract`, method: 'post' }, options);
};

export type BlockChainCreateContractMutationResult = NonNullable<Awaited<ReturnType<typeof blockChainCreateContract>>>;

export type BlockChainCreateContractMutationError = ErrorType<unknown>;

export const useBlockChainCreateContract = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof blockChainCreateContract>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof blockChainCreateContract>>, TVariables> = () => {
    return blockChainCreateContract(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof blockChainCreateContract>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary 블록체인 문서함(컨트렉트) 조회
 */
export const blockChainGetContract = (options?: SecondParameter<typeof mutator>, signal?: AbortSignal) => {
  return mutator<ResponseBlockChainStatusDto>({ url: `/api/block-chain/contract`, method: 'get', signal }, options);
};

export const getBlockChainGetContractQueryKey = () => [`/api/block-chain/contract`];

export type BlockChainGetContractQueryResult = NonNullable<Awaited<ReturnType<typeof blockChainGetContract>>>;
export type BlockChainGetContractQueryError = ErrorType<unknown>;

export const useBlockChainGetContract = <
  TData = Awaited<ReturnType<typeof blockChainGetContract>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof blockChainGetContract>>, TError, TData>;
  request?: SecondParameter<typeof mutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBlockChainGetContractQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof blockChainGetContract>>> = ({ signal }) =>
    blockChainGetContract(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof blockChainGetContract>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 블록체인 Request 조회 및 result 업데이트
 */
export const blockChainRequestCheck = (
  params: BlockChainRequestCheckParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseBlockChainStatusDto>(
    { url: `/api/block-chain/request`, method: 'get', params, signal },
    options,
  );
};

export const getBlockChainRequestCheckQueryKey = (params: BlockChainRequestCheckParams) => [
  `/api/block-chain/request`,
  ...(params ? [params] : []),
];

export type BlockChainRequestCheckQueryResult = NonNullable<Awaited<ReturnType<typeof blockChainRequestCheck>>>;
export type BlockChainRequestCheckQueryError = ErrorType<unknown>;

export const useBlockChainRequestCheck = <
  TData = Awaited<ReturnType<typeof blockChainRequestCheck>>,
  TError = ErrorType<unknown>,
>(
  params: BlockChainRequestCheckParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof blockChainRequestCheck>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBlockChainRequestCheckQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof blockChainRequestCheck>>> = ({ signal }) =>
    blockChainRequestCheck(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof blockChainRequestCheck>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 문서등록상태 조회
 */
export const blockChainGetDocumentStatus = (
  params: BlockChainGetDocumentStatusParams,
  options?: SecondParameter<typeof mutator>,
  signal?: AbortSignal,
) => {
  return mutator<ResponseBlockChainStatusDto>(
    { url: `/api/block-chain/document-status`, method: 'get', params, signal },
    options,
  );
};

export const getBlockChainGetDocumentStatusQueryKey = (params: BlockChainGetDocumentStatusParams) => [
  `/api/block-chain/document-status`,
  ...(params ? [params] : []),
];

export type BlockChainGetDocumentStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof blockChainGetDocumentStatus>>
>;
export type BlockChainGetDocumentStatusQueryError = ErrorType<unknown>;

export const useBlockChainGetDocumentStatus = <
  TData = Awaited<ReturnType<typeof blockChainGetDocumentStatus>>,
  TError = ErrorType<unknown>,
>(
  params: BlockChainGetDocumentStatusParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof blockChainGetDocumentStatus>>, TError, TData>;
    request?: SecondParameter<typeof mutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBlockChainGetDocumentStatusQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof blockChainGetDocumentStatus>>> = ({ signal }) =>
    blockChainGetDocumentStatus(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof blockChainGetDocumentStatus>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const blockChainCallBack = (options?: SecondParameter<typeof mutator>) => {
  return mutator<void>({ url: `/api/block-chain/call-back`, method: 'post' }, options);
};

export type BlockChainCallBackMutationResult = NonNullable<Awaited<ReturnType<typeof blockChainCallBack>>>;

export type BlockChainCallBackMutationError = ErrorType<unknown>;

export const useBlockChainCallBack = <TError = ErrorType<unknown>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof blockChainCallBack>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof mutator>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof blockChainCallBack>>, TVariables> = () => {
    return blockChainCallBack(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof blockChainCallBack>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};
